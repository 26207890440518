import cloneDeep from "lodash/cloneDeep";
import { Message, Messages, Comments, Comment, User, Topic, Acl } from "@/model";
import { ServerApiAccess } from "@/server-api-access";
import Vue from "vue";
import { Module } from "vuex";
import MessageDao from "./message-dao";
import type { MessageReadResult } from "./message-dao";
import type { UpdatePayload as CommentUpdatePayload } from "./comment-store";

type DateRange = { from: string|null, to: string|null };
type State = { messages: Messages, nextToken: Record<string, string | null | undefined>, range:Record<string, DateRange> }
type RootState = { domainId: string, topicId: string, messageId: string };

type Payload = { message: Message, topicId: string, domainId: string };
type PayloadAdd = {
    domainId: string,
    topicId: string,
    messages: Message[]
}
type PayloadFetch = {
    domainId: string,
    topicId: string,
    messageId?: string,
}
export type PayloadRegisterSubscription = { domainId: string, topicId: string };

const findTopicMessages = ( state: State, domainId: string, topicId: string ): Message[] => {
    let domainTopicList = state.messages[ domainId ];
    if( domainTopicList == undefined ) {    // 箱を用意する
        domainTopicList = {};
        Vue.set( state.messages, domainId, domainTopicList );
    }

    let messages = domainTopicList[ topicId ];
    if( messages == undefined ) {           // 箱を用意する
        messages = [];
        Vue.set( state.messages[ domainId ], topicId, messages );
    }

    return messages;
}

/** 追加もしくは更新を行う */
function addOrUpdate( state: State, messages: Message[], domainId: string, topicId: string ) {
    messages.forEach( message => {
    const topicMessages = findTopicMessages( state, domainId, topicId );
    const index = topicMessages.findIndex( m => m.id == message.id );
    if( index < 0 ) {
        topicMessages.push( message );
    } else {
        topicMessages.splice( index, 1, message );
    }
    // ソート
    topicMessages.sort( (l, r) => {
        return l.updatedAt.getTime() > r.updatedAt.getTime() ? -1 : 1;
    })
    })
}

const messageModule: Module<State, RootState> = {
    namespaced: true,
    state: {
        messages: {},
        nextToken: {},
        range: {}
    },
    getters: {
        /**
         * 組織IDと話題IDを基に投稿を取得する
         * @param domainId 組織ID
         * @param topicId  話題ID
         * @param exists   true: 削除されてないものだけ取得
         */
        get: ( state: State ) => ( domainId: string, topicId: string, exists: boolean = false ): Message[]|undefined => {
            const domainTopicList = state.messages[ domainId ];
            if( !domainTopicList ) return undefined;
            const messages = domainTopicList[ topicId ];
            if( !messages ) return undefined;
            return exists ? messages.filter( m => Message.isExists( m ) ) : messages;
        },

        getNextToken: ( state: State ) => ( topicId: string ): string | null | undefined => {
            return state.nextToken[topicId];
        },

        getRange: ( state: State ) => ( topicId: string ): DateRange => { 
            return state.range[topicId]; 
        },
    },
    mutations: {
        // 投稿を追加します。topicId, domainId はこの時点で確定済みにしておいて下さい
        add( state, { messages, topicId, domainId }: PayloadAdd ): void {
            addOrUpdate( state, messages, domainId, topicId )
        },

        // 投稿を更新します。topicId, domainId はこの時点で確定済みにしておいて下さい
        update( state, { message }: Payload ): void {
            addOrUpdate( state, [message], message.domainId, message.topicId )
        },
        setNextToken( state, { topicId, nextToken }: { topicId: string, nextToken: string | null | undefined } ): void {
            state.nextToken[ topicId ] = nextToken;
        },
        reset( state, { domainId, topicId, messageId }: { domainId: string, topicId: string, messageId: string } ): void {
            const messages = findTopicMessages(state, domainId, topicId);
            const index = messages.findIndex( m => m.id == messageId );
            // 1つだけの時のみ
            if( index > -1 && messages.length === 1 ) {
                Vue.delete( state.messages[domainId], topicId );
                state.nextToken[ topicId ] = undefined;
            }
        },
        setRange( state, { topicId, range }: { topicId: string, range: DateRange } ): void {
            state.range[ topicId ] = range;
        },
    },
    actions: {
        async reset({commit, rootState, rootGetters }, envelope: { domainId: string, topicId: string, messageId: string } ): Promise<void> {
            commit('reset', envelope); 
        },

        async create({ dispatch, commit, rootState, rootGetters }, envelope: { message: Message, topicId?: string, domainId?: string } ): Promise<void> {
            const message = envelope.message;
            message.setDomainAndTopicIfNotSet(
                ( envelope.domainId || rootState.domainId ),
                ( envelope.topicId || rootState.topicId ),
            );
            const domainId = message.domainId;
            const topicId = message.topicId;

            // commentが用意されていなかったら、今の段階で場所を用意しておく
            const comments = rootGetters["comments/get"]( message.id ) as Comments[];
            if( comments == undefined ) {
                await dispatch( "comments/add", { comment: [], messageId: envelope.message.id }, { root: true } );
            }

            // DBに保存
            const register = await MessageDao.create( message );
            if( register ) {
                commit( 'add', { messages: [register], domainId, topicId } );
                const access = new ServerApiAccess();

                // Topicの更新(投稿数)依頼
                const updateMessageCount = true;
                await access.putTopicMessageCount( domainId, topicId, updateMessageCount );
                // flowへの投稿
                await access.postFlow( register, `投稿：${register.title}`, rootGetters, "create" );
            }
        },

        async update({ dispatch, commit, rootState, rootGetters }, envelope: { messageId: string, param: { [key: string]: any },  topicId?: string, domainId?: string } ): Promise<void> {
            const messageId = envelope.messageId;
            const domainId = rootState.domainId;
            const topicId = rootState.topicId;

            const param = envelope.param;
            param.comments = rootGetters["comments/get"]( messageId ) as Comments[]; // コメントを追加
            const messageObject: Partial<Message> = { ...param, id: messageId, domainId: domainId, topicId: topicId }; 
            const message = Message.create(messageObject);

            const register = await MessageDao.update(message);
            if( register ) {
                commit( 'update', { message: register } );
                const access = new ServerApiAccess();

                // Topicの更新依頼
                // TODO: update-message API側に統合する
                const updateMessageCount = !!param.deleted; // 削除時は投稿数を更新、更新時は話題更新日時のみ更新する
                await access.putTopicMessageCount( domainId, topicId, updateMessageCount );

                // flowへの投稿
                if( param.deleted ) {
                    // 削除の場合は削除通知をする
                    await access.postFlow( register, `投稿削除：${register.title}`, rootGetters, "delete" );
                } else {
                    await access.postFlow( register, `投稿更新：${register.title}`, rootGetters, "update" );
                }
            }

            if(param.deleted && param.deletedUser) {
                const comments = rootGetters["comments/get"]( topicId, messageId );
                if(!comments) return;

                comments.forEach( async(comment: Partial<Comment>) => {
                    const commentId = comment.id as string;
                    if( !comment.deleted ) {
                        const payload: CommentUpdatePayload = { commentId: commentId, messageId: messageId, param: param  }
                        await dispatch( "comments/update", payload, { root: true } );
                    }
                });
            }
        },

        /**
         * DBから投稿データをロードして Store に保存する
         * @param domainId: 組織ID
         * @param topicId:  話題ID
         * @param messageId:投稿ID
         */
        async fetch({ commit, rootGetters }, { domainId, topicId, messageId }: PayloadFetch ): Promise<void> {
            const getCommentFunc = rootGetters["comments/get"];
            const messages = await MessageDao.read( domainId, topicId, getCommentFunc, messageId);
            if( messages ) {
                commit( 'add', { messages: messages, topicId, domainId } );
            }
        },

        /** Subscriptionを登録する */
        async registerSubscription( {commit, dispatch }, { domainId, topicId }: PayloadRegisterSubscription ): Promise<void> {
            await MessageDao.registerSubscrptions( domainId, topicId, {
                onCreate:( domainId: string, topicId: string, messageId: string ) => {
                    dispatch("fetch", { domainId, topicId, messageId })
                },
                onUpdate: ( domainId: string, topicId: string, messageId: string ) => {
                    dispatch("fetch", { domainId, topicId, messageId })
                },
                onDelete: ( domainId: string, topicId: string, messageId: string ) => {
                    commit( 'delete', { domainId, topicId, messageId } );
                },
            } );
            return;
        },

        /** Subscriptionを登録解除 */
        async removeSubscription( context, payload: { topicId: string } ): Promise<void> {
            const topicId = payload.topicId;
            await MessageDao.closeSubscription( topicId );
        },
    }
}

export default messageModule;
