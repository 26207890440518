import { render, staticRenderFns } from "./SearchResult.vue?vue&type=template&id=92fd54d6&"
import script from "./SearchResult.vue?vue&type=script&lang=ts&"
export * from "./SearchResult.vue?vue&type=script&lang=ts&"
import style0 from "./SearchResult.vue?vue&type=style&index=0&scss=true&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/Users/m73takahiro.mori/dx-flow/dx-flow-open-channel/packages/open-channel/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('92fd54d6')) {
      api.createRecord('92fd54d6', component.options)
    } else {
      api.reload('92fd54d6', component.options)
    }
    module.hot.accept("./SearchResult.vue?vue&type=template&id=92fd54d6&", function () {
      api.rerender('92fd54d6', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/components/SearchResult.vue"
export default component.exports