import { render, staticRenderFns } from "./CategoryList.vue?vue&type=template&id=2ba7ee28&"
import script from "./CategoryList.vue?vue&type=script&lang=ts&"
export * from "./CategoryList.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/Users/m73takahiro.mori/dx-flow/dx-flow-open-channel/packages/open-channel/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('2ba7ee28')) {
      api.createRecord('2ba7ee28', component.options)
    } else {
      api.reload('2ba7ee28', component.options)
    }
    module.hot.accept("./CategoryList.vue?vue&type=template&id=2ba7ee28&", function () {
      api.rerender('2ba7ee28', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/components/CategoryList.vue"
export default component.exports