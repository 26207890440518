/**
 * APIサーバへのアクセス用
 */

import axios, { AxiosResponse } from "axios";
import { DirectDomainMembersType, DirectDepartmentsType } from "./direct-restapi-types";
import { Acl, Category, Topic, Message, Comment, User as UserModel } from "@/model";
import { ReactionController } from "./model/reaction-controller";
import { IconReaction, IconReactionType, User, Topic as DbTopic, Category as DbCategory, Message as DbMessage , Comment as DbComment, Department as DbDepartment, Department} from "./API";
import { ServerUserInfo } from "./aws-config";
import { FlowPostParam, FlowUtility } from "./flow/flow-utility";
import DirectUtility from "./direct-utility";
import { allowFeature } from "./direct-app-config";
import store from "./store";
import AclController from "./components/acl/acl-controller";
import { SolutionLinkResponse } from "./model/solution";
import { EventManager } from "./events/event-manager";


export type Domains = Direct.GetDomains;


// API-GWへの接続設定
const PRODUCT_BASE      = process.env.VUE_APP_PRODUCT_BASE || "/";
const AUTH_SERVER_URL   = process.env.VUE_APP_AUTH_SERVER_URL || "http://localhost:3000/";

const ServerApi = {
    userData: new URL( `${PRODUCT_BASE}userData`, AUTH_SERVER_URL ).toString(),
    flow: new URL( `${PRODUCT_BASE}flow`, AUTH_SERVER_URL ).toString(),
    domainMembers: ( domainId: string, offset?: number, limit?: number ) => {
        const url = new URL( `${PRODUCT_BASE}domain-members`, AUTH_SERVER_URL );
        const params = url.searchParams;
        params.append( "domainId", domainId );
        if( offset ) params.append( "offset", String( offset ) );
        if( limit  ) params.append( "limit",  String( limit ) );
        return url.toString();
    },
    listCategories: ( domainId: string ) => {
        const url = new URL( `${PRODUCT_BASE}list-categories`, AUTH_SERVER_URL );
        const params = url.searchParams;
        params.append( "domain_id", domainId );
        return url.toString();
    },
    topicListByAcl: (domainId: string) => {
        const url = new URL( `${PRODUCT_BASE}list-topics-by-acl`, AUTH_SERVER_URL );
        const params = url.searchParams;
        params.append( "domain_id", domainId );
        return url.toString();
    },
    listMessages: (domainId: string, topicId: string, messageId?: string) => {
        const url = new URL( `${PRODUCT_BASE}list-messages`, AUTH_SERVER_URL );
        const params = url.searchParams;
        params.append( "domain_id", domainId );
        params.append( "topic_id", topicId );
        if( messageId ) {
            params.append( "message_id", messageId );
        }
        return url.toString();
    },
    listComments: (domainId: string, topicId: string, messageId?: string) => {
        const url = new URL( `${PRODUCT_BASE}list-comments`, AUTH_SERVER_URL );
        const params = url.searchParams;
        params.append( "domain_id", domainId );
        params.append( "topic_id", topicId );
        if( messageId ) {
            params.append( "message_id", messageId );
        }
        return url.toString();
    },
    listDepartments: ( domainId: string ) => {
        const url = new URL( `${PRODUCT_BASE}list-departments`, AUTH_SERVER_URL );
        const params = url.searchParams;
        params.append( "domain_id", domainId );
        return url.toString();
    },
    listApps: (domainId: string) => {
        const url = new URL( `${PRODUCT_BASE}list-apps`, AUTH_SERVER_URL );
        const params = url.searchParams;
        params.append( "domainId", domainId );
        return url.toString();
    },
    listDomains: () => {
        const url = new URL( `${PRODUCT_BASE}list-domains`, AUTH_SERVER_URL );
        return url.toString();
    },
    upsertDepartments: new URL( `${PRODUCT_BASE}upsert-departments`, AUTH_SERVER_URL ).toString(),
    createCategory: new URL( `${PRODUCT_BASE}create-category`, AUTH_SERVER_URL ).toString(),
    updateCategory: new URL( `${PRODUCT_BASE}update-category`, AUTH_SERVER_URL ).toString(),
    createTopic: new URL( `${PRODUCT_BASE}create-topic`, AUTH_SERVER_URL ).toString(),
    updateTopic: new URL( `${PRODUCT_BASE}update-topic`, AUTH_SERVER_URL ).toString(),
    createMessage: new URL( `${PRODUCT_BASE}create-message`, AUTH_SERVER_URL ).toString(),
    updateMessage: new URL( `${PRODUCT_BASE}update-message`, AUTH_SERVER_URL ).toString(),
    createComment: new URL( `${PRODUCT_BASE}create-comment`, AUTH_SERVER_URL ).toString(),
    updateComment: new URL( `${PRODUCT_BASE}update-comment`, AUTH_SERVER_URL ).toString(),
    logging: new URL( `${PRODUCT_BASE}logging`, AUTH_SERVER_URL ).toString(),
    notifications: new URL( `${PRODUCT_BASE}notifications`, AUTH_SERVER_URL ).toString(),
    topicMessageCount: new URL( `${PRODUCT_BASE}topic/update-message-count`, AUTH_SERVER_URL ).toString(),

    error: new URL( `${PRODUCT_BASE}debug-sentry`, AUTH_SERVER_URL ).toString(),
}

type FlowTargetType = Topic|Message|Comment;
type FlowActionType = "create"|"update"|"delete";
type FlowContainerType = "self"|"parent"|"root"|undefined;

type FlowTargetUser = {
    users: string[],        // 送付先 user_id の配列。空配列だと組織内全員。undefined だと送付しない
    allowGuest: boolean,    // true: ゲストへの送付OK false: ゲストへの送付NG
}

export class ServerApiAccess {

    public constructor() {
        // 空
    }

    /**
     * GET系 API にアクセス
     * @param api API-GW REST API endpoint
     */
    private async getApi<T>( api: string ): Promise< AxiosResponse<T|undefined>> {
        const response = await axios.get( api, { withCredentials: true } );
        return response;
    }

    /**
     * POST系 API にアクセス
     * @param api API-GW REST API endpoint
     */
    private async postApi<T>( api: string, param: any ): Promise<T|undefined> {
        const response = await axios.post( api, param, { withCredentials: true } );
        return response.data as T;
    }

    /**
     * PUT系 API にアクセス
     * @param api API-GW REST API endpoint
     * @param param パラメーター
     */
    private async putApi<T>( api: string, param: any ): Promise<T|undefined> {
        const response = await axios.put( api, param, { withCredentials: true } );
        return response.data as T;
    }

    // true: ゲストの閲覧許可
    private allowGuestRead( topicAcl: Acl ): boolean {
        const allowGuest = topicAcl?.guest?.read || false;          // ゲストへの送付設定はTopicのゲスト許可に依る
        return allowGuest;
    }

    // 話題のACLから通知先ユーザを返す
    private getNotificationUserIdList( topic: Topic ): string[] {
        const users = store.getters[ 'users/getByDomainId' ]( topic.domainId );
        const departments = store.getters["domains/getDepartments"] || [];
        const aclInstance = new AclController();
        aclInstance.editExceptionUser( topic.owner, { owner: true, operator: false, admin: false })
        aclInstance.init( topic.domainId, users, departments, topic.acl );
        const userIdList = aclInstance.getAllowUserIdList();
        return userIdList;
    }

    // flowの対象ユーザーを集める
    // type アクション種別。create or update or delete
    // containers 収集先一覧
    // topicAcl: Topic の ACL
    private gatherFlowTargetUsers( target: FlowTargetType, type: FlowActionType, containers: FlowContainerType[], topicAcl: Acl, getters: Record<string, any>): FlowTargetUser|undefined {
        if( type == "delete" ) return undefined;                    // 削除は送付しない
        const allowGuest = this.allowGuestRead( topicAcl );          // ゲストへの送付設定はTopicのゲスト許可に依る
        // ゲストユーザーのフィルタリング（必要なら）
        const filterGuestUser = ( userIdList: string[], domainId: string ): string[] => {
            if( allowGuest ) return userIdList;     // ゲスト許可なので送信先をフィルタする必要無し
            const members = getters[ "users/getDomainMembers" ]( domainId ) as DirectDomainMembersType;
            return userIdList.filter( userId => {
                // ゲストの場合は拒否
                return UserModel.memberIsGuestRole( members, userId ) ? false : true;
            } )
        }
        if( target instanceof Topic && type == "create") {
            const userIdList = this.getNotificationUserIdList(target);
            const filtered = filterGuestUser( userIdList, target.domainId );
            return {
                users: filtered,                  // 話題の作成時は、組織内全員に送付
                allowGuest: allowGuest,
            };
        } else if( target instanceof Message && type == "create" ) {
            const topic = getters[ "topics/getOne" ]( target.domainId, target.topicId );
            // 全体通知の話題の場合は、組織内全員に送付
            if( topic.notification ) {
                const userIdList = this.getNotificationUserIdList(topic);
                const filtered = filterGuestUser( userIdList, target.domainId );
                return {
                    users: filtered,
                    allowGuest: allowGuest,
                };
            }
        }

        let result: string[] = [];
        const isEmpty = ( array?: string[] ) => !array || array.length == 0;

        containers.forEach( container => {
            let tmp: string[]|undefined = undefined;
            let reactionParam, reaction;
            switch( container ) {
                case "self": {
                    // self が選択された場合は、リアクションを取得して該当ユーザーを探す
                    reactionParam = ReactionController.getReactionParam( target );
                    reaction = getters[ "reactions/get" ]( IconReactionType.FAVORITE, reactionParam ) as IconReaction | undefined;
                    if( !reaction || isEmpty( reaction.userIdList ) ) {
                        // 送信先無し
                    } else {
                        tmp = reaction.userIdList;
                    }
                    break;
                }
                case "parent":
                case "root": {
                    // parent もしくは root が選択された場合は、リアクションを取得して該当ユーザーを返す
                    reactionParam = ReactionController.getReactionParam( target, container );
                    reaction = getters[ "reactions/get" ]( IconReactionType.FAVORITE, reactionParam ) as IconReaction | undefined;
                    if( !reaction || isEmpty( reaction.userIdList ) ) {
                        // 送信先無し
                    } else {
                        tmp = reaction.userIdList;
                    }
                    break;
                }
            }
            if( tmp && isEmpty( tmp ) == false ) {
                result = result.concat( tmp );
            }
        })
        if( !result || isEmpty( result )) {
            return undefined;   // 送信先無し
        } else {
            const filtered = filterGuestUser( result, target.domainId );
            return {
                users: [ ...new Set( filtered ) ],  // 重複排除
                allowGuest: allowGuest,             // ゲスト許可設定
            }
        }
    }

    /**
     * フローに投稿する
     * @param target 状態が変化する話題／投稿／コメント
     * @param message フローに投稿されるメッセージ
     * @param getters VuexのRootGetters
     * @param type    操作種別
     * @returns
     */
    public async postFlow( target: FlowTargetType, message: string, getters: Record<string, any>, type: FlowActionType ): Promise<void> {
        let container: FlowContainerType[] = [];
        let topicAcl = Acl.createByTopic( (target instanceof Topic ? target.id : target.topicId), false );
        if( target instanceof Topic ) {
            topicAcl = target.acl;
            switch( type ) {
                case "create": container = []; break;       // 組織内全員(ここでの指定は意味無し)
                case "update": container = ["self"]; break; // 自Topicのみ
                case "delete": return;                      // フロー投稿しない
            }
        } else if( target instanceof Message ) {
            const topic = getters[ "topics/getOne" ]( target.domainId, target.topicId );
            if( topic ) topicAcl = topic.acl;
            switch( type ) {
                case "create": container = ["parent"]; break;         // 親Topicから取得
                case "update": container = ["self", "parent"]; break; // 親Topic 及び 自身
                case "delete": return;                      // フロー投稿しない
            }
        } else if( target instanceof Comment ) {
            const topic = getters[ "topics/getOne" ]( target.domainId, target.topicId );
            if( topic ) topicAcl = topic.acl;
            switch( type ) {
                case "create": container = ["parent", "root"]; break; // 親Topic及びMessage
                case "update": container = ["parent", "root"]; break; // 親Topic及びMessage
                case "delete": return;                      // フロー投稿しない
            }
        }

        // 送信先設定
        const notifyUserIdList = this.gatherFlowTargetUsers( target, type, container, topicAcl, getters );
        if( !notifyUserIdList ) return;    // 対象となる人がいない
        // Vue.jsの配列オブジェクトを一般の文字列に変換する(↓Vueの製作者によるVue管理オブジェクトの削除方法)
        // これをしないとVue.jsが追加する管理オブジェクトが入ってしまう
        const notifyUserIds = JSON.parse(JSON.stringify(notifyUserIdList.users))

        // サーバーAPIができたら切り替える
        // return await this.postApi( ServerApi.flow, {
        //     ...reactionParam,
        //     user_id: reaction?.userIdList || [],
        //     contents: message,
        // });

        // @obsolute
        const me = getters["users/me"] as User;
        const reactionParam = ReactionController.getReactionParam( target );    // 投稿URLパスの作成
        const path = `/${reactionParam.domainId}/${reactionParam.topicId}`
                    + ( reactionParam.messageId ? `/${reactionParam.messageId}` : "" )
                    + ( reactionParam.commentId ? `/${reactionParam.commentId}` : "" )
                    ;
        const url = new URL( path, window.location.origin ).toString();
        const param: FlowPostParam = {
            domain_id: target.domainId,
            user_id: notifyUserIds,
            url: url,
            contents: message,
            registrant_id: me.directId,
            guest_flag: this.allowGuestRead( topicAcl ) ? 0 : 1,    // 0: ゲスト配信する 1: ゲスト配信しない
            attachment: [],
        }
        // Push通知
        if ( allowFeature("with-puth-notification", getters) ) {
            const domainName = getters["domains/getDomainName"];
            let typeStr = "";
            switch( type ) {
                case "create": typeStr = "作成"; break;
                case "update": typeStr = "更新"; break;
            }
            if( target instanceof Topic ) {
                param.push_title = `【話題${typeStr}】${me.name}さん ${domainName}`;
            } else if( target instanceof Message ) {
                param.push_title = `【投稿${typeStr}】${me.name}さん ${domainName}`;
            } else if( target instanceof Comment ) {
                param.push_title = `【コメント${typeStr}】${me.name}さん ${domainName}`;
            }
            param.push_description = message;
        }
        FlowUtility.post( param, ServerApi.notifications );
    }

    /**
     * /userData にアクセスして、自分のユーザー情報を取得します
     * @returns ユーザー情報。number: 401は未ログインエラー。undefined: その他のエラー
     */
    public async getUserData(): Promise<ServerUserInfo|number|undefined> {
        try {
            const result = await this.getApi<{user:ServerUserInfo}>( ServerApi.userData );
            if( result.status == 200 && result.data?.user ) {
                const user = result.data.user as ServerUserInfo;
                console.log("★ServerUserInfo:%O", JSON.stringify(user) )
                return user;
            } else if ( result.status == 401 ) {
                // 認証してくださいエラー
                console.error( "error(401). not authorized" );
                return result.status;
            } else if ( result.status == 200 && ( result.data as any ) == "Request failed with status code 400" ) {
                // refresh token の更新エラー
                console.error( "error(200) and refresh token error" );
                return 401;
            } else {
                console.error("error code:%s data:%O", result.status, result.data );
                return undefined;
            }
        } catch( err: any ) {
            const status = err?.response?.status;
            console.log("★response:%O status:%O", err.response, status)
            if( status == 401 ) {
                // 認証してくださいエラー
                console.error( "error(401). not authorized" );
                return status;
            } else {
                // セッションが無い
                console.error("error code:%s data:%O", status, err );
                return undefined;
            }
        }
    }

    /**
     * 組織内メンバー一覧を取得する
     * @param domainId 組織ID
     * @param offset オフセット
     * @param limit リミット
     * @returns
     */
    public async getDomainMembers( domainId: string, offset: number = 0, limit: number = 20, ): Promise<DirectDomainMembersType|number|undefined> {
        try {
            const result = await this.getApi<DirectDomainMembersType>( ServerApi.domainMembers( domainId, offset, limit ) );
            if( result.status == 200 && result.data?.contents ) {
                return result.data;
            } else if( result.status == 401 ) {
                // 認証してくださいエラー
                return result.status;
            } else if ( result.status == 200 && ( result.data as any ) == "Request failed with status code 400" ) {
                // refresh token の更新エラー
                console.error( "error(200) and refresh token error" );
                return 401;
            } else if ( result.status == 200 && ( result.data as any ).startsWith("Unexpected") ) {
                // direct REST API サーバー自体のエラー
                console.error( "error(200) and REST API server error:", result.data )
                return 500;
            } else {
                console.error("error code:%s data:%O", result.status, result.data );
                return undefined;
            }
        } catch( err: any ) {
            const status = err?.response?.status;
            console.log("★response:%O status:%O", err.response, status)
            if( status == 401 ) {
                // 認証してくださいエラー
                console.error( "error(401). not authorized" );
                return status;
            } else {
                // セッションが無い
                console.error("error code:%s data:%O", status, err );
                return undefined;
            }
        }
    }

    /**
     * 話題の投稿数を更新する
     * @param domainId 組織ID
     * @param topicId 話題ID
     * @param updateMessageCount true: 話題の投稿数を更新する
     */
    public async putTopicMessageCount( domainId: string, topicId: string, updateMessageCount: boolean ): Promise<void> {
        try {
            const result = await this.putApi<any>( ServerApi.topicMessageCount, { domainId: domainId, topicId: topicId, updateMessageCount: updateMessageCount } );
            if (result) { // エラーメッセージが返された場合
                console.error("error:%O", result);
            }
        } catch( err: any ) {
            console.error("error code:%s, data:%O", err?.response?.status, err);
        }
    }

    /**
     * 話題取得
     * @param domainId
     * @returns
     */
    public async getTopics( domainId: string ): Promise<{ topics: DbTopic[], totalCount: number }|number|undefined> {
        try {
            const result = await this.getApi<{ topics: DbTopic[], totalCount: number }>( ServerApi.topicListByAcl(domainId) );
            if( result.status == 200 && result.data && typeof result.data !== 'string' ) {
                return result.data;
            } else if( result.status == 401 ) {
                // 認証してくださいエラー
                return result.status;
            } else {
                // セッションが無い
                console.error("error code:%s data:%O", status );
                return undefined;
            }
        } catch( err: any ) {
            const status = err?.response?.status;
            console.log("★response:%O status:%O", err.response, status)
            if( status == 401 ) {
                // 認証してくださいエラー
                console.error( "error(401). not authorized" );
                return status;
            } else {
                // セッションが無い
                console.error("error code:%s data:%O", status, err );
                return undefined;
            }
        }
    }

    // API共通処理
    private async _apiAccess<T>( params: string ): Promise<T|undefined|number> {
        try {
            const result = await this.getApi<T>( params );
            if( result.status == 200 && result.data ) {
                return result.data;
            } else if( result.status == 401 ) {
                // 認証してくださいエラー
                return result.status;
            } else {
                // セッションが無い
                console.error("error code:%s data:%O", status );
                return undefined;
            }
        } catch ( err: any ) {
            const status = err?.response?.status;
            console.log("★response:%O status:%O", err.response, status)
            if( status == 401 ) {
                // 認証してくださいエラー
                console.error( "error(401). not authorized" );
                return status;
            } else {
                // セッションが無い
                console.error("error code:%s data:%O", status, err );
                return undefined;
            }
        }
    }

    /** directのアプリ一覧を取得する */
    public async listApps( domainId: string ): Promise<SolutionLinkResponse|undefined|number> {
        const params = ServerApi.listApps(domainId);
        return this._apiAccess( params )
    }

    /** 部署の更新を行う */
    public async upsertDepartments( domainId: string ): Promise<DbDepartment[]|undefined> {
        try {
            const result = await this.putApi<DbDepartment[]>( ServerApi.upsertDepartments, { domainId: domainId } );
            console.log("results", result);
            if( !result || typeof result === "string" ) {
                console.error("error:%O", result);
                return undefined;
            } else {
                return result;
            }
        } catch( err: any ) {
            console.error("error code:%s, data:%O", err?.response?.status, err);
            return undefined;
        }
    }

    /** 部署情報を取得 */
    public async listDepartments( domainId: string ): Promise<Department[]> {
        try {
            const result = await this.getApi<Department[]>( ServerApi.listDepartments(domainId) );
            if( result.status == 200 && result.data && typeof result.data !== 'string' ) {
                return result.data;
            } else if( result.status == 401 ) {
                // 認証してくださいエラー
                return [];
            } else if( result.status == 403 ) {
                // 権限がない
                console.error( "error(403) access denied." );
                return [];
            } else {
                // セッションが無い
                console.error("error code:%s, data:%O", result);
                return [];
            }
        } catch( err: any ) {
            const status = err?.response?.status;
            console.log("★response:%O status:%O", err.response, status)
            if( status == 401 ) {
                // 認証してくださいエラー
                console.error( "error(401). not authorized" );
                return [];
            } else if( status == 403 ) {
                // 権限がない
                console.error( "error(403) access denied." );
                return [];
            } else {
                // セッションが無い
                console.error("error code:%s data:%O", status, err );
                return [];
            }
        }
    }

    /** directの組織情報を取得 */
    public async listDomains(): Promise<Domains|undefined|number> {
        const params = ServerApi.listDomains();
        return this._apiAccess(params);
    }

    /**
     * カテゴリー作成
     * @param category 
     * @returns 
     */
    public async createCategory( category: Category ): Promise<DbCategory|undefined> {
        try {
            const result = await this.putApi<DbCategory>( ServerApi.createCategory, category );
            console.log("results", result);
            if( !result || typeof result === "string" ) {
                console.error("error:%O", result);
                return undefined;
            } else {
                return result;
            }
        } catch( err: any ) {
            console.error("error code:%s, data:%O", err?.response?.status, err);
            return undefined;
        }
    }

    /**
     * カテゴリー一覧取得
     * @param domainId 
     * @returns 
     */
    public async listCategories( domainId: string ): Promise<DbCategory[]|undefined> {
        try {
            const result = await this.getApi<DbCategory[]>( ServerApi.listCategories(domainId) );
            if( result.status == 200 && result.data && typeof result.data !== 'string' ) {
                return result.data;
            } else if( result.status == 401 ) {
                // 認証してくださいエラー
                return undefined;
            } else {
                // セッションが無い
                console.error("error code:%s data:%O", status );
                return undefined;
            }
        } catch( err: any ) {
            console.error("error code:%s, data:%O", err?.response?.status, err);
            return undefined;
        }
    }

    /**
     * カテゴリー編集
     * @param category 
     * @returns 
     */
    public async updateCategory( category: Partial<Category> ): Promise<DbCategory|undefined> {
        try {
            const result = await this.putApi<DbCategory>( ServerApi.updateCategory, category );
            if( !result || typeof result === "string" ) {
                console.error("error:%O", result);
                return undefined;
            } else {
                return result;
            }
        } catch( err: any ) {
            console.error("error code:%s, data:%O", err?.response?.status, err);
            return undefined;
        }
    }

    /**
     * 話題作成
     * @param topic
     * @returns
     */
    public async createTopic( topic: Topic ): Promise<DbTopic|undefined> {
        try {
            const result = await this.putApi<DbTopic>( ServerApi.createTopic, topic );
            if( !result || typeof result === "string" ) {
                // 無料版の制限エラー
                if( typeof result === "string" && (result as string).match(/exceeded limit/) ) {
                    EventManager.freeAlertEvent();
                }
                console.error("error:%O", result);
                return undefined;
            } else {
                return result;
            }
        } catch( err: any ) {
            console.error("error code:%s, data:%O", err?.response?.status, err);
            return undefined;
        }
    }

    /**
     * 話題更新
     * @param topic
     * @returns
     */
    public async updateTopic( topic: Partial<Topic> ): Promise<DbTopic|undefined> {
        try {
            const result = await this.putApi<DbTopic>( ServerApi.updateTopic, topic );
            if( !result || typeof result === "string" ) {
                console.error("error:%O", result);
                return undefined;
            } else {
                return result;
            }
        } catch( err: any ) {
            console.error("error code:%s, data:%O", err?.response?.status, err);
            return undefined;
        }
    }

    /**
     * 投稿作成
     * @param message 
     * @returns 
     */
    public async createMessage( message: Message ): Promise<DbMessage|undefined> {
        try {
            const result = await this.putApi<DbMessage>( ServerApi.createMessage, message );
            if( !result || typeof result === "string" ) {
                // 無料版の制限エラー
                if( typeof result === "string" && (result as string).match(/exceeded limit/) ) {
                    EventManager.freeAlertEvent();
                }
                console.error("error:%O", result);
                return undefined;
            } else {
                return result;
            }
        } catch( err: any ) {
            console.error("error code:%s, data:%O", err?.response?.status, err);
            return undefined;
        }
    }

    /**
     * 投稿編集
     * @param message 
     * @returns 
     */
    public async updateMessage( message: Partial<Message> ): Promise<DbMessage|undefined> {
        try {
            const result = await this.putApi<DbMessage>( ServerApi.updateMessage, message );
            if( !result || typeof result === "string" ) {
                console.error("error:%O", result);
                return undefined;
            } else {
                return result;
            }
        } catch( err: any ) {
            console.error("error code:%s, data:%O", err?.response?.status, err);
            return undefined;
        }
    }


    /**
     * 投稿一覧取得
     * @param domainId 
     * @param topicId 
     * @param messageId
     * @returns 
     */
    public async listMessages( domainId: string, topicId: string, messageId?: string ): Promise<DbMessage[]|undefined> {
        try {
            const result = await this.getApi<DbMessage[]>( ServerApi.listMessages(domainId, topicId, messageId) );
            if( result.status == 200 && result.data && typeof result.data !== 'string' ) {
                return result.data;
            } else if( result.status == 401 ) {
                // 認証してくださいエラー
                return undefined;
            } else {
                // セッションが無い
                console.error("error code:%s data:%O", status );
                return undefined;
            }
        } catch( err: any ) {
            console.error("error code:%s, data:%O", err?.response?.status, err);
            return undefined;
        }
    }

    /**
     * コメント作成
     * @param comment 
     * @returns 
     */
    public async createComment( comment: Comment ): Promise<DbComment|undefined> {
        try {
            const result = await this.putApi<DbComment>( ServerApi.createComment, comment );
            if( !result || typeof result === "string" ) {
                console.error("error:%O", result);
                return undefined;
            } else {
                return result;
            }
        } catch( err: any ) {
            console.error("error code:%s, data:%O", err?.response?.status, err);
            return undefined;
        }
    }

    /**
     * コメント更新
     * @param comment 
     * @returns 
     */
    public async updateComment( comment: Partial<Comment> ): Promise<DbComment|undefined> {
        try {
            const result = await this.putApi<DbComment>( ServerApi.updateComment, comment );
            if( !result || typeof result === "string" ) {
                console.error("error:%O", result);
                return undefined;
            } else {
                return result;
            }
        } catch( err: any ) {
            console.error("error code:%s, data:%O", err?.response?.status, err);
            return undefined;
        }
    }

    /**
     * コメント一覧取得
     * @param domainId 
     * @param topicId 
     * @param messageId 
     * @returns 
     */
    public async listComments( domainId: string, topicId: string, messageId?: string ): Promise<DbComment[]|undefined> {
        try {
            const result = await this.getApi<DbComment[]>( ServerApi.listComments(domainId, topicId, messageId) );
            if( result.status == 200 && result.data && typeof result.data !== 'string' ) {
                return result.data;
            } else if( result.status == 401 ) {
                // 認証してくださいエラー
                return undefined;
            } else {
                // セッションが無い
                console.error("error code:%s data:%O", status );
                return undefined;
            }
        } catch( err: any ) {
            console.error("error code:%s, data:%O", err?.response?.status, err);
            return undefined;
        }
    }

    /** ロガーに記録する */
    public static logging( command: string, params: any ): void {
        try {
            const api = new ServerApiAccess();
            api.postApi( ServerApi.logging, {
                command,
                params,
            }).catch( ( err ) => {
                // loggingに対するエラー処理は行わない
                console.error( "error logging:", err )
            })
        } catch( err ) {
            console.error( "error logging:", err );
        }
    }

    public static debugSentry(): void {
        const api = new ServerApiAccess();
        api.getApi( ServerApi.error );
    }

}
