require("dotenv").config();

import { Auth } from 'aws-amplify';
import Amplify from 'aws-amplify';
import axios from 'axios';
import { AuthCogintoIdPool } from './auth/cognito-id-pool';
import { AuthCognitoUserPool } from './auth/cognito-user-pool';
import "url";
import { CognitoUser } from 'amazon-cognito-identity-js';
import { ServerApiAccess } from './server-api-access';
import { v4 as uuidv4 } from "uuid";
import sentry from './sentry';

const awsmobile = require("./aws-exports");


const CognitoRegion = "ap-northeast-1";
const CognitoUserPoolId = "ap-northeast-1_G4oQmo7Rs"
const CognitoUserPoolAppClientId = "13h7eqg7seivci33vo1p4ujtp";
const CognitoIdentityPoolId = "ap-northeast-1:e0d3e8ae-9dd3-461b-b66f-ace8b0699b90";
const CognitoDomain = "auth.staging.dx-flow.direct-ccs.com";

const PRODUCT_BASE          = process.env.VUE_APP_PRODUCT_BASE || "/";
const PRODUCT_URL           = process.env.VUE_APP_PRODUCT_URL || "http://localhost:8080/";
export const PRODUCT_STAGE  = process.env.VUE_APP_PRODUCT_STAGE || "DEV";


const AUTH_SERVER_URL   = process.env.VUE_APP_AUTH_SERVER_URL || "http://localhost:3000/";

// カスタムドメイン
const GRAPHQL_ENDPOINT = process.env.VUE_APP_GRAPHQL_ENDPOINT || "";
const APIGW_ENDPOINT = process.env.VUE_APP_APIGW_ENDPOINT || "";

// ログインを簡単にする場合は federated を指定
const SIGNIN_MODE: SignInMode = "custom";
// const SIGNIN_MODE: SignInMode = "federated";

export type PROVIDER = "direct" | "direct-dev" | "direct-staging" | "direct-mobile";
// OIDCログイン関係
const LOGIN_PATH        = ( provider: PROVIDER ) => {
    switch( provider ) {
        case "direct":          return `${PRODUCT_BASE}oauth2/login`;
        case "direct-dev":      return `${PRODUCT_BASE}oauth2/login-dev`;
        case "direct-staging":  return `${PRODUCT_BASE}oauth2/login-stg`;
        case "direct-mobile":   return `${PRODUCT_BASE}oauth2/login-mbl`;
        default:                return `${PRODUCT_BASE}oauth2/login`;
    }
}
const OIDC_LOGIN_URL    = ( provider: PROVIDER ) => new URL( LOGIN_PATH(provider), AUTH_SERVER_URL ).toString();
const OIDC_LOGOUT_URL   = new URL( `${PRODUCT_BASE}oauth2/logout`, AUTH_SERVER_URL ).toString();
const OIDC_SUCCESS_REDIRECT = PRODUCT_URL;

export type ServerUserInfo = {
    id: string,                 //!< direct id
    name: string,               //!< ユーザー名
    profileImageUrl: string,    //!< プロフィール画像のURL
    domains: any,               //!< domains API の結果
    token: string,              //!< access token
    idToken: string,            //!< id token
    // refreshToken: string,       //!< refresh token
    exp: number,                //!< token 有効期限
}

// ログインに使う方式
// それぞれ Cognito ID Pool / Cognito User Pool(Custom認証) / Cognito User Pool(OIDC認証)
export type SignInMode = "idpool"|"custom"|"federated";

const mergeAuthSetting = ( config: any, awsexports: any ) => {
    if( !awsexports ) return;
    // cognito関連の設定値を Auth に反映していく
    if( !config.Auth ) config.Auth = {}

    const copy = ( key: string, toKey: string ) => {
        if( awsexports[key] ) {
            config.Auth[toKey] = awsexports[key];
        }
    }
    copy( "aws_cognito_region", "region" );
    copy( "aws_user_pools_id", "userPoolId" );
    copy( "aws_user_pools_web_client_id", "userPoolWebClientId" );
    copy( "aws_cognito_identity_pool_id", "identityPoolId" )
}

const base = {
    Auth: {
        region: CognitoRegion,
        userPoolId: CognitoUserPoolId,
        userPoolWebClientId: CognitoUserPoolAppClientId,
        identityPoolId: CognitoIdentityPoolId,
        oauth: {
            domain: `${CognitoDomain}`,
            scope: ['openid', 'email', 'phone', 'profile', "aws.cognito.signin.user.admin"],
            redirectSignIn: ['http://localhost:8080','https://forum.staging.dx-flow.direct-ccs.com', 'https://forum.staging.dx-flow.feel-on.com'].join(","),
            redirectSignOut: ['http://localhost:8080', 'https://forum.staging.dx-flow.direct-ccs.com', 'https://forum.staging.dx-flow.feel-on.com'].join(","),
            responseType: 'code'
        },
    },
    Storage: {
        AWSS3: {
            bucket: 'openchannel.image-storage', //使用するS3バケット名
            region: 'ap-northeast-1', //リージョン
        }
    },
}
const isLocalHost = Boolean(
    window.location.hostname.startsWith("localhost")
    || window.location.hostname === "[::1]"
    || window.location.hostname.match( /^127(?:\.(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)){3}$/ )
);
const [ localRedirectSignIn, remoteRedirectSignIn, ] = base.Auth.oauth!.redirectSignIn.split(",");
const [ localRedirectSignOut, remoteRedirectSignOut, ] = base.Auth.oauth!.redirectSignOut.split(",");
const oauth = {
    ...base.Auth.oauth,
    redirectSignIn: isLocalHost ? localRedirectSignIn : remoteRedirectSignIn,
    redirectSignOut: isLocalHost ? localRedirectSignOut : remoteRedirectSignOut,
};

const updatedAwsConfig = {
    ...{
        "aws_project_region": "ap-northeast-1",
        "aws_cognito_region": "ap-northeast-1",
        "aws_user_pools_id": "ap-northeast-1_G4oQmo7Rs",
        "aws_user_pools_web_client_id": "13h7eqg7seivci33vo1p4ujtp",
        "aws_appsync_graphqlEndpoint": "https://pec7v2ovcnhgfpsxmuq7tyqyqy.appsync-api.ap-northeast-1.amazonaws.com/graphql",
        "aws_appsync_region": "ap-northeast-1",
        "aws_appsync_authenticationType": "AMAZON_COGNITO_USER_POOLS",
    },
    ...awsmobile.default,
    Auth: {
        ...base.Auth,
    },
    Storage: {
        AWSS3: {
            bucket: awsmobile.default.aws_user_files_s3_bucket,
            region: awsmobile.default.aws_user_files_s3_bucket_region,
        }
    },
}
if( SIGNIN_MODE == "custom" ) {
    // custom認証には federationTarget と oauthは不必要
    delete updatedAwsConfig["federationTarget"];
    delete updatedAwsConfig["oauth"];
    delete updatedAwsConfig["Auth"]["oauth"];
    updatedAwsConfig.Auth.authenticationFlowType = "CUSTOM_AUTH";
} else {
    // federationTargetが必要
    updatedAwsConfig.federationTarget = "COGNITO_USER_POOLS";
    updatedAwsConfig.oauth = { ...oauth };
    updatedAwsConfig.Auth.oauth = { ...oauth };
}
mergeAuthSetting( updatedAwsConfig, awsmobile.default );

if( GRAPHQL_ENDPOINT ) {
  updatedAwsConfig.aws_appsync_graphqlEndpoint = GRAPHQL_ENDPOINT;
  delete updatedAwsConfig.aws_appsync_apiKey;
}
if( APIGW_ENDPOINT ) {
  updatedAwsConfig.aws_cloud_logic_custom[0].endpoint = APIGW_ENDPOINT;
}

// @ts-ignore
//window.LOG_LEVEL="DEBUG"

console.log("★AWS Config:%O", updatedAwsConfig)
Amplify.configure( updatedAwsConfig );
export const config = () => updatedAwsConfig;

export default class AppAuth {
    public static async hasSession(): Promise<boolean> {
        const session = await Auth.currentSession();
        console.log("★seesion:%O", session);
        return !!session;
    }

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    public static async authenticatedUser(): Promise<CognitoUser> {
        const user = await Auth.currentAuthenticatedUser();
        return user;
    }

    /** 掲示板の認証サーバー上のユーザー情報を取得します */
    public static async getServerUserInfo(): Promise<ServerUserInfo|number|undefined> {
        const access = new ServerApiAccess();
        return await access.getUserData();
    }

    /** direct-authサーバから OidcLoginUrl を取得するためのURLを作成する */
    public static getOidcLoginUrl( provider: PROVIDER, option?: string ) {
        // query部分の構築
        const params = new URLSearchParams();
        if( option ) {
            params.append( "opt", option );
        }
        const id = uuidv4();
        params.append( "trace-id", id );

        const urlBase = OIDC_LOGIN_URL( provider );
        const url = new URL( urlBase );
        url.search = params.toString();
        return url.toString();
    }

    /**
     * サインイン処理を行います
     *
     * 掲示板では、AppSync と direct-js 両方の操作が必要になります
     *
     * ■AppSyncについて
     * AppSync の操作には Cognito User Pool の認証を用います
     * Cognito User Pool のログインには direct OIDC でログインします
     * 以降 AppSync の操作は Amplify と Cognito が面倒見てくれます
     *
     * ■direct-jsについて
     * direct-js の操作には direct の OIDC Access Token を用います
     * この access token の有効期限は 60 分のため、定期的に refresh token で更新します
     * しかし、この refresh token は OIDC の scope に offline_access を、
     * さらに prompt に consent を指定する必要があります。
     * Cognito の OIDC ではこの prompt を指定する方法が無いため、別途OIDCログインが必要となる
     *
     * @param provider
     * @param option モバイルの場合 OS-Type を渡す
     * @returns
     */
    public static async signIn( provider: PROVIDER, option?: "ios"|"and" ): Promise<any> {
        const mode = SIGNIN_MODE;
        if( mode == "federated" ) {
            // CognitoUserPool にログインする(OIDC Auth)
            console.log("★federated sign in")
            return await Auth.federatedSignIn( { customProvider: provider } );
        }

        // 1. direct-js 側のセッション情報を取得
        const serverUserInfo = await this.getServerUserInfo();
        console.log("★ServerUserInfo:%O", serverUserInfo)
        if( typeof serverUserInfo == "number" ) {
            if( serverUserInfo == 401 ) {
                // direct-js 用のサーバーセッションを行ってから再度やり直し
                const url = this.getOidcLoginUrl( provider, option );
                window.location.href = url;
                return;
            } else {
                // その他のエラー
                sentry.sendSentryError( serverUserInfo );
                return;
            }
        } else if( serverUserInfo == undefined ) {
            // その他のエラー
            sentry.sendSentryError( "unknown error" );
            // direct-js 用のサーバーセッションを行ってから再度やり直し
            const url = this.getOidcLoginUrl( provider, option );
            window.location.href = url;
            return;
        }

        // 2. Cognito User Pool へのログインを行う
        switch( mode ) {
            case "idpool": {
                try {
                    // CognitoIdPool にログインする
                    const auth = new AuthCogintoIdPool();
                    const credentials = await auth.auth( serverUserInfo );
                    if( credentials ) return window.location.href = OIDC_SUCCESS_REDIRECT;
                } catch( err: any ) {
                    switch( err ) {
                        case "signout":
                            await this.signOut();
                            break;
                        default:
                            console.log("%O",err)
                            // ログインサーバに飛ばす
                            window.location.href = OIDC_LOGIN_URL(provider);
                    }
                    return;
                }
                break;
            }
            case "custom": {
                try {
                    // CognitoUserPool にログインする(CUSTOM Auth)
                    const auth = new AuthCognitoUserPool( provider );
                    const user = await auth.auth( serverUserInfo );
                    if( user ) return window.location.href = OIDC_SUCCESS_REDIRECT;
                } catch( err:any ) {
                    if( err.code == "NotAuthorizedException" ) {
                        // ユーザーが見つからないエラー
                        // 再度ログイン処理
                        const url = OIDC_LOGIN_URL( provider ) + ( option ? `?opt=${option}` : "" );
                        window.location.href = url;
                        return;
                    }
                    switch( err ) {
                        case "signout":
                            await this.signOut();
                            break;
                        default: {
                            console.log("%O",err)
                            // ログインサーバに飛ばす
                            const url = OIDC_LOGIN_URL( provider ) + ( option ? `?opt=${option}` : "" );
                            window.location.href = url
                            break;
                        }
                    }
                    return;
                }
                break;
            }
            default:
                // nothing
                break;
        }
    }

    public static async signOut(): Promise<void> {
        console.log("SignOut")
        await Auth.signOut()
        window.location.href = OIDC_LOGOUT_URL;
    }
}
