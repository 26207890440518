import { API, graphqlOperation } from "aws-amplify";
import { AWSAppSyncRealTimeProvider } from "@aws-amplify/pubsub"
import { Message as DbMessage,
    ListMessagesQuery, ListMessagesQueryVariables,
    ListMessagesByUpdatedAtQuery, ListMessagesByUpdatedAtQueryVariables,
    CreateMessageMutation, CreateMessageMutationVariables,
    DeleteMessageMutation, DeleteMessageMutationVariables,
    UpdateMessageMutation, UpdateMessageMutationVariables,
    OnCreateMessageSubscription, OnCreateMessageSubscriptionVariables,
    OnUpdateMessageSubscription, OnUpdateMessageSubscriptionVariables,
    OnDeleteMessageSubscription, OnDeleteMessageSubscriptionVariables, Attachment, ModelSortDirection,
} from "@/API"
import { Message, Comment, User, Topic, Acl } from "@/model";
import { createMessage, deleteMessage, updateMessage } from "@/graphql/mutations";
import Observable from "zen-observable-ts";
import { ZenObservable } from "zen-observable-ts";
import { GraphQLResult } from "@aws-amplify/api-graphql";
import { onCreateMessage, onDeleteMessage, onUpdateMessage } from "@/graphql-custom/subscriptions";
import AclManager from "@/model/acl-manager";
import { ServerApiAccess } from "@/server-api-access";

type SubscriptionResult<T> = Observable<{
    provider: AWSAppSyncRealTimeProvider;
    value: GraphQLResult<T>;
}>

export type SubscriptionCallback = {
    onCreate: ( domainId: string, topicId: string, messageId: string ) => void,
    onUpdate: ( domainId: string, topicId: string, messageId: string ) => void,
    onDelete: ( domainId: string, topicId: string, messageId: string ) => void,
}

export type MessageReadResult = {
    messages: Message[],
    nextToken?: string|null|undefined,
}
type MessageReadResultRaw = {
    messages: DbMessage[],
    nextToken?: string|null|undefined,
}

type CommentFunc = (messageId: string ) =>  Comment[];

export default class MessageDao {

    /** domain毎のSubscription */
    private static subscriptions: {
        [ topicId: string ]: ZenObservable.Subscription[]
    } = {}

    private constructor(){
        // インスタンス作成不可
    }

    /**
     * MessageをDBに登録する
     * @param message   message
     * @param domainId  登録先の domainId
     * @param topicId   登録先の topicId
     */
    public static async create( message: Message ): Promise<Message|undefined>{
        ServerApiAccess.logging( "message.create", message );    // logging
        try {
            const api = new ServerApiAccess();
            const result = await api.createMessage(message);
            if( result ) {
                return Message.create( result, message.comments );
            } else {
                return undefined;
            }
        } catch( error ) {
            console.error("Message Add Error:%O", error );
            return undefined;
        }
    }

    /**
     * 投稿データを取得する
     * @param domainId      組織ID
     * @param topicId       話題ID
     * @param getCommentFun 投稿データに対応するコメントを取得するためのハンドラ
     * @param messageId     投稿ID
     * @returns 投稿データ
     */
    public static async read( domainId: string, topicId: string, getCommentFun: (messageId: string ) =>  Comment[], messageId?: string ): Promise<Message[]|undefined> {
        ServerApiAccess.logging( "message.read", { domainId: domainId, topicId: topicId, messageId: messageId } );
        try {
            const api = new ServerApiAccess();
            const results = await api.listMessages(domainId, topicId, messageId);
            if( results && typeof results !== "number" ) {
                const messages = results.map( ( message ) => {
                    const filtered = getCommentFun( message.id ) || [];
                    return Message.create( message, filtered );
                });
                return messages;
            } else {
                return undefined;
            }
        } catch( err ) {
            console.error( err );
            return undefined;
        }
    }

    /**
     * DBのデータ更新
     * @param data 更新するデータリスト
     */
    public static async update( message: Message ): Promise<Message|undefined> {
        ServerApiAccess.logging( "message.update", message );    // logging
        try {
            const api = new ServerApiAccess();
            const result = await api.updateMessage(message);
            if( result ) {
                const comments = message.comments || [];
                return Message.create( result, comments );
            } else {
                console.error( "Message Update Error :%O", result);
                return undefined;
            }
        } catch( error ) {
            console.error( "Message Update Error :%O", error );
            return undefined;
        }
    }

    /**
     * DBから削除実行
     * @param message 削除する投稿
     */
    public static async delete( me: User, message: Message ): Promise<boolean> {
        // ACLチェック
        if( AclManager.deleteMessage( me, message ) == false ) return false;

        const variables: DeleteMessageMutationVariables = { input: { topicId: message.topicId, id: message.id } }

        ServerApiAccess.logging( "message.delete", variables );    // logging

        const op = graphqlOperation( deleteMessage, variables );
        try {
            await ( API.graphql( op ) as Promise<{data: DeleteMessageMutation}> );
        } catch( error ) {
            console.error("error:%O", error );
        }

        // 未実装
        return true;
    }

    public static async registerES( message: DbMessage ) {
        const input: UpdateMessageMutationVariables = {
            input: {
                id: message.id,
                domainId: message.domainId,
                topicId: message.topicId,
                title: message.title,
                message: message.message,
                pinned: message.pinned,
                owner: message.owner,
                commentId: message.commentId,
                photos: message.photos,
                // updatedAt: new Date().toISOString(),
                createdAt: message.createdAt,
                deleted: message.deleted,
                deletedUser: message.deletedUser,
                acl: message.acl,
            }
        }

        const op = graphqlOperation( updateMessage, input );
        try {
            const result = ( await API.graphql( op ) ) as GraphQLResult< UpdateMessageMutation >;
            console.log(result);
        } catch( error ) {
            console.error( "Message Update Error :%O", error );
        }

        const input2: UpdateMessageMutationVariables = {
            input: {
                id: message.id,
                domainId: message.domainId,
                topicId: message.topicId,
                title: message.title,
                message: message.message,
                pinned: message.pinned,
                owner: message.owner,
                commentId: message.commentId,
                photos: message.photos,
                updatedAt: message.updatedAt,
                createdAt: message.createdAt,
                deleted: message.deleted,
                deletedUser: message.deletedUser,
                acl: message.acl,
            }
        }

        const op2 = graphqlOperation( updateMessage, input2 );
        try {
            const result = ( await API.graphql( op2 ) ) as GraphQLResult< UpdateMessageMutation >;
            console.log(result);
        } catch( error ) {
            console.error( "Message Update Error :%O", error );
        }
    }

    private static prepareSubscription( topicId: string ) {
        const tmp = this.subscriptions[ topicId ];
        if( tmp == undefined ) this.subscriptions[ topicId ] = [];
    }

    /** サブスクリプションを作成する */
    public static async registerSubscrptions( domainId: string, topicId: string, callback: SubscriptionCallback ): Promise<void> {
        this.prepareSubscription( topicId );

        const subscriptions = this.subscriptions[ topicId ];
        if( 0 < subscriptions.length ) return;  // 登録済み

        // create
        {
            const input: OnCreateMessageSubscriptionVariables = {
                domainId: domainId,
                topicId: topicId,
            };
            const graphql = await API.graphql(
                graphqlOperation( onCreateMessage, input )
            ) as SubscriptionResult<OnCreateMessageSubscription>

            const subscription = graphql.subscribe({
                next: ( { value } ) => {
                    const data = value.data;
                    if( data?.onCreateMessage ) {
                        const message = data.onCreateMessage;
                        callback.onCreate( message.domainId, message.topicId, message.id );
                    }
                },
                error: error => {
                    console.error( "Message Register Subscription Error:%O", error );
                },
            })
            subscriptions.push( subscription );
        }

        // update
        {
            const input: OnUpdateMessageSubscriptionVariables = {
                domainId: domainId,
                topicId: topicId,
            };
            const graphql = await API.graphql(
                graphqlOperation( onUpdateMessage, input )
            ) as SubscriptionResult<OnUpdateMessageSubscription>;
            const subscription = graphql.subscribe({
                next: ( { value } ) => {
                    if( value?.data?.onUpdateMessage ) {
                        const message = value.data.onUpdateMessage;
                        callback.onUpdate( message.domainId, message.topicId, message.id );
                    }
                }
            })
            subscriptions.push( subscription );
        }

        // delete
        {
            const input: OnDeleteMessageSubscriptionVariables = {
                domainId: domainId,
                topicId: topicId,
            };
            const graphql = await API.graphql(
                graphqlOperation( onDeleteMessage, input )
            ) as SubscriptionResult<OnDeleteMessageSubscription>;
            const subscription = graphql.subscribe( {
                next: ({value}) => {
                    if( value?.data?.onDeleteMessage ) {
                        const message = value.data.onDeleteMessage;
                        callback.onDelete( message.domainId, message.topicId, message.id );
                    }
                }
            })
            subscriptions.push( subscription );
        }
    }

    /** サブスクリプションを閉じる */
    public static closeSubscription( topicId: string ): void {
        const tmp = this.subscriptions[ topicId ] || [];
        tmp.forEach( tmp => tmp.unsubscribe() );
        this.subscriptions[ topicId ] = [];
    }
}
