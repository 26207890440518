import Vue from "vue";
import * as Sentry from "@sentry/vue";
import { BrowserTracing } from "@sentry/tracing";
import * as SentryBrowser from "@sentry/browser";
import VueRouter from "vue-router";

const SENTRY_ID = process.env.VUE_APP_SENTRY_ID || ''
const SENTRY_ORIGINS = (process.env.VUE_APP_SENTRY_ORIGINS || '') as string
const SENTRY_ENVIRONMENT = process.env.VUE_APP_SENTRY_ENVIRONMENT || undefined
const VUE_STAGE = process.env.VUE_APP_PRODUCT_STAGE || 'PRD'

const tracingOrigins = SENTRY_ORIGINS.split(",") as ( string | RegExp )[];
tracingOrigins.push( /^\// );

// true: sentryの利用設定がOFFの場合
const sentryDisable = () => SENTRY_ID == ''

// Traceサンプルレート。devやstagingだと1.0。productionは0.1ぐらいにする
const TRACES_SAMPLE_RATE = VUE_STAGE == "PRD" ? 0.1 : 1.0

export default {
    init: ( router: VueRouter ) => {
        if (sentryDisable()) return
        Sentry.init({
            Vue,
            dsn: SENTRY_ID,
            integrations: [
                // Tracingプラグイン
                new BrowserTracing({
                    routingInstrumentation: Sentry.vueRouterInstrumentation( router ),
                    tracePropagationTargets: tracingOrigins,
                }),

                // Replayプラグイン
                new Sentry.Replay(),
            ],

            // Tracing用オプション。devやstagingだと1.0。productionは0.1ぐらいにする
            tracesSampleRate: TRACES_SAMPLE_RATE,

            // Replay用オプション。エラー発生時のみ送るようにする
            replaysSessionSampleRate: 0,    // 一般時はReplay時は取得しない
            replaysOnErrorSampleRate: 1.0,  // エラー発生時は全部送る（発生１分前のデータから送信できる）

            environment: SENTRY_ENVIRONMENT,

            // for Vue3
            // logErrors: true,
            // trackComponents: true,

            // true: ブラウザのconsoleにSentryのデバッグ情報を流す
            // debug: false,
        })
    },
    sendSentry: (response: any): void => {
        if (sentryDisable()) return

        if (response && response.message) {
            SentryBrowser.captureMessage(response.message, 'debug')
        }
    },
    sendSentryError: ( error: any ): void => {
        if (sentryDisable()) return
        if (error) {
            SentryBrowser.captureException( error )
        }
    }
}

