/* tslint:disable */
/* eslint-disable */
// this is an auto generated file. This will be overwritten

import * as APITypes from "../API";
type GeneratedQuery<InputType, OutputType> = string & {
  __generatedQueryInput: InputType;
  __generatedQueryOutput: OutputType;
};

export const authenticatedImageUrl = /* GraphQL */ `query AuthenticatedImageUrl($url: String, $token: String) {
  authenticatedImageUrl(url: $url, token: $token) {
    statusCode
    body
    __typename
  }
}
` as GeneratedQuery<
  APITypes.AuthenticatedImageUrlQueryVariables,
  APITypes.AuthenticatedImageUrlQuery
>;
export const listDomains = /* GraphQL */ `query ListDomains($token: String, $provider: String) {
  listDomains(token: $token, provider: $provider) {
    statusCode
    body {
      domain_id_str
      domain_name
      frozen
      updated_at
      role {
        role_id
        type
        name
        __typename
      }
      contract {
        solution_ids
        direct_app_keys
        __typename
      }
      setting {
        allow_attachment_type
        allow_save_attachments {
          web
          ios
          android
          desktop
          __typename
        }
        allow_take_screenshot
        __typename
      }
      __typename
    }
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListDomainsQueryVariables,
  APITypes.ListDomainsQuery
>;
export const attachmentPushUrl = /* GraphQL */ `query AttachmentPushUrl($objectKey: String) {
  attachmentPushUrl(objectKey: $objectKey) {
    statusCode
    body
    __typename
  }
}
` as GeneratedQuery<
  APITypes.AttachmentPushUrlQueryVariables,
  APITypes.AttachmentPushUrlQuery
>;
export const getTopic = /* GraphQL */ `query GetTopic($domainId: String!, $id: ID!) {
  getTopic(domainId: $domainId, id: $id) {
    id
    domainId
    title
    desc
    icon {
      url
      mime
      __typename
    }
    pinned
    categoryId
    messages
    owner
    updatedAt
    createdAt
    deleted
    deletedUser
    acl {
      id
      refId
      refIdType
      base {
        create
        read
        update
        delete
        createTopic
        createMessage
        createComment
        createReactions
        __typename
      }
      guest {
        id
        refId
        refIdType
        create
        read
        update
        delete
        createTopic
        createMessage
        createComment
        createReactions
        __typename
      }
      items {
        id
        refId
        refIdType
        create
        read
        update
        delete
        createTopic
        createMessage
        createComment
        createReactions
        __typename
      }
      __typename
    }
    notification
    __typename
  }
}
` as GeneratedQuery<APITypes.GetTopicQueryVariables, APITypes.GetTopicQuery>;
export const listTopics = /* GraphQL */ `query ListTopics(
  $domainId: String
  $id: ModelIDKeyConditionInput
  $filter: ModelTopicFilterInput
  $limit: Int
  $nextToken: String
  $sortDirection: ModelSortDirection
) {
  listTopics(
    domainId: $domainId
    id: $id
    filter: $filter
    limit: $limit
    nextToken: $nextToken
    sortDirection: $sortDirection
  ) {
    items {
      id
      domainId
      title
      desc
      icon {
        url
        mime
        __typename
      }
      pinned
      categoryId
      messages
      owner
      updatedAt
      createdAt
      deleted
      deletedUser
      acl {
        id
        refId
        refIdType
        base {
          create
          read
          update
          delete
          createTopic
          createMessage
          createComment
          createReactions
          __typename
        }
        guest {
          id
          refId
          refIdType
          create
          read
          update
          delete
          createTopic
          createMessage
          createComment
          createReactions
          __typename
        }
        items {
          id
          refId
          refIdType
          create
          read
          update
          delete
          createTopic
          createMessage
          createComment
          createReactions
          __typename
        }
        __typename
      }
      notification
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListTopicsQueryVariables,
  APITypes.ListTopicsQuery
>;
export const getMessage = /* GraphQL */ `query GetMessage($topicId: String!, $id: ID!) {
  getMessage(topicId: $topicId, id: $id) {
    id
    domainId
    topicId
    title
    message
    pinned
    owner
    commentId
    photos {
      url
      mime
      __typename
    }
    updatedAt
    createdAt
    deleted
    deletedUser
    acl {
      id
      refId
      refIdType
      base {
        create
        read
        update
        delete
        createTopic
        createMessage
        createComment
        createReactions
        __typename
      }
      guest {
        id
        refId
        refIdType
        create
        read
        update
        delete
        createTopic
        createMessage
        createComment
        createReactions
        __typename
      }
      items {
        id
        refId
        refIdType
        create
        read
        update
        delete
        createTopic
        createMessage
        createComment
        createReactions
        __typename
      }
      __typename
    }
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetMessageQueryVariables,
  APITypes.GetMessageQuery
>;
export const listMessages = /* GraphQL */ `query ListMessages(
  $topicId: String
  $id: ModelIDKeyConditionInput
  $filter: ModelMessageFilterInput
  $limit: Int
  $nextToken: String
  $sortDirection: ModelSortDirection
) {
  listMessages(
    topicId: $topicId
    id: $id
    filter: $filter
    limit: $limit
    nextToken: $nextToken
    sortDirection: $sortDirection
  ) {
    items {
      id
      domainId
      topicId
      title
      message
      pinned
      owner
      commentId
      photos {
        url
        mime
        __typename
      }
      updatedAt
      createdAt
      deleted
      deletedUser
      acl {
        id
        refId
        refIdType
        base {
          create
          read
          update
          delete
          createTopic
          createMessage
          createComment
          createReactions
          __typename
        }
        guest {
          id
          refId
          refIdType
          create
          read
          update
          delete
          createTopic
          createMessage
          createComment
          createReactions
          __typename
        }
        items {
          id
          refId
          refIdType
          create
          read
          update
          delete
          createTopic
          createMessage
          createComment
          createReactions
          __typename
        }
        __typename
      }
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListMessagesQueryVariables,
  APITypes.ListMessagesQuery
>;
export const getComment = /* GraphQL */ `query GetComment($messageId: String!, $id: ID!) {
  getComment(messageId: $messageId, id: $id) {
    id
    domainId
    topicId
    messageId
    message
    photos {
      url
      mime
      __typename
    }
    owner
    updatedAt
    createdAt
    deleted
    deletedUser
    acl {
      id
      refId
      refIdType
      base {
        create
        read
        update
        delete
        createTopic
        createMessage
        createComment
        createReactions
        __typename
      }
      guest {
        id
        refId
        refIdType
        create
        read
        update
        delete
        createTopic
        createMessage
        createComment
        createReactions
        __typename
      }
      items {
        id
        refId
        refIdType
        create
        read
        update
        delete
        createTopic
        createMessage
        createComment
        createReactions
        __typename
      }
      __typename
    }
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetCommentQueryVariables,
  APITypes.GetCommentQuery
>;
export const listComments = /* GraphQL */ `query ListComments(
  $messageId: String
  $id: ModelIDKeyConditionInput
  $filter: ModelCommentFilterInput
  $limit: Int
  $nextToken: String
  $sortDirection: ModelSortDirection
) {
  listComments(
    messageId: $messageId
    id: $id
    filter: $filter
    limit: $limit
    nextToken: $nextToken
    sortDirection: $sortDirection
  ) {
    items {
      id
      domainId
      topicId
      messageId
      message
      photos {
        url
        mime
        __typename
      }
      owner
      updatedAt
      createdAt
      deleted
      deletedUser
      acl {
        id
        refId
        refIdType
        base {
          create
          read
          update
          delete
          createTopic
          createMessage
          createComment
          createReactions
          __typename
        }
        guest {
          id
          refId
          refIdType
          create
          read
          update
          delete
          createTopic
          createMessage
          createComment
          createReactions
          __typename
        }
        items {
          id
          refId
          refIdType
          create
          read
          update
          delete
          createTopic
          createMessage
          createComment
          createReactions
          __typename
        }
        __typename
      }
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListCommentsQueryVariables,
  APITypes.ListCommentsQuery
>;
export const getCategory = /* GraphQL */ `query GetCategory($domainId: String!, $id: ID!) {
  getCategory(domainId: $domainId, id: $id) {
    id
    title
    domainId
    color
    owner
    updatedAt
    createdAt
    deleted
    deletedUser
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetCategoryQueryVariables,
  APITypes.GetCategoryQuery
>;
export const listCategories = /* GraphQL */ `query ListCategories(
  $domainId: String
  $id: ModelIDKeyConditionInput
  $filter: ModelCategoryFilterInput
  $limit: Int
  $nextToken: String
  $sortDirection: ModelSortDirection
) {
  listCategories(
    domainId: $domainId
    id: $id
    filter: $filter
    limit: $limit
    nextToken: $nextToken
    sortDirection: $sortDirection
  ) {
    items {
      id
      title
      domainId
      color
      owner
      updatedAt
      createdAt
      deleted
      deletedUser
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListCategoriesQueryVariables,
  APITypes.ListCategoriesQuery
>;
export const getUser = /* GraphQL */ `query GetUser($directId: String!) {
  getUser(directId: $directId) {
    id
    directId
    name
    profileIcon
    domainIdList
    owner
    favorites
    likes
    updatedAt
    createdAt
    __typename
  }
}
` as GeneratedQuery<APITypes.GetUserQueryVariables, APITypes.GetUserQuery>;
export const listUsers = /* GraphQL */ `query ListUsers(
  $directId: String
  $filter: ModelUserFilterInput
  $limit: Int
  $nextToken: String
  $sortDirection: ModelSortDirection
) {
  listUsers(
    directId: $directId
    filter: $filter
    limit: $limit
    nextToken: $nextToken
    sortDirection: $sortDirection
  ) {
    items {
      id
      directId
      name
      profileIcon
      domainIdList
      owner
      favorites
      likes
      updatedAt
      createdAt
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<APITypes.ListUsersQueryVariables, APITypes.ListUsersQuery>;
export const getDomainUser = /* GraphQL */ `query GetDomainUser($domainId: String!, $userDirectId: String!) {
  getDomainUser(domainId: $domainId, userDirectId: $userDirectId) {
    domainId
    userDirectId
    user {
      id
      directId
      name
      profileIcon
      domainIdList
      owner
      favorites
      likes
      updatedAt
      createdAt
      __typename
    }
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetDomainUserQueryVariables,
  APITypes.GetDomainUserQuery
>;
export const listDomainUsers = /* GraphQL */ `query ListDomainUsers(
  $domainId: String
  $userDirectId: ModelStringKeyConditionInput
  $filter: ModelDomainUserFilterInput
  $limit: Int
  $nextToken: String
  $sortDirection: ModelSortDirection
) {
  listDomainUsers(
    domainId: $domainId
    userDirectId: $userDirectId
    filter: $filter
    limit: $limit
    nextToken: $nextToken
    sortDirection: $sortDirection
  ) {
    items {
      domainId
      userDirectId
      user {
        id
        directId
        name
        profileIcon
        domainIdList
        owner
        favorites
        likes
        updatedAt
        createdAt
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListDomainUsersQueryVariables,
  APITypes.ListDomainUsersQuery
>;
export const getDepartment = /* GraphQL */ `query GetDepartment($domainId: String!, $user_defined_key: String!) {
  getDepartment(domainId: $domainId, user_defined_key: $user_defined_key) {
    domainId
    department_id
    name
    user_defined_key
    order
    parent
    node
    createdAt
    updatedAt
    owner
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetDepartmentQueryVariables,
  APITypes.GetDepartmentQuery
>;
export const listDepartments = /* GraphQL */ `query ListDepartments(
  $domainId: String
  $user_defined_key: ModelStringKeyConditionInput
  $filter: ModelDepartmentFilterInput
  $limit: Int
  $nextToken: String
  $sortDirection: ModelSortDirection
) {
  listDepartments(
    domainId: $domainId
    user_defined_key: $user_defined_key
    filter: $filter
    limit: $limit
    nextToken: $nextToken
    sortDirection: $sortDirection
  ) {
    items {
      domainId
      department_id
      name
      user_defined_key
      order
      parent
      node
      createdAt
      updatedAt
      owner
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListDepartmentsQueryVariables,
  APITypes.ListDepartmentsQuery
>;
export const getNotifications = /* GraphQL */ `query GetNotifications($id: ID!) {
  getNotifications(id: $id) {
    id
    domainId
    title
    desc
    users
    departments
    exclude_users
    exclude_departments
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetNotificationsQueryVariables,
  APITypes.GetNotificationsQuery
>;
export const listNotifications = /* GraphQL */ `query ListNotifications(
  $filter: ModelNotificationsFilterInput
  $limit: Int
  $nextToken: String
) {
  listNotifications(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      domainId
      title
      desc
      users
      departments
      exclude_users
      exclude_departments
      createdAt
      updatedAt
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListNotificationsQueryVariables,
  APITypes.ListNotificationsQuery
>;
export const getIconReaction = /* GraphQL */ `query GetIconReaction(
  $type: IconReactionType!
  $targetType: ReactionTargetType!
  $domainId: String!
  $topicId: String!
  $messageId: String!
  $commentId: String!
) {
  getIconReaction(
    type: $type
    targetType: $targetType
    domainId: $domainId
    topicId: $topicId
    messageId: $messageId
    commentId: $commentId
  ) {
    type
    targetType
    domainId
    topicId
    messageId
    commentId
    userIdList
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetIconReactionQueryVariables,
  APITypes.GetIconReactionQuery
>;
export const listIconReactions = /* GraphQL */ `query ListIconReactions(
  $type: IconReactionType
  $targetTypeDomainIdTopicIdMessageIdCommentId: ModelIconReactionPrimaryCompositeKeyConditionInput
  $filter: ModelIconReactionFilterInput
  $limit: Int
  $nextToken: String
  $sortDirection: ModelSortDirection
) {
  listIconReactions(
    type: $type
    targetTypeDomainIdTopicIdMessageIdCommentId: $targetTypeDomainIdTopicIdMessageIdCommentId
    filter: $filter
    limit: $limit
    nextToken: $nextToken
    sortDirection: $sortDirection
  ) {
    items {
      type
      targetType
      domainId
      topicId
      messageId
      commentId
      userIdList
      createdAt
      updatedAt
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListIconReactionsQueryVariables,
  APITypes.ListIconReactionsQuery
>;
export const getDomainSettings = /* GraphQL */ `query GetDomainSettings($id: ID!) {
  getDomainSettings(id: $id) {
    id
    createTopicAuthorityGroupPolicy {
      admin
      user
      __typename
    }
    updateTopicAuthorityGroupPolicy {
      admin
      user
      __typename
    }
    attachmentPolicy
    attachmentFileType {
      image
      movie
      pdf
      office
      __typename
    }
    downloadPolicy
    createdAt
    updatedAt
    owner
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetDomainSettingsQueryVariables,
  APITypes.GetDomainSettingsQuery
>;
export const listDomainSettings = /* GraphQL */ `query ListDomainSettings(
  $filter: ModelDomainSettingsFilterInput
  $limit: Int
  $nextToken: String
) {
  listDomainSettings(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      createTopicAuthorityGroupPolicy {
        admin
        user
        __typename
      }
      updateTopicAuthorityGroupPolicy {
        admin
        user
        __typename
      }
      attachmentPolicy
      attachmentFileType {
        image
        movie
        pdf
        office
        __typename
      }
      downloadPolicy
      createdAt
      updatedAt
      owner
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListDomainSettingsQueryVariables,
  APITypes.ListDomainSettingsQuery
>;
export const getAllowFeature = /* GraphQL */ `query GetAllowFeature($keyword: String!) {
  getAllowFeature(keyword: $keyword) {
    keyword
    releasePhase
    desc
    updatedAt
    createdAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetAllowFeatureQueryVariables,
  APITypes.GetAllowFeatureQuery
>;
export const listAllowFeatures = /* GraphQL */ `query ListAllowFeatures(
  $keyword: String
  $filter: ModelAllowFeatureFilterInput
  $limit: Int
  $nextToken: String
  $sortDirection: ModelSortDirection
) {
  listAllowFeatures(
    keyword: $keyword
    filter: $filter
    limit: $limit
    nextToken: $nextToken
    sortDirection: $sortDirection
  ) {
    items {
      keyword
      releasePhase
      desc
      updatedAt
      createdAt
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListAllowFeaturesQueryVariables,
  APITypes.ListAllowFeaturesQuery
>;
export const ListTopicsByDomainId = /* GraphQL */ `query ListTopicsByDomainId(
  $domainId: String
  $sortDirection: ModelSortDirection
  $filter: ModelTopicFilterInput
  $limit: Int
  $nextToken: String
) {
  ListTopicsByDomainId(
    domainId: $domainId
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      domainId
      title
      desc
      icon {
        url
        mime
        __typename
      }
      pinned
      categoryId
      messages
      owner
      updatedAt
      createdAt
      deleted
      deletedUser
      acl {
        id
        refId
        refIdType
        base {
          create
          read
          update
          delete
          createTopic
          createMessage
          createComment
          createReactions
          __typename
        }
        guest {
          id
          refId
          refIdType
          create
          read
          update
          delete
          createTopic
          createMessage
          createComment
          createReactions
          __typename
        }
        items {
          id
          refId
          refIdType
          create
          read
          update
          delete
          createTopic
          createMessage
          createComment
          createReactions
          __typename
        }
        __typename
      }
      notification
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListTopicsByDomainIdQueryVariables,
  APITypes.ListTopicsByDomainIdQuery
>;
export const ListMessagesByDomainAndTopic = /* GraphQL */ `query ListMessagesByDomainAndTopic(
  $domainId: String
  $topicId: ModelStringKeyConditionInput
  $sortDirection: ModelSortDirection
  $filter: ModelMessageFilterInput
  $limit: Int
  $nextToken: String
) {
  ListMessagesByDomainAndTopic(
    domainId: $domainId
    topicId: $topicId
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      domainId
      topicId
      title
      message
      pinned
      owner
      commentId
      photos {
        url
        mime
        __typename
      }
      updatedAt
      createdAt
      deleted
      deletedUser
      acl {
        id
        refId
        refIdType
        base {
          create
          read
          update
          delete
          createTopic
          createMessage
          createComment
          createReactions
          __typename
        }
        guest {
          id
          refId
          refIdType
          create
          read
          update
          delete
          createTopic
          createMessage
          createComment
          createReactions
          __typename
        }
        items {
          id
          refId
          refIdType
          create
          read
          update
          delete
          createTopic
          createMessage
          createComment
          createReactions
          __typename
        }
        __typename
      }
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListMessagesByDomainAndTopicQueryVariables,
  APITypes.ListMessagesByDomainAndTopicQuery
>;
export const ListMessagesByUpdatedAt = /* GraphQL */ `query ListMessagesByUpdatedAt(
  $topicId: String
  $updatedAt: ModelStringKeyConditionInput
  $sortDirection: ModelSortDirection
  $filter: ModelMessageFilterInput
  $limit: Int
  $nextToken: String
) {
  ListMessagesByUpdatedAt(
    topicId: $topicId
    updatedAt: $updatedAt
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      domainId
      topicId
      title
      message
      pinned
      owner
      commentId
      photos {
        url
        mime
        __typename
      }
      updatedAt
      createdAt
      deleted
      deletedUser
      acl {
        id
        refId
        refIdType
        base {
          create
          read
          update
          delete
          createTopic
          createMessage
          createComment
          createReactions
          __typename
        }
        guest {
          id
          refId
          refIdType
          create
          read
          update
          delete
          createTopic
          createMessage
          createComment
          createReactions
          __typename
        }
        items {
          id
          refId
          refIdType
          create
          read
          update
          delete
          createTopic
          createMessage
          createComment
          createReactions
          __typename
        }
        __typename
      }
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListMessagesByUpdatedAtQueryVariables,
  APITypes.ListMessagesByUpdatedAtQuery
>;
export const ListCommentsByTopicId = /* GraphQL */ `query ListCommentsByTopicId(
  $topicId: String
  $createdAt: ModelStringKeyConditionInput
  $sortDirection: ModelSortDirection
  $filter: ModelCommentFilterInput
  $limit: Int
  $nextToken: String
) {
  ListCommentsByTopicId(
    topicId: $topicId
    createdAt: $createdAt
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      domainId
      topicId
      messageId
      message
      photos {
        url
        mime
        __typename
      }
      owner
      updatedAt
      createdAt
      deleted
      deletedUser
      acl {
        id
        refId
        refIdType
        base {
          create
          read
          update
          delete
          createTopic
          createMessage
          createComment
          createReactions
          __typename
        }
        guest {
          id
          refId
          refIdType
          create
          read
          update
          delete
          createTopic
          createMessage
          createComment
          createReactions
          __typename
        }
        items {
          id
          refId
          refIdType
          create
          read
          update
          delete
          createTopic
          createMessage
          createComment
          createReactions
          __typename
        }
        __typename
      }
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListCommentsByTopicIdQueryVariables,
  APITypes.ListCommentsByTopicIdQuery
>;
export const ListCommentsByMessageId = /* GraphQL */ `query ListCommentsByMessageId(
  $topicId: String
  $messageIdCreatedAt: ModelCommentListCommentsByMessageIdCompositeKeyConditionInput
  $sortDirection: ModelSortDirection
  $filter: ModelCommentFilterInput
  $limit: Int
  $nextToken: String
) {
  ListCommentsByMessageId(
    topicId: $topicId
    messageIdCreatedAt: $messageIdCreatedAt
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      domainId
      topicId
      messageId
      message
      photos {
        url
        mime
        __typename
      }
      owner
      updatedAt
      createdAt
      deleted
      deletedUser
      acl {
        id
        refId
        refIdType
        base {
          create
          read
          update
          delete
          createTopic
          createMessage
          createComment
          createReactions
          __typename
        }
        guest {
          id
          refId
          refIdType
          create
          read
          update
          delete
          createTopic
          createMessage
          createComment
          createReactions
          __typename
        }
        items {
          id
          refId
          refIdType
          create
          read
          update
          delete
          createTopic
          createMessage
          createComment
          createReactions
          __typename
        }
        __typename
      }
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListCommentsByMessageIdQueryVariables,
  APITypes.ListCommentsByMessageIdQuery
>;
export const CategoryByDomainId = /* GraphQL */ `query CategoryByDomainId(
  $domainId: String
  $title: ModelStringKeyConditionInput
  $sortDirection: ModelSortDirection
  $filter: ModelCategoryFilterInput
  $limit: Int
  $nextToken: String
) {
  CategoryByDomainId(
    domainId: $domainId
    title: $title
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      title
      domainId
      color
      owner
      updatedAt
      createdAt
      deleted
      deletedUser
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.CategoryByDomainIdQueryVariables,
  APITypes.CategoryByDomainIdQuery
>;
export const UserByCognitoUserId = /* GraphQL */ `query UserByCognitoUserId(
  $id: ID
  $sortDirection: ModelSortDirection
  $filter: ModelUserFilterInput
  $limit: Int
  $nextToken: String
) {
  UserByCognitoUserId(
    id: $id
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      directId
      name
      profileIcon
      domainIdList
      owner
      favorites
      likes
      updatedAt
      createdAt
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.UserByCognitoUserIdQueryVariables,
  APITypes.UserByCognitoUserIdQuery
>;
export const DomainUsersByDomainId = /* GraphQL */ `query DomainUsersByDomainId(
  $domainId: String
  $sortDirection: ModelSortDirection
  $filter: ModelDomainUserFilterInput
  $limit: Int
  $nextToken: String
) {
  DomainUsersByDomainId(
    domainId: $domainId
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      domainId
      userDirectId
      user {
        id
        directId
        name
        profileIcon
        domainIdList
        owner
        favorites
        likes
        updatedAt
        createdAt
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.DomainUsersByDomainIdQueryVariables,
  APITypes.DomainUsersByDomainIdQuery
>;
export const DepartmentByOrder = /* GraphQL */ `query DepartmentByOrder(
  $domainId: String
  $order: ModelIntKeyConditionInput
  $sortDirection: ModelSortDirection
  $filter: ModelDepartmentFilterInput
  $limit: Int
  $nextToken: String
) {
  DepartmentByOrder(
    domainId: $domainId
    order: $order
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      domainId
      department_id
      name
      user_defined_key
      order
      parent
      node
      createdAt
      updatedAt
      owner
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.DepartmentByOrderQueryVariables,
  APITypes.DepartmentByOrderQuery
>;
export const ListIconReactionByDomainId = /* GraphQL */ `query ListIconReactionByDomainId(
  $type: IconReactionType
  $targetTypeDomainId: ModelIconReactionListIconReactionByDomaniIdCompositeKeyConditionInput
  $sortDirection: ModelSortDirection
  $filter: ModelIconReactionFilterInput
  $limit: Int
  $nextToken: String
) {
  ListIconReactionByDomainId(
    type: $type
    targetTypeDomainId: $targetTypeDomainId
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      type
      targetType
      domainId
      topicId
      messageId
      commentId
      userIdList
      createdAt
      updatedAt
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListIconReactionByDomainIdQueryVariables,
  APITypes.ListIconReactionByDomainIdQuery
>;
export const ListIconReactionByTopicId = /* GraphQL */ `query ListIconReactionByTopicId(
  $type: IconReactionType
  $targetTypeDomainIdTopicId: ModelIconReactionListIconReactionByTopicIdCompositeKeyConditionInput
  $sortDirection: ModelSortDirection
  $filter: ModelIconReactionFilterInput
  $limit: Int
  $nextToken: String
) {
  ListIconReactionByTopicId(
    type: $type
    targetTypeDomainIdTopicId: $targetTypeDomainIdTopicId
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      type
      targetType
      domainId
      topicId
      messageId
      commentId
      userIdList
      createdAt
      updatedAt
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListIconReactionByTopicIdQueryVariables,
  APITypes.ListIconReactionByTopicIdQuery
>;
export const searchTopics = /* GraphQL */ `query SearchTopics(
  $filter: SearchableTopicFilterInput
  $sort: SearchableTopicSortInput
  $limit: Int
  $nextToken: String
  $from: Int
) {
  searchTopics(
    filter: $filter
    sort: $sort
    limit: $limit
    nextToken: $nextToken
    from: $from
  ) {
    items {
      id
      domainId
      title
      desc
      icon {
        url
        mime
        __typename
      }
      pinned
      categoryId
      messages
      owner
      updatedAt
      createdAt
      deleted
      deletedUser
      acl {
        id
        refId
        refIdType
        base {
          create
          read
          update
          delete
          createTopic
          createMessage
          createComment
          createReactions
          __typename
        }
        guest {
          id
          refId
          refIdType
          create
          read
          update
          delete
          createTopic
          createMessage
          createComment
          createReactions
          __typename
        }
        items {
          id
          refId
          refIdType
          create
          read
          update
          delete
          createTopic
          createMessage
          createComment
          createReactions
          __typename
        }
        __typename
      }
      notification
      __typename
    }
    nextToken
    total
    __typename
  }
}
` as GeneratedQuery<
  APITypes.SearchTopicsQueryVariables,
  APITypes.SearchTopicsQuery
>;
export const searchMessages = /* GraphQL */ `query SearchMessages(
  $filter: SearchableMessageFilterInput
  $sort: SearchableMessageSortInput
  $limit: Int
  $nextToken: String
  $from: Int
) {
  searchMessages(
    filter: $filter
    sort: $sort
    limit: $limit
    nextToken: $nextToken
    from: $from
  ) {
    items {
      id
      domainId
      topicId
      title
      message
      pinned
      owner
      commentId
      photos {
        url
        mime
        __typename
      }
      updatedAt
      createdAt
      deleted
      deletedUser
      acl {
        id
        refId
        refIdType
        base {
          create
          read
          update
          delete
          createTopic
          createMessage
          createComment
          createReactions
          __typename
        }
        guest {
          id
          refId
          refIdType
          create
          read
          update
          delete
          createTopic
          createMessage
          createComment
          createReactions
          __typename
        }
        items {
          id
          refId
          refIdType
          create
          read
          update
          delete
          createTopic
          createMessage
          createComment
          createReactions
          __typename
        }
        __typename
      }
      __typename
    }
    nextToken
    total
    __typename
  }
}
` as GeneratedQuery<
  APITypes.SearchMessagesQueryVariables,
  APITypes.SearchMessagesQuery
>;
export const searchComments = /* GraphQL */ `query SearchComments(
  $filter: SearchableCommentFilterInput
  $sort: SearchableCommentSortInput
  $limit: Int
  $nextToken: String
  $from: Int
) {
  searchComments(
    filter: $filter
    sort: $sort
    limit: $limit
    nextToken: $nextToken
    from: $from
  ) {
    items {
      id
      domainId
      topicId
      messageId
      message
      photos {
        url
        mime
        __typename
      }
      owner
      updatedAt
      createdAt
      deleted
      deletedUser
      acl {
        id
        refId
        refIdType
        base {
          create
          read
          update
          delete
          createTopic
          createMessage
          createComment
          createReactions
          __typename
        }
        guest {
          id
          refId
          refIdType
          create
          read
          update
          delete
          createTopic
          createMessage
          createComment
          createReactions
          __typename
        }
        items {
          id
          refId
          refIdType
          create
          read
          update
          delete
          createTopic
          createMessage
          createComment
          createReactions
          __typename
        }
        __typename
      }
      __typename
    }
    nextToken
    total
    __typename
  }
}
` as GeneratedQuery<
  APITypes.SearchCommentsQueryVariables,
  APITypes.SearchCommentsQuery
>;
