import { API } from "aws-amplify";
import { CreateUserMutation, CreateUserMutationVariables, DeleteUserMutation, DeleteUserMutationVariables, User as DbUser, DomainUsersByDomainIdQuery, DomainUsersByDomainIdQueryVariables, GetDomainUserQuery, GetDomainUserQueryVariables, GetUserQueryVariables, ListDomainUsersQuery, ListDomainUsersQueryVariables, UpdateUserMutation, UpdateUserMutationVariables, UserByCognitoUserIdQuery, UserByCognitoUserIdQueryVariables } from "@/API";
import { DomainUsersByDomainId as domainUsersByDomainId, UserByCognitoUserId as userByCognitoUserId } from "@/graphql/queries";
import { createUser, deleteUser, updateUser } from "@/graphql/mutations";
import { User } from "@/model";
import { graphqlOperation, GraphQLResult } from "@aws-amplify/api-graphql";
import { ServerApiAccess } from "@/server-api-access";

export type UserReadResult = {
    users: User[],
    nextToken?: string|null|undefined,
}

export default class UserDao {
    public static async create( user: User ): Promise<User|undefined> {
        const data: CreateUserMutationVariables = {
            input: {
                id: user.id,
                directId: user.directId,
                name: user.name,
                profileIcon: user.profileIcon,
                domainIdList: user.domainIdList,
                // owner: user.owner,
                favorites: user.favorites,
                likes: user.likes,
                // updatedAt: user.updatedAt,
                // createdAt: user.createdAt,
            }
        }
        ServerApiAccess.logging( "user.create", data.input );
        const op = graphqlOperation( createUser, data );
        try {
            const apiResult = await ( API.graphql( op ) as Promise< GraphQLResult<CreateUserMutation> > );
            if( apiResult.data?.createUser ) {
                return User.create( apiResult.data.createUser );
            } else {
                return undefined;
            }
        } catch( error ) {
            console.log("User Add error:%O", error );
            throw error;
        }
    }

    /**
     * 組織内ユーザー一覧の取得
     * @param domainid 取得する組織ID情報
     */
    public static async read( domainId: string, next: string|null|undefined ): Promise<UserReadResult> {
        const data: DomainUsersByDomainIdQueryVariables = {
            domainId: domainId,
            nextToken: next,
        }
        ServerApiAccess.logging( "user.read", data );
        const op = graphqlOperation( domainUsersByDomainId, data );
        try {
            const result = await ( API.graphql( op ) as Promise< GraphQLResult<DomainUsersByDomainIdQuery> > );
            if( result.data?.DomainUsersByDomainId?.items ) {
                return this.domainUsersToUsers( result.data );
            } else {
                return { users: [] };
            }
        } catch( error ) {
            console.error("[DB]DOMAINUSER READ ERROR:%O", error)
            if( "data" in ( error as any ) ) {
                return this.domainUsersToUsers( ( error as any ).data );
            } else {
                return { users: [] };
            }
        }
    }

    private static domainUsersToUsers( data: GraphQLResult< DomainUsersByDomainIdQuery >["data"] ): { users: User[], nextToken: string|null|undefined } {
        const users = data?.DomainUsersByDomainId?.items
                        .filter( u => !!( u?.user ) )
                        .map( domainUser => User.create( domainUser!.user as DbUser ))
                        ;
        return { users: users || [], nextToken: data?.DomainUsersByDomainId?.nextToken };
    }

    public static async readByCognitoId( cognitoUserId: string ): Promise<User|undefined> {
        const data: UserByCognitoUserIdQueryVariables = {
            id: cognitoUserId,
        }
        ServerApiAccess.logging( "user.readByCognitoId", data );
        const op = graphqlOperation( userByCognitoUserId, data );
        const result = await ( API.graphql( op ) as Promise< GraphQLResult<UserByCognitoUserIdQuery> > );
        if( result.data?.UserByCognitoUserId?.items ) {
            const data = result.data.UserByCognitoUserId.items[0];
            if( data ) return User.create( data );
        } else {
            console.error("ReadByCognitoId ERROR:%O",result)
        }
        return undefined;
    }

    public static async update( user: User, updateAttr?: { favorites?: string[], likes?: string[] } ): Promise<User> {
        const data: UpdateUserMutationVariables = {
            input: {
                directId: user.directId,
                name: user.name,
                profileIcon: user.profileIcon,
                domainIdList: user.domainIdList,
                // owner: user.owner,
                favorites: updateAttr?.favorites || user.favorites,
                likes: updateAttr?.likes || user.likes,
                // updatedAt: user.updatedAt,
                // createdAt: user.createdAt,
            }
        }
        ServerApiAccess.logging( "user.update", data.input );
        const op = graphqlOperation( updateUser, data );
        const result = await ( API.graphql( op ) as Promise< GraphQLResult<UpdateUserMutation> > );
        if( result.data?.updateUser ) {
            return User.create( result.data.updateUser );
        } else {
            return user;
        }
    }

    public static async delete( user: User ): Promise<boolean> {
        const data: DeleteUserMutationVariables = {
            input: {
                directId: user.directId,
            }
        }
        const op = graphqlOperation( deleteUser, data );
        const result = await ( API.graphql( op ) as Promise< GraphQLResult<DeleteUserMutation> > );
        return result.data?.deleteUser ? true : false;
    }
}
