import cloneDeep from "lodash/cloneDeep";
import { Category, Topic, User, Acl } from "@/model";
import Vue from "vue";
import TopicDao from "./topic-dao";
import { ActionContext } from "vuex";
import { ServerApiAccess } from "@/server-api-access";
import AclManager from "@/model/acl-manager";
import { Department } from "@/components/acl/group-uesr-list/Departments/values/Department";
import { IndexdDB } from "@/indexddb";

type Topics = {
    [ domainId: string ]: Topic[],
}

type State = { topics: Topics, totalCount: {[domainId: string]: number} };
type RootState = { domainId: string, topicId: string, messageId: string };
type TopicActionContext = ActionContext<State, RootState>;
export type TopicUpdateMessagesPayload = { domainId: string, topicId: string, messageCount: number };

async function setIndexedDB( state: State ): Promise<void> {
    await Promise.all( Object.keys(state.topics).map( async( key ) => {
        await IndexdDB.set("TOPIC", key, state.topics[key]);
    }))
}

function normalize( topic: Topic, domainId: string ): string {
    Topic.normalize( topic, domainId );
    return topic.domainId;
}

const getDomainTopics = ( state: State, domainId: string ) => {
    let domainTopics = state.topics[ domainId ];
    if( !domainTopics ) {
        domainTopics = [];
        Vue.set( state.topics, domainId, domainTopics );
    }
    return domainTopics;
}

function addOrUpdateTopic( state: State, topic: Topic ) {
    normalize( topic, topic.domainId );
    const domainTopics = getDomainTopics( state, topic.domainId );
    const index = domainTopics.findIndex( t => t.id == topic.id );
    if( index < 0 ) {
        domainTopics.push( topic );
    } else {
        domainTopics.splice( index, 1, topic );
    }
}

function deleteTopic( state: State, domainId: string, id: string ) {
    const domainTopics = getDomainTopics( state, domainId );
    const index = domainTopics.findIndex( t => t.id == id );
    if( index < 0 ) {
        // 何もしない
    } else {
        domainTopics.splice( index, 1 );    // 削除
    }
}

const topicModule = {
    namespaced: true,
    state: {
        topics: {},
        totalCount: {}, // 組織毎の全話題数 (話題数による制限の判定)
    } as State,
    getters: {
        /**
         * 組織のTopic一覧を取得します
         * @param domainid 組織ID
         * @param exists   true: 削除されていないTopicのみ取得する false: すべて取得
         * @param ignoreAcl true: ACLを考慮せずに取得
         */
        get: ( state: State, getters: unknown, rootState: RootState ) => ( domainId: string, exists: boolean = true ): Topic[] => {
            const topics = state.topics[ domainId ] || [];
            const results = exists ? topics.filter( t => Topic.isExists( t ) ) : topics;
            return results;
        },

        /**
         * 指定Topicを取得します
         * @param domainId 組織ID
         * @param topicId  話題ID
         * @param exists   true: 削除されていないTopicを取得する false: 状態に寄らない
         * @returns
         */
        getOne: ( state: State, getters: unknown, rootState: RootState ) => ( domainId: string, topicId: string, exists: boolean = false ): Topic|undefined => {
            domainId = domainId || rootState.domainId;
            const domainTopics = state.topics[ domainId ] || [];
            const topic = domainTopics.find( topic => topic.id == topicId );
            return topic;
        },

        /**
         * 話題数を取得します
         * @param domainid 組織ID
         * @param exists   true: 削除されていないTopicのみ取得する false: すべて取得
         * @param ignoreAcl true: ACLを考慮せずに取得
         */
        getLength: ( state: State, getters: any ) => ( domainId: string, exists: boolean = false, ignoreAcl: boolean = false ): number => {
            const topics = getters["get"]( domainId, exists, ignoreAcl ) as Topic[]|undefined;
            return topics ? topics.length : 0;
        },

        /**
         * 全体の話題数を取得
         * @param domainId 組織ID
         * @returns 
         */
        getAllTopicsLength: ( state: State ) => ( domainId: string ): number|undefined => {
            return state.totalCount[domainId];
        },
    },
    mutations: {
        add( state: State, payload: { data: Topic|Topic[] } ): void {
            if( Array.isArray( payload.data ) ) {
                ( payload.data || [] ).forEach( topic => {
                    addOrUpdateTopic( state, topic );
                });
            } else {
                addOrUpdateTopic( state, payload.data );
            }
        },

        overwrite( state: State, payload: { data: Topic[], domainId: string } ): void {
            const topics = payload.data
            const domainId = payload.domainId
            Vue.set( state.topics, domainId, topics );
        },

        // 追加、もしくは更新を行う
        addOrUpdate( state: State, payload: { data: Topic|Topic[], users: User[], departments: Department[] } ): void {
            if( Array.isArray( payload.data ) ) {
                ( payload.data || [] ).forEach( topic => {
                    addOrUpdateTopic( state, topic );
                });
            } else {
                addOrUpdateTopic( state, payload.data );
            }
        },

        /** topicを更新する */
        update( state: State, payload: { data: Topic, users: User[], departments: Department[] } ): void {
            addOrUpdateTopic( state, payload.data );
        },

        /** topicを削除する */
        delete( state: State, payload: { domainId: string, topicId: string } ): void {
            deleteTopic( state, payload.domainId, payload.topicId );
        },

        // 話題数をセット
        setTotalCount( state: State, payload: { domainId: string, count: number } ): void {
            Vue.set( state.totalCount, payload.domainId, payload.count );
        },

    },
    actions: {

        async create( { commit, dispatch, state, rootState, rootGetters }:TopicActionContext, payload: { topic: Topic } ): Promise<void> {
            const topic = payload.topic;
            if( Topic.isTemporary( topic ) === false ) return undefined;
            normalize( topic, rootState.domainId );

            const domainId = topic.domainId;
            const users = rootGetters[ 'users/getByDomainId' ]( domainId );
            const departments = rootGetters[ 'domains/getDepartments' ] || [];
            const register = await TopicDao.create( topic );
            if( register ) {
                commit( 'addOrUpdate', { data: register, users: users, departments: departments } );
                await setIndexedDB( state );

                // 同期
                await dispatch("fetch", { domainId: domainId });

                // flowへの投稿
                const access = new ServerApiAccess();
                await access.postFlow( register, `話題：${register.title}`, rootGetters, "create" );
            }
        },

        /** Topicの更新 */
        async update( { commit, state, getters, rootGetters }:TopicActionContext, payload: { param: Partial<Topic> } ): Promise<void> {
            const param = payload.param;
            const topic = getters["getOne"]( param.domainId, param.id );
            if( !topic ) {
                console.error("topic is not found:", param.domainId, param.id );
                return;
            }
            const updated = cloneDeep( topic );
            updated.copyFrom( param );

            const domainId = topic.domainId;
            const users = rootGetters[ 'users/getByDomainId' ]( domainId );
            const departments = rootGetters[ 'domains/getDepartments' ] || [];
            const register = await TopicDao.update( updated );
            if( register ) {
                commit( "update", { data: register, users: users, departments: departments } );
                await setIndexedDB( state );

                // flowへの投稿
                const access = new ServerApiAccess();
                await access.postFlow( register, `話題更新：${register.title}`, rootGetters, "update" );
            }
        },

        /**
         * topicを追加する
         * @param topic: 追加するTopic
         * @param domainId: 追加先の組織ID(fallback用)
         */
        async add( { dispatch, commit, state, rootState }: TopicActionContext, payload: { topic: Topic, domainId?: string } ): Promise<void> {
            normalize( payload.topic, rootState.domainId );
            const topic = payload.topic;
            const domainId = payload.topic.domainId;

            // カテゴリー側の登録作業
            const category = topic.category;
            if( Category.isTemporary( category ) ) {
                const result = await dispatch( 'categories/create', { data: category, domainId: domainId }, { root: true } );
                if( result ) topic.category = result;   // 作成したもので差し替え
            }
            commit( "add", { data: topic } );
            await setIndexedDB( state );
        },

        /**
         * サーバ側から組織のTopicを取得する
         * @param domainId: 取得する組織のID
         * @param topicId:  取得する話題ID。未指定なら組織の話題全部になる
         */
        async fetch( { commit, state, rootGetters }: TopicActionContext, payload: { domainId: string, topicId?: string } ): Promise<void> {
            // 注：categories/fetchを行ってcommitされる前に到達した場合矛盾が発生する
            // 現状では、全トピックを取得
            const domainId = payload.domainId;
            const categories = rootGetters[ 'categories/get' ]( domainId ) || [];
            
            const results = await TopicDao.read( categories, domainId );
            if( results ) {
                const topics = results.topics;
                commit('setTotalCount', { domainId: domainId, count: results.totalCount })
                commit('overwrite', { data: topics, domainId: domainId });
                await setIndexedDB( state );
            }
        },

        /**
         * Topicを削除する
         * @param topicId: 削除するTopicId
         * @param deleted: true固定
         * @param deletedUser: 削除実行を行う direct user id
         */
        async delete( { commit, state, getters, rootGetters }: TopicActionContext, payload: { topicId: string, param:{ deleted: true, deletedUser: string } } ): Promise<void> {
            const domainId = rootGetters["domainId"];
            const topicId = payload.topicId;
            const topics = getters.get( domainId ) as Topic[] | undefined;
            if( !topics ) return;
            const topic = topics.find( t => t.id == topicId );
            if( !topic ) return;

            const me = rootGetters[ "users/me" ];

            // 削除フラグを付けた更新
            topic.deleted = payload.param.deleted;
            topic.deletedUser = payload.param.deletedUser;
            await TopicDao.update( topic );

            commit('delete', { domainId, topicId } );
            await setIndexedDB( state );

            // 通知
            const access = new ServerApiAccess();
            await access.postFlow( topic, `話題削除：${topic.title}`, rootGetters, "delete" );
    },

        /**
         * 再更新要求
         * このメソッド自体は何もしないので subscribAction 側が対処してください
         */
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        async invalidate( context: TopicActionContext, payload: { domainId: string, topicId: string } ): Promise<void> {
            // Nop
        },

        async registerSubscription( {commit, dispatch, rootGetters }: TopicActionContext, payload: { domainId: string, me: User } ): Promise<void> {
            const domainId = payload.domainId;
            try {
                await TopicDao.registerSubscrptions( domainId, {
                    onCreate:( domainId: string, topicId: string ) => {
                        dispatch("fetch", { domainId, topicId })
                    },
                    onUpdate: ( domainId: string, topicId: string, deleted?: boolean|null, deletedUser?: string|null, newAcl?: Acl ) => {
                        if( deleted ) {
                            commit("delete", { domainId, topicId })
                        } else {
                            const me = rootGetters[ "users/me" ] as User;
                            const oldTopic = rootGetters[ "topics/getOne" ]( domainId, topicId ) as Topic|undefined;
                            const oldAcl = oldTopic?.acl;
                            if( AclManager.readTopic( me, { domainId, acl: newAcl } ) ) {
                                dispatch( "fetch", { domainId, topicId }).then( () => {
                                    dispatch( "invalidate", { domainId, topicId, newAcl, oldAcl } );
                                })
                            } else {
                                commit( "delete", { domainId, topicId })
                            }

                        }
                    },
                    onDelete: ( domainId: string, topicId: string ) => {
                        commit( 'delete', { domainId, topicId } );
                    },
                } );
            } catch( error ) {
                console.error( "Topic登録エラー:%O", error );
            }
            return;
        },

        async removeSubscription( context: TopicActionContext, payload: { domainId: string } ): Promise<void> {
            const domainId = payload.domainId;
            await TopicDao.closeSubscription( domainId );
        },

        async restore({ state }: TopicActionContext): Promise<void> {
            const topics = await IndexdDB.getAll("TOPIC");
            if( topics ) {
                state.topics = topics;
            }
        },
    }
}

export default topicModule;
