import Vue from 'vue'
import VueRouter, { NavigationGuardNext, Route, RouteConfig } from 'vue-router'

import NavigationBar from "../components/NavigationBar.vue";
import Logout from "../views/Logout.vue";
import TopicList from "../components/TopicList.vue";
import TopicEdit from "../components/TopicEdit.vue";
import TopicSideMenuPanel from "../components/TopicSideMenuPanel.vue";
import MessageList from "../components/MessageList.vue";
import MessageEdit from '../components/MessageEdit.vue';
import SearchResult from'../components/SearchResult.vue';
import MessageSideMenuPanel from "../components/MessageSideMenuPanel.vue";
import DomainSelect from "../components/DomainSelect.vue";

Vue.use(VueRouter)

import store, { ISearchParam } from "../store"
import { Category, Topic, Message, Comment, User, Acl } from '@/model';
import S3AccessUtility from '@/components/s3-acceess-utility';
import { ReactionController } from '@/model/reaction-controller';
import { IconReactionType } from '@/API';
import { Store } from 'vuex';
import HistoryUtility from '@/history';
import AclManager from '@/model/acl-manager';
import { LoadingManager } from '@/components/loading/loading-manager';
import { AttachmentFileTypes } from '@/suppport-attachment-types';

// 検索結果表示用に整理した話題/投稿/コメントを統合したデータ構造
export type SearchResultItemData = {
    type: "topic" | "message" | "comment",
    updatedAt: Date,
    item: Message | Comment | Topic,
    text: string,
    topicTitle: string,
    messageTitle: string,
    pinned: boolean,
    star: boolean,
    link: string,
    acl?: Acl
}

const normalizeDomainId = ( store: Store<{domainId:string}>, domainId: string ) => {
    if( store.state.domainId !== domainId ) {
        store.dispatch("setDomainId", domainId );
        store.state.domainId = domainId;
    }
}
const normalizeTopicId = ( store: { state: { topicId: string } }, topicId: string ) => {
    if( store.state.topicId !== topicId ) store.state.topicId = topicId;
}
const normalizeMessageId = ( store: { state: { messageId: string } }, messageId: string ) => {
    if( store.state.messageId !== messageId ) store.state.messageId = messageId;
}

// ★の点灯チェック(検索データ用)
const hasStar = ( item: Topic | Message, user: User ) => item.star?.userIdList.includes( user.directId );

const routes: Array<RouteConfig> = [
    {
        path: '/:domainId/new-topic',
        name: 'new-topic',
        components: {
            default: TopicEdit,
        },
        props: {
            default: route => {
                normalizeDomainId( store, route.params.domainId );
                const domainId = store.state.domainId;
                const me = store.getters["users/me"] as User;
                const categories = store.getters["categories/get"]( domainId ) as Category[];
                // Direct組織の添付ファイル情報
                const allow_attachment_type = store.getters["domains/getAllowAttachmentType"] as AttachmentFileTypes;
                return {
                    type: "create",
                    categories,
                    domainId,
                    me,
                    allow_attachment_type: allow_attachment_type,
                    isMobile: true,
                }
            }
        }
    },
    {
        path: '/logout',
        name: 'logout',
        component: Logout,
    },
    {
        path: '/:domainId/:topicId/edit-topic',
        name: 'edit-topic',
        components: {
            default: TopicEdit,
        },
        props: {
            // eslint-disable-next-line @typescript-eslint/no-unused-vars
            default: route => {
                const domainId = route.params.domainId
                const categories = store.getters["categories/get"]( domainId ) as Category[];
                const topicId = route.params.topicId
                const topics = store.getters["topics/get"]( domainId ) as Topic[]|undefined;
                const topic = topics?.find( t => t.id == topicId );
                const me = store.getters["users/me"] as User;
                // Direct組織の添付ファイル情報
                const allow_attachment_type = store.getters["domains/getAllowAttachmentType"] as AttachmentFileTypes;
                return {
                    type: "edit",
                    categories,
                    topicId,
                    domainId,
                    title: topic?.title,
                    desc: topic?.desc,
                    me: me,
                    thumb: topic?.icon,
                    category: topic?.category,
                    pinned: topic?.pinned,
                    allow_attachment_type: allow_attachment_type,
                    acl: topic?.acl,
                    isMobile: true,
                }
            }
        }
    },
    {
        path: '/:domainId/:topicId/post-message',
        name: "post-message",
        components: {
            default: MessageEdit
        },
        props: {
            default: route => {
                normalizeDomainId( store, route.params.domainId );
                const domainId = route.params.id || store.state.domainId;
                normalizeTopicId( store, route.params.topicId );
                const topicId = route.params.topicId || "error";
                // Direct組織の添付ファイル情報
                const allow_attachment_type = store.getters["domains/getAllowAttachmentType"] as AttachmentFileTypes;
                return  {
                    nav: true,
                    type: 'create',
                    photos: [],
                    domainId: domainId,
                    topicId: topicId,
                    allow_attachment_type: allow_attachment_type,
                }
            }
        }
    },
    {
        path: '/edit-message/:domainId/:topicId/:messageId',
        name: "edit-message",
        components: {
            default: MessageEdit
        },
        props: {
            default: route => {
                const domainId = route.params.domainId || store.state.domainId;
                const topicId = route.params.topicId || "error";
                const messageId = route.params.messageId || "error";
                const messages = ( store.getters["messages/get"]( domainId, topicId ) || [] ) as Message[];
                const message = messages.find( m => m.id === messageId );
                const topic = store.getters.getTopic( domainId, topicId ) as Topic;
                // Direct組織の添付ファイル情報
                const allow_attachment_type = store.getters["domains/getAllowAttachmentType"] as AttachmentFileTypes;
                return {
                    nav: true,
                    type: 'edit',
                    messageId: messageId,
                    title: message?.title,
                    message: message?.message,
                    photos: message?.photos,
                    domainId: domainId,
                    topicId: topicId,
                    allow_attachment_type: allow_attachment_type,
                    acl: message?.acl,
                    topicAcl: topic?.acl,
                }
            }
        }
    },
    // {
    //     path: '/about',
    //     name: 'About',
    //     // route level code-splitting
    //     // this generates a separate chunk (about.[hash].js) for this route
    //     // which is lazy-loaded when the route is visited.
    //     component: () => import(/* webpackChunkName: "about" */ '../views/About.vue')
    // },

    // 組織選択
    {
        path: '/',
        name: 'domain-select',
        components: {
            nav: NavigationBar,
            sidemenu: TopicSideMenuPanel,   // サイドメニューは話題一覧と同じものを使用
            default: DomainSelect,
        },
        props: {
            nav: () => {
                const domainId = "";
                const domainName = "";
                const isMobile = store.getters["isMobile"] as boolean;
                if( isMobile ) {
                    return {
                        domainId: domainId,
                        back: true,
                        title: domainName,
                        subTitle: "",
                        appClose: true,
                        search: true,
                    }
                } else {
                    return {
                        domainId: domainId,
                        brand: true,
                        domains: true,
                        apps: true,
                        preset: true,
                        search: true,
                    }
                }
            },
            sidemenu: () => {
                return { domainId: "", categories: [], categoryFilter: [] }
            },
        }
    },

    // 話題一覧
    {
        path: '/:domainId',
        name: 'topic-list',
        components: {
            nav: NavigationBar,
            sidemenu: TopicSideMenuPanel,
            default: TopicList,
            search: SearchResult,
        },
        props: {
            default: route => {
                normalizeDomainId( store, route.params.domainId );
                const domainId = store.state.domainId;
                const isDomainEnable = store.getters[ "domains/isEnable" ](domainId);
                if( domainId && isDomainEnable ) {
                    const s3AccessUtility = S3AccessUtility.getInstance();
                    s3AccessUtility.init();
                    s3AccessUtility.setDomainId(domainId);
                      // fetch依頼を出す
                    const categories = store.getters["categories/get"]( domainId );
                    const categoryFilter = store.getters["categories/filter"]( domainId );
                    const topicList = store.getters["topics/get"]( domainId );
                    const users = store.getters["users/getByDomainId"]( domainId ) as User[];
                    const me = store.getters["users/me"] as User;
                    const props = {
                        domainId, categories, topicList, users, me, categoryFilter,
                        fab: AclManager.createTopic( store, domainId ),
                    }
                    return props;
                } else {
                    return {};
                }
            },
            // eslint-disable-next-line @typescript-eslint/no-unused-vars
            nav: route => {
                const domainId = store.state.domainId;
                const isMobile = store.getters["isMobile"] as boolean;
                const domainName = store.getters["domains/getDomainName"] as string;
                if( isMobile ) {
                    return {
                        domainId: domainId,
                        back: true,
                        title: domainName,
                        subTitle: "",
                        appClose: true,
                        search: true,
                    }
                } else {
                    return {
                        domainId: domainId,
                        brand: true,
                        domains: true,
                        apps: true,
                        preset: true,
                        search: true,
                    }
                }
            },
            search: route => {
                normalizeDomainId( store, route.params.domainId );
                const param = store.state.searchParam;
                const word = param.word;

                const allData: SearchResultItemData[] = [];
                const domainId = store.state.domainId;

                const users = store.getters["users/getByDomainId"]( domainId ) as User[];
                const me = store.getters["users/me"] as User;

                const topics = ( store.getters["topics/get"]( domainId ) || [] ) as Topic[];
                const ctl = new ReactionController( store );

                topics.forEach( topic => {
                    // Reactionマージ
                    topic.star = ctl.getReaction( topic, IconReactionType.FAVORITE );

                    const pinned = topic.pinned;
                    const star = !!hasStar(topic, me);
                    allData.push({ type: "topic", updatedAt: topic.updatedAt, topicTitle: topic.title, messageTitle: '', text: topic.desc, item: topic, pinned: pinned, star: star, link: `/${domainId}/${topic.id}/`, acl: topic.acl });
                })
                return {
                    word: word,
                    allData: allData,
                    users: users,
                    viewer: me,
                    domainId: domainId,
                }
            },
            sidemenu: _route => {
                const domainId = store.state.domainId;
                const categories = store.getters["categories/get"]( domainId );
                const categoryFilter = store.getters["categories/filter"]( domainId );
                const isDomainEnable = store.getters[ "domains/isEnable" ](domainId);
                if( !isDomainEnable ) {
                    return {
                        domainId,
                        categories: [],
                        categoryFilter: [],
                    }
                } else {
                    return {
                        domainId,
                        categories,
                        categoryFilter,
                    }
                }
            },
        }
    },

    // 投稿一覧
    {
        path: '/:domainId/:topicId/:messageId?/:commentId?',
        name: 'message-list',
        components: {
            nav: NavigationBar,
            sidemenu: MessageSideMenuPanel,
            default: MessageList,
            search: SearchResult,
        },
        props: {
            default: route => {
                normalizeDomainId( store, route.params.domainId );
                normalizeTopicId( store, route.params.topicId );
                const messageJumpId = route.params.messageId;
                const commentJumpId = route.params.commentId;

                const messageId = messageJumpId || "";
                normalizeMessageId( store, messageId );

                const domainId = store.state.domainId;
                const topicId = store.state.topicId;

                const s3AccessUtility = S3AccessUtility.getInstance();
                s3AccessUtility.init();
                s3AccessUtility.setDomainId(domainId);
                s3AccessUtility.setTopicId(topicId);

                // 話題取得
                const topic = store.getters["topics/getOne"]( domainId, topicId ) as Topic|undefined;
                if( topic ) {
                    const categories = store.getters["categories/get"]( domainId ) || [];
                    const category = categories.find( (c:Category) => c.id == topic.category.id );
                    topic.category = category ? category : topic.category;
                }
                // 話題が削除済みか
                const deleted = topic? false : true;
                // ゲスト許可
                const allowGuest = topic ? topic.acl.guest.read : true;

                // ユーザー一覧、ログインユーザーの取得
                const users = store.getters["users/getByDomainId"]( domainId ) as User[];
                const me = store.getters["users/me"] as User;

                // 投稿一覧の取得
                const storeMessages = ( store.getters["messages/get"]( domainId, topicId ) || [] ) as Message[];
                if( storeMessages.length > 0 ) {
                    const ctl = new ReactionController( store );
                    storeMessages.forEach( message => {
                        message.star = ctl.getReaction( message, IconReactionType.FAVORITE )
                        message.like = ctl.getReaction( message, IconReactionType.LIKE )
                    });
                }
                // topic の ★
                const ctl = new ReactionController( store );
                const topicStar = ctl.getReaction( topic, IconReactionType.FAVORITE );

                // Direct組織の添付ファイル情報
                const allow_attachment_type = store.getters["domains/getAllowAttachmentType"] as AttachmentFileTypes;
                const isDomainEnable = store.getters[ "domains/isEnable" ](domainId);
                if( !isDomainEnable || !topic ) {
                    return {
                        domainId: '',
                        topicId: '',
                        icon: undefined,
                        category: undefined,
                        title: '',
                        desc: '',
                        pinned: false,
                        topicOwner: '',
                        topicAcl: undefined,
                        star: undefined,
                        deleted: false,
                        messages: [],
                        allow_attachment_type: undefined,
                        fab: false,
                        users: [],
                        viewer: undefined,
                    }
                } else {
                    return {
                        domainId: topic?.domainId,
                        topicId: topic?.id,
                        icon: topic?.icon,
                        category: topic?.category,
                        title: topic?.title,
                        desc: topic?.desc,
                        pinned: topic?.pinned,
                        topicOwner: topic?.owner,
                        topicAcl: topic?.acl,
                        notification: topic?.notification,
                        star: topicStar,
                        deleted: deleted,
                        messages: storeMessages,
                        allow_attachment_type: allow_attachment_type,
                        fab: AclManager.createMessage( store, domainId ),
                        users: users,
                        viewer: me,
                        messageJump: messageJumpId,
                        commentJump: commentJumpId,
                    }
                }
            },
            nav: route => {
                const domainId = store.state.domainId;
                const topicId = route.params.topicId || "error";
                const topics = store.getters["topics/get"]( domainId ) as Topic[];
                const topic = topics ? topics.find( t => t.id == topicId ) : undefined;
                const isMobile = store.getters["isMobile"] as boolean;
                if( isMobile ) {
                    return {
                        domainId: domainId,
                        back: true,
                        title: topic?.title,
                        appClose: true,
                        search: true,
                    }
                } else {
                    return {
                        domainId: domainId,
                        brand: true,
                        domains: true,
                        apps: true,
                        back: true,
                        title: topic?.title,
                        preset: true,
                        search: true,
                    }
                }
            },
            search: route => {
                normalizeDomainId( store, route.params.domainId );
                normalizeTopicId( store, route.params.topicId );
                const isEndInitialLoad = LoadingManager.isEndInitialLoad();
                if( !isEndInitialLoad ) {
                    return {
                        word: '',
                        allData: [],
                        users: [],
                        viewer: undefined,
                        domainId: '',
                        topicId: '',
                    }
                }

                const param = store.state.searchParam;
                const word = param.word;

                const allData: SearchResultItemData[] = [];
                const domainId = store.state.domainId;
                const topicId = route.params.topicId || "error";

                const users = store.getters["users/getByDomainId"]( domainId ) as User[];
                const me = store.getters["users/me"] as User;

                const topics = store.getters["topics/get"]( domainId ) as Topic[];
                const topic = topics ? topics.find( t => t.id == topicId ) : undefined;
                const topicTitle = topic ? topic.title : '';

                const storeMessages = ( store.getters["messages/get"]( domainId, topicId ) || [] ) as Message[];
                const ctl = new ReactionController( store );
                storeMessages.forEach( (m:Message) => {
                    const comments = store.getters["comments/get"](m.topicId, m.id) as Comment[];
                    // コメントの配列をmessage.commentsへマージ
                    m.comments = comments;
                    // Reactionマージ
                    m.star = ctl.getReaction( m, IconReactionType.FAVORITE );
                    m.like = ctl.getReaction( m, IconReactionType.LIKE )

                    if( !m.deleted ) {
                        const pinned = m.pinned;
                        const star = !!hasStar(m, me);
                        allData.push({ type: "message", updatedAt: m.updatedAt, topicTitle: topicTitle, messageTitle: m.title, text: m.message, item: m, pinned: pinned, star: star, link: `/${domainId}/${topicId}/${m.id}`, acl: m.acl });
                    }
                    if( !comments || m.deleted ) return; // コメントがない場合 もしくは 親投稿が削除されている場合 コメントは検索から外す
                    comments.forEach( (c:Comment) => {
                        // Reactionマージ
                        c.like = ctl.getReaction( c, IconReactionType.LIKE );
                        if( !c.deleted ) { allData.push({ type: "comment", updatedAt: c.updatedAt, topicTitle: topicTitle, messageTitle: m.title, text: c.message, item: c, pinned: false, star: false, link: `/${domainId}/${topicId}/${m.id}/${c.id}` }); }
                    });
                });
                return {
                    word: word,
                    allData: allData,
                    users: users,
                    viewer: me,
                    domainId: domainId,
                    topicId: topicId,
                }
            },
            sidemenu: route => {
                const isEndInitialLoad = LoadingManager.isEndInitialLoad();
                if( !isEndInitialLoad ) return {}

                normalizeDomainId( store, route.params.domainId );
                const domainId = store.state.domainId;
                normalizeTopicId( store, route.params.topicId );
                const topicId = store.state.topicId;
                const categories = store.getters["categories/get"]( domainId );
                const categoryFilter = store.getters["categories/filter"]( domainId );
                const topicList = store.getters["topics/get"]( domainId ) as Topic[];

                // ユーザー一覧、ログインユーザーの取得
                const users = store.getters["users/getByDomainId"]( domainId ) as User[];
                const me = store.getters["users/me"] as User;
                const isDomainEnable = store.getters[ "domains/isEnable" ](domainId);
                if( !isDomainEnable ) {
                    return {}
                } else {
                    return {
                        domainId,
                        categories,
                        topicList,
                        topicId,
                        users,
                        me,
                        categoryFilter,
                    }
                }
            },
        }
    },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

router.beforeEach( (to: Route, from: Route, next: NavigationGuardNext) => {
    if (to.name == "topic-list" || to.name == "message-list" ) {
        // 検索ワードをセット（NOTE: 初期データ未ロード時はロード後にセットされる）
        const word = store.getters["searchParam"].word;
        const searchQuery = to.query.search as string || "";
        if (word != searchQuery && LoadingManager.isEndInitialLoad()) {
            const searchParam: ISearchParam = { word: searchQuery };
            router.app.$emit("on-set-search-param", searchParam );
        }
    }
    next();
})

router.afterEach( (to: Route, from: Route ) => {
    HistoryUtility.afterEach( from );
})

export default router
