import { API } from "aws-amplify";
import {
    CreateIconReactionMutation, CreateIconReactionMutationVariables,
    GetIconReactionQuery, GetIconReactionQueryVariables,
    IconReaction, IconReactionType,
    ListIconReactionByDomainIdQuery, ListIconReactionByDomainIdQueryVariables,
    ListIconReactionByTopicIdQuery, ListIconReactionByTopicIdQueryVariables,
    ListIconReactionsQuery, ListIconReactionsQueryVariables,
    OnCreateIconReactionSubscription, OnCreateIconReactionSubscriptionVariables,
    OnDeleteIconReactionSubscription, OnDeleteIconReactionSubscriptionVariables,
    OnUpdateIconReactionSubscription, OnUpdateIconReactionSubscriptionVariables,
    ReactionTargetType,
    UpdateIconReactionMutation, UpdateIconReactionMutationVariables
} from "@/API";
import { createIconReaction, updateIconReaction } from "@/graphql/mutations";
import { Comment, Message, Topic } from "@/model";
import { graphqlOperation, GraphQLResult } from "@aws-amplify/api-graphql";
import { getIconReaction, ListIconReactionByDomainId as listIconReactionByDomainId, listIconReactions, ListIconReactionByTopicId as listIconReactionByTopicId } from "@/graphql/queries";
import Observable from "zen-observable-ts";
import { ZenObservable } from "zen-observable-ts";
import { AWSAppSyncRealTimeProvider } from "@aws-amplify/pubsub"
import { onCreateIconReaction, onDeleteIconReaction, onUpdateIconReaction } from "@/graphql/subscriptions";
import { ServerApiAccess } from "@/server-api-access";
import { isSpecialPath } from "../direct-app-config";

type SubscriptionResult<T> = Observable<{
    provider: AWSAppSyncRealTimeProvider,
    value: GraphQLResult<T>
}>

export type ReactionReadResult = {
    reactions: IconReaction[],
    nextToken?: string|null|undefined,
}

export default class ReactionDao {
    /** domain毎のSubscription */
    private static subscriptions: {
        [ domainId: string ]: ZenObservable.Subscription[]
    } = {}

    private static keySettings( target: Topic|Message|Comment ) {
        if( target instanceof Topic ) {
            return {
                targetType: ReactionTargetType.TOPIC,
                domainId: target.domainId,
                topicId: target.id,
                messageId: "",
                commentId: "",
            }
        } else if( target instanceof Message ) {
            return {
                targetType: ReactionTargetType.MESSAGE,
                domainId: target.domainId,
                topicId: target.topicId,
                messageId: target.id,
                commentId: "",
            }
        } else {
            return {
                targetType: ReactionTargetType.COMMENT,
                domainId: target.domainId,
                topicId: target.topicId,
                messageId: target.messageId,
                commentId: target.id,
            }
        }
    }

    /**
     * 作成する
     * @param userId ユーザーID(direct user id)
     * @param target 作成種別
     * @returns
     */
    public static async create( userId: string, target: Topic|Message|Comment, type: IconReactionType ): Promise<IconReaction|undefined> {
        const keys = this.keySettings( target );

        const data: CreateIconReactionMutationVariables = {
            input: {
                type: type,
                ...keys,
                userIdList: [ userId ],
            }
        }
        ServerApiAccess.logging( "reaction.create", data.input );
        const op = graphqlOperation( createIconReaction, data );
        try {
            const result = await ( API.graphql( op ) as Promise< GraphQLResult<CreateIconReactionMutation> > );
            if( result.data?.createIconReaction ) {
                return result.data.createIconReaction;
            }
        } catch( error ) {
            console.error("IconReaction Create Error :%O", error );
            return undefined;
        }
    }

    /**
     * リアクション一覧を取得します
     * @param type      取得するリアクションタイプ
     * @param target    取得対象
     * @returns
     */
    public static async read( type: IconReactionType, target: Topic|Message|Comment, next: string|null|undefined ): Promise<ReactionReadResult> {
        const keys = this.keySettings( target );
        const data: ListIconReactionsQueryVariables = {
            type: type,
            targetTypeDomainIdTopicIdMessageIdCommentId: { eq: keys },
            nextToken: next,
        };
        ServerApiAccess.logging( "reaction.read", data );
        const op = graphqlOperation( listIconReactions, data );
        try {
            const result = await ( API.graphql( op ) as Promise< GraphQLResult<ListIconReactionsQuery> > );
            if( result.data?.listIconReactions?.items ) {
                const reactions = result.data.listIconReactions.items.filter( item => !!item ) as IconReaction[];
                return {
                    reactions: reactions,
                    nextToken: result.data.listIconReactions.nextToken,
                }
            } else {
                return { reactions: [] };
            }
        } catch( error ) {
            console.error("IconReactions Read Error :%O", error );
            return { reactions: [] };
        }
    }

    /**
     * 組織ID指定して、話題一覧用のリアクション一覧を取得します
     * @param type
     * @param domainId
     */
    public static async readByDomainId( type: IconReactionType, domainId: string, next: string|null|undefined ): Promise<ReactionReadResult> {
        const data: ListIconReactionByDomainIdQueryVariables = {
            type: type,
            targetTypeDomainId: {
                eq: {
                    targetType: ReactionTargetType.TOPIC,
                    domainId: domainId,
                }
            },
            nextToken: next,
        }
        ServerApiAccess.logging( "reaction.readByDomainId", data );
        const op = graphqlOperation( listIconReactionByDomainId, data );
        try {
            const result = await ( API.graphql( op ) as Promise< GraphQLResult< ListIconReactionByDomainIdQuery > >);
            if( result.data?.ListIconReactionByDomainId?.items ) {
                const reactions = result.data.ListIconReactionByDomainId.items.filter( item => !!item ) as IconReaction[];
                return {
                    reactions: reactions,
                    nextToken: result.data.ListIconReactionByDomainId.nextToken,
                }
            } else {
                return { reactions: [] };
            }
        } catch( error ) {
            console.error("IconReaction Read Error :%O %O", error, data );
            return { reactions: [] };
        }
    }

    /**
     * TopicID指定して、投稿一覧用のリアクション一覧を取得します
     * @param type
     * @param domainId
     * @param targetType 取得種別。Message or Comment
     */
    public static async readByTopicId( type: IconReactionType, domainId: string, topicId: string, targetType: ReactionTargetType, next: string|null|undefined ): Promise<ReactionReadResult> {
        const data: ListIconReactionByTopicIdQueryVariables = {
            type: type,
            targetTypeDomainIdTopicId: {
                beginsWith: {
                    targetType: targetType,
                    domainId: domainId,
                    topicId: topicId,
                }
            },
            nextToken: next,
        }
        ServerApiAccess.logging( "reaction.readByTopicId", data );
        const op = graphqlOperation( listIconReactionByTopicId, data );
        try {
            const result = await ( API.graphql( op ) as Promise< GraphQLResult< ListIconReactionByTopicIdQuery > >);
            if( result.data?.ListIconReactionByTopicId?.items ) {
                const reactions = result.data.ListIconReactionByTopicId.items.filter( item => !!item ) as IconReaction[];
                return {
                    reactions: reactions,
                    nextToken: result.data.ListIconReactionByTopicId.nextToken,
                }
            } else {
                return { reactions: [] };
            }
        } catch( error ) {
            console.error("IconReaction Read Error :%O %O", error, data );
            return { reactions: [] };
        }
    }


    /** リアクションを取得する */
    public static async get( type: IconReactionType, target: Topic|Message|Comment ): Promise<IconReaction|undefined> {
        const keys = this.keySettings( target );
        const data: GetIconReactionQueryVariables = {
            type: type,
            ...keys
        }
        ServerApiAccess.logging( "reaction.get", data );
        const op = graphqlOperation( getIconReaction, data );
        try {
            const result = await ( API.graphql( op ) as Promise< GraphQLResult< GetIconReactionQuery > > );
            if( result.data?.getIconReaction ) {
                return result.data.getIconReaction;
            }
            return undefined;
        } catch( error ) {
            console.error( "IconReactions Get Error :%O", error );
            return undefined;
        }
    }

    /** リアクションを更新します */
    public static async update( reaction: IconReaction ): Promise<IconReaction|undefined> {
        const data: UpdateIconReactionMutationVariables = {
            input: {
                type: reaction.type,
                targetType: reaction.targetType,
                domainId: reaction.domainId,
                topicId: reaction.topicId,
                messageId: reaction.messageId,
                commentId: reaction.commentId,
                userIdList: reaction.userIdList,
            }
        }
        ServerApiAccess.logging( "reaction.update", data.input );
        const op = graphqlOperation( updateIconReaction, data );
        try {
            const result = await( API.graphql( op ) as Promise< GraphQLResult<UpdateIconReactionMutation> > );
            if( result.data?.updateIconReaction ) {
                return result.data.updateIconReaction;
            } else {
                return undefined;
            }
        } catch( error ) {
            console.error("IconReaction Update Error :%O", error );
            return undefined;
        }
    }

    private static prepareSubscription( domainId: string ) {
        const tmp = this.subscriptions[ domainId ];
        if( tmp == undefined ) this.subscriptions[ domainId ] = [];
    }

    public static async registerSubscriptions( domainId: string, callback: SubscriptionCallback ): Promise<void> {
        this.prepareSubscription( domainId );

        const subscriptions = this.subscriptions[ domainId ];
        if( 0 < subscriptions.length ) return;  // 登録済み

        if( isSpecialPath( domainId ) ) return;

        console.log("☆REGISTER REACTION SUB:", domainId )

        // create
        {
            const input: OnCreateIconReactionSubscriptionVariables = {
                domainId: domainId,
            }
            const graphql = await API.graphql(
                graphqlOperation( onCreateIconReaction, input )
            ) as SubscriptionResult<OnCreateIconReactionSubscription>;

            const subscription = graphql.subscribe( {
                next:( { value } ) => {
                    const data = value.data;
                    if( data?.onCreateIconReaction ) {
                        const reaction = data.onCreateIconReaction;
                        callback.onCreate( reaction );
                    }
                },
                error: error => {
                    console.error( "IconReacton onCreate Subscription Error:%O", error );
                },
                complete: () => {
                    // unsubscribeした時
                }
            } )
            subscriptions.push( subscription );
        }

        // update
        {
            const input: OnUpdateIconReactionSubscriptionVariables = {
                domainId: domainId,
            }
            const graphql = await API.graphql(
                graphqlOperation( onUpdateIconReaction, input )
            ) as SubscriptionResult<OnUpdateIconReactionSubscription>;
            const subscription = graphql.subscribe( {
                next:( { value } ) => {
                    const data = value.data;
                    if( data?.onUpdateIconReaction ) {
                        const reaction = data.onUpdateIconReaction;
                        callback.onUpdate( reaction );
                    }
                },
                error: error => {
                    console.error( "IconReacton onUpate Subscription Error:%O", error );
                },
                complete: () => {
                    // unsubscribeした時
                }
            })
            subscriptions.push( subscription );
        }

        // delete
        {
            const input: OnDeleteIconReactionSubscriptionVariables = {
                domainId: domainId,
            }
            const graphql = await API.graphql(
                graphqlOperation( onDeleteIconReaction, input )
            ) as SubscriptionResult<OnDeleteIconReactionSubscription>;
            const subscription = graphql.subscribe( {
                next:( { value } ) => {
                    const data = value.data;
                    if( data?.onDeleteIconReaction ) {
                        const reaction = data.onDeleteIconReaction;
                        callback.onUpdate( reaction );
                    }
                },
                error: error => {
                    console.error( "IconReacton onDelete Subscription Error:%O", error );
                },
                complete: () => {
                    // unsubscribeした時
                }
            })
            subscriptions.push( subscription );
        }

    }

    /** サブスクリプションを閉じる */
    public static closeSubscription( domainId: string ) {
        const tmp = this.subscriptions[ domainId ] || [];
        tmp.forEach( tmp => tmp.unsubscribe() );
        this.subscriptions[ domainId ] = [];
    }
}

export type SubscriptionCallback = {
    onCreate: ( reaction: IconReaction ) => void,
    onUpdate: ( reaction: IconReaction ) => void,
    onDelete: ( reaction: IconReaction ) => void,
}
