import { IconReaction, IconReactionType, ReactionTargetType } from "@/API";
import { Topic, Message, Comment } from "@/model";
import Vue from "vue";
import { ActionContext, Module } from "vuex";
import { State } from "vuex-class";
import ReactionDao from "./reaction-dao";
import type { ReactionReadResult } from "./reaction-dao";

type State = { reactions: Record<string, IconReaction|undefined>, }
type RootState = { domainId: string, topicId: string, messageId: string };
type ReactionActionContext = ActionContext< State, RootState >;

type PayloadFetch<T> = { data: { target: T, type: IconReactionType } };
export type PayloadFetchTopicList = { domainId: string, type: IconReactionType };
export type PayloadFetchMessageList = { domainId: string, topicId: string, type: IconReactionType };
type PayloadSet = { data: { type: IconReactionType, reaction: IconReaction } };
type PayloadSetAll = { data: { type: IconReactionType, reactions: IconReaction[] } };
export type PayloadUpdateReaction = { type: IconReactionType, target: Topic|Message|Comment, userDirectId: string, value: boolean };

export type ListTopics     = { domainId: string, topicId?: undefined, messageId?: undefined, commentId?: undefined, };
export type ListMessages   = { domainId: string, topicId: string,     messageId?: undefined, commentId?: undefined, };
export type ListComments   = { domainId: string, topicId: string,     messageId: string,     commentId?: undefined, };
export type ListTarget = ListTopics | ListMessages | ListComments;

export type FindByTopic     = { domainId: string, topicId: string, messageId?: never, commentId?: never, };
export type FindByMessage   = { domainId: string, topicId: string, messageId: string, commentId?: never, };
export type FindByComment   = { domainId: string, topicId: string, messageId: string, commentId: string };
export type FindReaction     = FindByTopic | FindByMessage | FindByComment;

export type ReactionGetter = ( type: IconReactionType, target: FindReaction ) => IconReaction | undefined;
export type ReactionHasUser = ( type: IconReactionType, target: FindReaction, userId: string ) => boolean;

// storeに保存するときのキー形式
function createKeyForSet( type: IconReactionType, target: IconReaction ): string {
    switch( target.targetType ) {
        case ReactionTargetType.TOPIC:  return `${type}#${target.targetType}#${target.domainId}#${target.topicId}`;
        case ReactionTargetType.MESSAGE:return `${type}#${target.targetType}#${target.domainId}#${target.topicId}#${target.messageId}`;
        case ReactionTargetType.COMMENT:
        default:                        return `${type}#${target.targetType}#${target.domainId}#${target.topicId}#${target.messageId}#${target.commentId}`;
    }
}

// TargetTypeを取得
function getListTargetType( target: ListTarget ): ReactionTargetType {
    if( target.topicId == undefined )   return ReactionTargetType.TOPIC;
    if( target.messageId == undefined ) return ReactionTargetType.MESSAGE;
    return ReactionTargetType.COMMENT;
}

// TargetTypeを取得
function getFindTargetType( target: FindReaction ): ReactionTargetType {
    if( target.messageId == undefined ) return ReactionTargetType.TOPIC;
    if( target.commentId == undefined ) return ReactionTargetType.MESSAGE;
    return ReactionTargetType.COMMENT;
}

// store から読み出す時のキーを作成
// @param target 話題一覧、投稿一覧、コメント 一覧の指定
function createListKey( type: IconReactionType, target: ListTarget, targetType?: ReactionTargetType ): string {
    targetType = targetType ? targetType : getListTargetType( target );

    // 話題一覧、投稿一覧、コメント一覧 用のキーを作成
    switch( targetType ) {
        case ReactionTargetType.TOPIC:  return `${type}#${targetType}#${target.domainId}`;
        case ReactionTargetType.MESSAGE:return `${type}#${targetType}#${target.domainId}#${target.topicId}`;
        case ReactionTargetType.COMMENT:return `${type}#${targetType}#${target.domainId}#${target.topicId}#${target.messageId}`;
        default:                        return `${type}#${targetType}#${target.domainId}#${target.topicId}#${target.messageId}#${target.commentId}`;
    }
}

function createFindKey( type: IconReactionType, target: FindReaction ): string {
    const targetType = getFindTargetType( target );
    switch( targetType ) {
        case ReactionTargetType.TOPIC:  return `${type}#${targetType}#${target.domainId}#${target.topicId}`;
        case ReactionTargetType.MESSAGE:return `${type}#${targetType}#${target.domainId}#${target.topicId}#${target.messageId}`;
        case ReactionTargetType.COMMENT:return `${type}#${targetType}#${target.domainId}#${target.topicId}#${target.messageId}#${target.commentId}`;
        default:                        return ``;
    }
}

// 条件に合うリアクション一覧を取得
function listReactions( state: State, type: IconReactionType, target: ListTarget, targetType?: ReactionTargetType ): IconReaction[] {
    const key = createListKey( type, target, targetType );
    const keys = Object.keys( state.reactions ).filter( id => id.startsWith( key ) );
    return keys.map( k => state.reactions[ k ] ).filter( reaction => !!reaction ) as IconReaction[];
}


const reactionModule: Module< State, RootState > = {
    namespaced: true,
    state: {
        reactions: {}
    },
    getters: {
        /**
         * IDを指定してReaction設定を取得
        */
        get: ( state: State ): ReactionGetter => ( type:IconReactionType, target: FindReaction ): IconReaction|undefined => {
            const key = createFindKey( type, target );
            return state.reactions[ key ];
        },

        /**
         * 一覧用のリアクション一覧を取得
         * @param type          リアクション種別
         * @param container     一覧種別のコンテナタイプ(話題一覧の場合はDomain、投稿一覧の場合はTopicを指定)
         * @param targetType    表示するもの。Topic一覧がほしいならTOPIC
        */
        listBy: ( state: State ) => ( type: IconReactionType, target: ListTarget, targetType: ReactionTargetType ): Record<string, IconReaction> => {
            if( !target ) return {};
            const reactions = listReactions( state, type, target, targetType );
            const obj: Record<string, IconReaction> = {};
            return reactions.reduce( ( prev, reaction ) => {
                switch( reaction.targetType ) {
                    case ReactionTargetType.TOPIC: prev[ reaction.topicId ] = reaction; break;
                    case ReactionTargetType.MESSAGE: prev[ reaction.messageId ] = reaction; break;
                    case ReactionTargetType.COMMENT: prev[ reaction.commentId ] = reaction; break;
                }
                return prev;
            }, obj )
        },

        /** userIdが指定タイプのリアクションを持つかどうか */
        hasReaction: ( state: State ) => ( type: IconReactionType, target: FindReaction, userId: string ): boolean => {
            const key = createFindKey( type, target );
            const reaction = state.reactions[ key ];
            return reaction ? reaction.userIdList.includes( userId ) : false;
        },
    },
    mutations: {
        set( state: State, payload: PayloadSet ) {
            const key = createKeyForSet( payload.data.type, payload.data.reaction );
            const reaction = payload.data.reaction;
            Vue.set( state.reactions, key, reaction );
        },
        setAll( state: State, payload: PayloadSetAll ) {
            const reactions = payload.data.reactions;
            for( const reaction of reactions ) {
                const key = createKeyForSet( payload.data.type, reaction );
                Vue.set( state.reactions, key, reaction );
            }
        },
    },
    actions: {
        /** 組織の対話一覧で利用するリアクションを取得する */
        async fetchDomainTopics( {commit}: ReactionActionContext, { domainId, type }: PayloadFetchTopicList ): Promise<void> {
            let next = undefined;
            do {
                const readResult: ReactionReadResult = await ReactionDao.readByDomainId( type, domainId, next ); // ★一覧を取得
                const input: PayloadSetAll = { data: { type: type, reactions: readResult.reactions } };
                if( input.data.reactions ) commit( "setAll", input );
                next = readResult.nextToken;
            } while( Boolean( next ) == true );
        },

        /** 投稿一覧で利用するリアクションを取得する */
        async fetchMessageListReactions( {commit}: ReactionActionContext, { domainId, topicId, type }: PayloadFetchMessageList ): Promise<void> {
            let next = undefined;
            do {
                const readResult: ReactionReadResult = await ReactionDao.readByTopicId( type, domainId, topicId, ReactionTargetType.MESSAGE, next ); // ★一覧を取得
                const input: PayloadSetAll = { data: { type: type, reactions: readResult.reactions } };
                if( input.data.reactions ) commit( "setAll", input );
                next = readResult.nextToken;
            } while( Boolean( next ) == true );
        },

        /**
         * 投稿一覧で利用するコメントのリアクションを取得する
         * @param domainId 組織ID
         * @param topicId 話題ID
         * @param type リアクション種別
         */
        async fetchCommentReactions( { commit }: ReactionActionContext, { domainId, topicId, type }: PayloadFetchMessageList ): Promise<void> {
            let next = undefined;
            do {
                const readResult: ReactionReadResult = await ReactionDao.readByTopicId( type, domainId, topicId, ReactionTargetType.COMMENT, next ); // ★一覧を取得
                const input: PayloadSetAll = { data: { type: type, reactions: readResult.reactions } };
                if( input.data.reactions ) commit( "setAll", input );
                next = readResult.nextToken;
            } while( Boolean( next ) == true );
        },

        /** リアクション一覧を取得 */
        async fetch<T extends Topic|Message|Comment>( { commit }: ReactionActionContext, payload: PayloadFetch<T> ): Promise<void> {
            const type = payload.data.type;
            const target = payload.data.target;
            if( !target ) return;

            let next = undefined;
            do {
                const readResult: ReactionReadResult = await ReactionDao.read( type, target, next ); // ★一覧を取得
                const input: PayloadSetAll = { data: { type: type, reactions: readResult.reactions } };
                if( input.data.reactions ) commit( "setAll", input );
                next = readResult.nextToken;
            } while( Boolean( next ) == true );
        },

        /** Reaction設定を行う */
        async update( { commit }: ReactionActionContext, { type, userDirectId, target ,value }: PayloadUpdateReaction ) {
            const userId = userDirectId;
            const reaction = await ReactionDao.get( type, target );
            if( reaction ) {    // 更新処理
                const index = reaction.userIdList.findIndex( id => id == userId );
                if( value ) {
                    if( index < 0 ) reaction.userIdList.push( userId );
                } else {
                    if( 0 <= index ) reaction.userIdList.splice( index, 1 );
                }

                const result = await ReactionDao.update( reaction );
                const input: PayloadSet = { data: { type: type, reaction: result || reaction  } };
                commit( 'set', input );
            } else {            // 新規作成処理
                const result = await ReactionDao.create( userId, target, type )
                if( result ) {
                    const input: PayloadSet = { data: { type: type, reaction: result  } };
                    commit( 'set', input );
                } else {
                    // reaction 及び result が undefined
                    // 全失敗
                }
            }
        },

        async registerSubscription( {commit}: ReactionActionContext, payload:{ domainId: string } ) {
            const domainId = payload.domainId;
            try {
                await ReactionDao.registerSubscriptions( domainId, {
                    onCreate: ( reaction: IconReaction ) => {
                        const input: PayloadSet = { data: { type: reaction.type, reaction: reaction } }
                        commit( 'set', input );
                    },
                    onUpdate: ( reaction: IconReaction ) => {
                        const input: PayloadSet = { data: { type: reaction.type, reaction: reaction } }
                        commit( 'set', input );
                    },
                    onDelete: ( reaction: IconReaction ) => {
                        // 何もしない
                    },
                } );
            } catch( error ) {
                console.error( error );
            }
        },

        async removeSubscription( { commit }: ReactionActionContext, payload: { domainId: string } ): Promise<void> {
            const domainId = payload.domainId;
            await ReactionDao.closeSubscription( domainId );
        }
    },
}

export default reactionModule;
