























































































import AppAuth from "../aws-config";
import { PRODUCT_STAGE } from "../aws-config";
import type { PROVIDER } from "../aws-config";
import Loading from "../components/loading/Loading.vue"
import { isMobile, osType, OS_TYPE } from "../mobile";

import { Component, Vue } from "vue-property-decorator";
import { LoadingManager } from "../components/loading/loading-manager";
import sentry from "@/sentry";

declare global {
    // iOS向け関数 https://lisb.myjetbrains.com/youtrack/issue/dxflow-13
    export function DAJSHGetDomainId(): void;
    export interface Window {
        webkit: {
            messageHandlers: {
                DANHAuthenticateWebApp: {
                    postMessage: ( authUrl: string ) => void;
                },
                DANHCloseWebView: {
                    postMessage: ( dummy: string ) => void;
                },
            },
        },
        directJsApi: {
            signin: ( authUrl: string ) => void;
            setCurrentDomainId: ( domainId: string ) => void;
            closeWindow: () => void;
            hideToolbar: () => void;
        }
    }
}

enum LoadingMngKey {
    LOGIN_COGNITO = "loginCognito",
    LOGIN_MOBILE_REDIRECT = "loginMobileRedirect",
}

// Mobileログインのリトライ回数
const RETRY_COUNT = 5;

/**
 * iOSにログインする
 * @param next directの singin URL
 * @return false direct iOS のログインハンドラーが無く、signin 実行できなかった場合
 */
function signinIOS( next: string ): boolean {
    const iosHandlers = window.webkit ? window.webkit.messageHandlers : undefined;
    const authHandler = iosHandlers ? iosHandlers.DANHAuthenticateWebApp : undefined;
    const authHandlerMethod = authHandler ? authHandler.postMessage : undefined;

    if( authHandlerMethod && typeof authHandlerMethod == "function" ) {
        try {
            window.webkit.messageHandlers.DANHAuthenticateWebApp.postMessage( next );
            return true;
        } catch( error ) {
            sentry.sendSentryError( error )
            return false;
        }
    } else {
        return false;
    }
}

/**
 * Androidにログインする
 * @param next directの signin URL
 * @return false direct Android のログインハンドラーが無く、signin 実行できなかった場合
 */
function signinAndroid( next: string ): boolean {
    const handler = window.directJsApi ? window.directJsApi.signin : undefined;
    if( handler && typeof handler == "function" ) {
        try {
            window.directJsApi.signin( next );
            return true
        } catch( error ) {
            sentry.sendSentryError( error )
            return false;
        }
    } else {
        return false
    }
}

// モバイル用ログイン処理を行う
function signinMobile( os: string, next: string ): boolean {
    if( os == "ios" && signinIOS( next ) ) {
        return true
    } else if ( os == "and" && signinAndroid( next ) ) {
        return true
    } else {
        // OS指定が無い時もハンドラーがあれば挑戦する
        if( signinIOS( next ) ) {
            return true
        } else if( signinAndroid( next ) ) {
            return true
        }
    }
    return false    // モバイル向けログイン失敗
}

/**
 * urlがURL文字列かどうかを判定する.
 * @param url URL文字列
 * @return true:URL文字列 false:URL文字列ではない何か
 */
function isValidURL(url: unknown): boolean {
    if( typeof url != "string" ) return false;  // 文字列型では無い時点で URL 文字列とは見なさない
    try {
        const urlObj = new URL(url);            // URL文字列では無い場合ここで例外が出る
        return urlObj.protocol === "http:" || urlObj.protocol === "https:";
    } catch (error) {
        return false;
    }
}

@Component({ components: { Loading } })
export default class Login extends Vue {
    public readonly name = "login";

    status = "";
    message_text = "";
    loginContinue: boolean = false;
    loginMobileRedirect: boolean = false;
    stage = PRODUCT_STAGE;
    useLoadingView = true;  // true: ログイン画面を出さず、ロード中画面にする
    count = 0; // リトライのカウント数

    get loginRestrictDesc(): string {
        return (this.$route.query?.desc || "") as string;
    }

    created(): void {
        this.loginContinue = this.$route.path.indexOf("login-continue") != -1;
        this.loginMobileRedirect = this.$route.path.indexOf("login-mobile-redirect") != -1;
    }

    async mounted(): Promise<void> {
        try {
            const msg = 'ログインしています';
            this.sendLoadingManager( LoadingMngKey.LOGIN_COGNITO, msg );
            await AppAuth.authenticatedUser();
            this.status = msg
        } catch( err ) {
            if( this.loginContinue ) {
                this.status = 'ログイン中です。しばらくお待ちください'
                this.sendLoadingManager( LoadingMngKey.LOGIN_COGNITO, this.status );
                let provider: PROVIDER;
                switch( this.$route.path ) {
                    case "/login-continue":     provider = "direct"; break;
                    case "/login-continue-stg": provider = "direct-staging"; break;
                    case "/login-continue-dev": provider = "direct-dev"; break;
                    default:                    provider = "direct"; break;
                }
                try {
                    await AppAuth.signIn( provider );
                } finally {
                    this.stopLoadingManager( LoadingMngKey.LOGIN_COGNITO );
                }
            } else if( this.loginMobileRedirect ) {
                // 次はMobileAPIを叩く
                try {
                    // const msg = "ログイン中です" + os + "next:" + next +"query" + this.$route.query
                    const msg = "ログイン中です"
                    console.log(`★ログイン(mobile)：${msg}`)
                    console.log(`★ログイン(route.query)：${JSON.stringify(this.$route.query)}`)
                    this.sendLoadingManager( LoadingMngKey.LOGIN_MOBILE_REDIRECT, msg );
                    this.status = msg;
                    this.loginMobile();
                } catch( error ) {
                    sentry.sendSentryError({
                        type: `Mobile Login Error : ${JSON.stringify(this.$route.query)}`,
                        error: error,
                    })
                } finally {
                    this.stopLoadingManager( LoadingMngKey.LOGIN_MOBILE_REDIRECT );
                }
            } else {
                // Cognito側の認証エラー

                const msg = 'ログイン中';
                this.sendLoadingManager( LoadingMngKey.LOGIN_COGNITO, msg );

                // mobile判定を$routeからすると失敗するパターンがあるため vuex 経由
                // ＊認可callback後など
                const device = this.$store.getters["device"];
                if( isMobile( device ) ) { // スマホは別処理
                    const type = osType( device );
                    await this.signIn( type );
                } else {                        // Web版
                    let provider: PROVIDER | "" = "";
                    switch( this.stage ) {
                        case "PRD": provider = "direct"; break;
                        case "STG": provider = "direct-staging"; break;
                        case "DEV": provider = "direct-dev"; break;
                    }
                    if( provider ) {
                        await AppAuth.signIn( provider );
                        this.status = msg;
                    }
                }
            }
        } finally {
            this.count = 0;
            this.stopLoadingManager( LoadingMngKey.LOGIN_COGNITO );
        }
    }

    // MobileAPIの処理
    private loginMobile(): void {
        const next = this.$route.query.next as string;
        if( isValidURL(next) == false ) {
            // 取得したモバイル向けOIDCログインURLが不正。
            // ここで再度モバイル向けOIDCログインURL取得処理に戻らせる。sentryにも通知する
            const error = new Error( `Mobile Login URL ERROR query:${JSON.stringify(this.$route.query)}` )
            sentry.sendSentryError( error );

            // モバイルのログインURL取得からやり直し
            const device = this.$store.getters["device"];
            const os = osType( device );
            const type = os == "ios" ? "ios" : "and";
            const url = AppAuth.getOidcLoginUrl( "direct-mobile", type );
            window.location.href = url;
            return;
        }

        const os = this.$route.query.os as string;
        if( signinMobile( os, next ) == false ) {
            // direct iOS/Android の初期化状態によってはハンドラが掴めない場合があるようなので、数秒後に再実行
            this.count++;
            if( this.count < RETRY_COUNT ) {
                // 1秒後にリトライ
                setTimeout( this.loginMobile, 1000 );
            } else {
                const next = this.$route.query.next as string;
                const os = this.$route.query.os as string;
                const msg = "ログイン処理に失敗しました:" + os + "next:" + next
                const error = new Error( `Mobile Login URL ERROR(RETRY OVER) query:${JSON.stringify(this.$route.query)}` );
                sentry.sendSentryError( error )
                this.sendLoadingManager( LoadingMngKey.LOGIN_MOBILE_REDIRECT, msg );
                this.status = msg;
            }
        }
    }

    async signIn( provider: PROVIDER | OS_TYPE ): Promise<void> {
        try {
            switch( provider ) {
                case "ios":     await AppAuth.signIn( "direct-mobile", "ios" ); break;
                case "android": await AppAuth.signIn( "direct-mobile", "and" ); break;
                default:        await AppAuth.signIn( provider as PROVIDER ); break;
            }
            this.status = 'ログイン中です';
        } catch( err ) {
            sentry.sendSentryError( err )
        }
    }

    async signOut(): Promise<void> {
        await AppAuth.signOut();
    }

    private sendLoadingManager( category: LoadingMngKey, msg: string ) {
        if( !this.useLoadingView ) LoadingManager.start( category, msg );
    }

    private stopLoadingManager( category: LoadingMngKey ) {
        if( !this.useLoadingView ) LoadingManager.stop( category );
    }
}
