/**
 * Flowへ投稿するためのクラス
 */

import axios from "axios";

// true: 配信サーバーを利用する
const FLOW_USE_FLOWSERVER = ( process.env.VUE_APP_FLOW_USE_FLOWSERVER || "true" ) === "true";

export type FlowPostParam = {
    domain_id: string,      //!< 投稿する direct 組織ID
    user_id: string[],      //!< 配布先のユーザーの direct user id
    url: string,            //!< ${ドメイン}/${組織ID}/${話題ID}[/${投稿ID}[/${コメントID}]]
    contents: string,       //!< フローに表示するテキスト
    registrant_id: string,  //!< 登録者(自分)の direct user id
    guest_flag: 0 | 1,      //!< 0: ゲストに配信する 1: ゲストには配信しない (default:0)
    attachment: {
        name: string,       //!< 添付ファイル名
        path: string,       //!< 添付ファイルパス。/flowlist/image/put の返り値を指定
    }[],                    //!< 添付ファイル情報。無ければ空配列を指定
    push_title?: string,            //!< プッシュ通知タイトル（未指定の場合、プッシュ通知はされない）
    push_description?: string,      //!< プッシュ通知本文（未指定の場合、プッシュ通知はされない）
    // push_to: string[],           //!< プッシュ通知先の direct user id（未指定の場合、user_id が利用される）
    // push_scheduled_time: string  //!< プッシュ通知時刻 unixtime（未指定の場合、即時通知）
}

export class FlowUtility {

    /**
     * フローに投稿する
     * @param params        フロー投稿パラメータ。@see FlowPostParam
     * @param api           フロー投稿API
     */
    public static post( params: FlowPostParam, api: string ): void {
        if( !FLOW_USE_FLOWSERVER ) return;

        try {
            console.log("send flow:%O", params );
            axios.post(
                api, { ...params }, { withCredentials: true }
            ).catch( err => {
                console.error( err );
            });
        } catch( err ) {
            console.error( err );
        }
    }
}
