/* tslint:disable */
/* eslint-disable */
//  This file was automatically generated and should not be edited.

export type CreateTopicInput = {
  id?: string | null,
  domainId: string,
  title: string,
  desc: string,
  icon?: AttachmentInput | null,
  pinned?: boolean | null,
  categoryId: string,
  messages?: number | null,
  owner?: string | null,
  updatedAt?: string | null,
  createdAt?: string | null,
  deleted?: boolean | null,
  deletedUser?: string | null,
  acl?: AclInput | null,
  notification?: string | null,
};

export type AttachmentInput = {
  url: string,
  mime: string,
};

export type AclInput = {
  id?: string | null,
  refId: string,
  refIdType: RefidType,
  base: AclBaseItemInput,
  guest: AclItemInput,
  items?: Array< AclItemInput > | null,
};

export enum RefidType {
  TOPIC_ID = "TOPIC_ID",
  MESSAGE_ID = "MESSAGE_ID",
  COMMENT_ID = "COMMENT_ID",
  USER_ID = "USER_ID",
  DEPARTMENT_ID = "DEPARTMENT_ID",
  ROLE_GROUP_ID = "ROLE_GROUP_ID",
  DUMMY_ID = "DUMMY_ID",
}


export type AclBaseItemInput = {
  create?: boolean | null,
  read?: boolean | null,
  update?: boolean | null,
  delete?: boolean | null,
  createTopic?: string | null,
  createMessage?: string | null,
  createComment?: string | null,
  createReactions?: string | null,
};

export type AclItemInput = {
  id?: string | null,
  refId: string,
  refIdType: RefidType,
  create?: boolean | null,
  read?: boolean | null,
  update?: boolean | null,
  delete?: boolean | null,
  createTopic?: string | null,
  createMessage?: string | null,
  createComment?: string | null,
  createReactions?: string | null,
};

export type ModelTopicConditionInput = {
  title?: ModelStringInput | null,
  desc?: ModelStringInput | null,
  pinned?: ModelBooleanInput | null,
  categoryId?: ModelIDInput | null,
  messages?: ModelIntInput | null,
  updatedAt?: ModelStringInput | null,
  createdAt?: ModelStringInput | null,
  deleted?: ModelBooleanInput | null,
  deletedUser?: ModelStringInput | null,
  notification?: ModelIDInput | null,
  and?: Array< ModelTopicConditionInput | null > | null,
  or?: Array< ModelTopicConditionInput | null > | null,
  not?: ModelTopicConditionInput | null,
};

export type ModelStringInput = {
  ne?: string | null,
  eq?: string | null,
  le?: string | null,
  lt?: string | null,
  ge?: string | null,
  gt?: string | null,
  contains?: string | null,
  notContains?: string | null,
  between?: Array< string | null > | null,
  beginsWith?: string | null,
  attributeExists?: boolean | null,
  attributeType?: ModelAttributeTypes | null,
  size?: ModelSizeInput | null,
};

export enum ModelAttributeTypes {
  binary = "binary",
  binarySet = "binarySet",
  bool = "bool",
  list = "list",
  map = "map",
  number = "number",
  numberSet = "numberSet",
  string = "string",
  stringSet = "stringSet",
  _null = "_null",
}


export type ModelSizeInput = {
  ne?: number | null,
  eq?: number | null,
  le?: number | null,
  lt?: number | null,
  ge?: number | null,
  gt?: number | null,
  between?: Array< number | null > | null,
};

export type ModelBooleanInput = {
  ne?: boolean | null,
  eq?: boolean | null,
  attributeExists?: boolean | null,
  attributeType?: ModelAttributeTypes | null,
};

export type ModelIDInput = {
  ne?: string | null,
  eq?: string | null,
  le?: string | null,
  lt?: string | null,
  ge?: string | null,
  gt?: string | null,
  contains?: string | null,
  notContains?: string | null,
  between?: Array< string | null > | null,
  beginsWith?: string | null,
  attributeExists?: boolean | null,
  attributeType?: ModelAttributeTypes | null,
  size?: ModelSizeInput | null,
};

export type ModelIntInput = {
  ne?: number | null,
  eq?: number | null,
  le?: number | null,
  lt?: number | null,
  ge?: number | null,
  gt?: number | null,
  between?: Array< number | null > | null,
  attributeExists?: boolean | null,
  attributeType?: ModelAttributeTypes | null,
};

export type Topic = {
  __typename: "Topic",
  id: string,
  domainId: string,
  title: string,
  desc: string,
  icon?: Attachment | null,
  pinned?: boolean | null,
  categoryId: string,
  messages?: number | null,
  owner?: string | null,
  updatedAt: string,
  createdAt?: string | null,
  deleted?: boolean | null,
  deletedUser?: string | null,
  acl?: Acl | null,
  notification?: string | null,
};

export type Attachment = {
  __typename: "Attachment",
  url: string,
  mime: string,
};

export type Acl = {
  __typename: "Acl",
  id?: string | null,
  refId: string,
  refIdType: RefidType,
  base: AclBaseItem,
  guest: AclItem,
  items?:  Array<AclItem > | null,
};

export type AclBaseItem = {
  __typename: "AclBaseItem",
  create?: boolean | null,
  read?: boolean | null,
  update?: boolean | null,
  delete?: boolean | null,
  createTopic?: string | null,
  createMessage?: string | null,
  createComment?: string | null,
  createReactions?: string | null,
};

export type AclItem = {
  __typename: "AclItem",
  id?: string | null,
  refId: string,
  refIdType: RefidType,
  create?: boolean | null,
  read?: boolean | null,
  update?: boolean | null,
  delete?: boolean | null,
  createTopic?: string | null,
  createMessage?: string | null,
  createComment?: string | null,
  createReactions?: string | null,
};

export type UpdateTopicInput = {
  id: string,
  domainId: string,
  title?: string | null,
  desc?: string | null,
  icon?: AttachmentInput | null,
  pinned?: boolean | null,
  categoryId?: string | null,
  messages?: number | null,
  owner?: string | null,
  updatedAt?: string | null,
  createdAt?: string | null,
  deleted?: boolean | null,
  deletedUser?: string | null,
  acl?: AclInput | null,
  notification?: string | null,
};

export type DeleteTopicInput = {
  domainId: string,
  id: string,
};

export type CreateMessageInput = {
  id?: string | null,
  domainId: string,
  topicId: string,
  title?: string | null,
  message?: string | null,
  pinned?: boolean | null,
  owner?: string | null,
  commentId: Array< string >,
  photos: Array< AttachmentInput >,
  updatedAt?: string | null,
  createdAt?: string | null,
  deleted?: boolean | null,
  deletedUser?: string | null,
  acl?: AclInput | null,
};

export type ModelMessageConditionInput = {
  title?: ModelStringInput | null,
  message?: ModelStringInput | null,
  pinned?: ModelBooleanInput | null,
  commentId?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  createdAt?: ModelStringInput | null,
  deleted?: ModelBooleanInput | null,
  deletedUser?: ModelStringInput | null,
  and?: Array< ModelMessageConditionInput | null > | null,
  or?: Array< ModelMessageConditionInput | null > | null,
  not?: ModelMessageConditionInput | null,
};

export type Message = {
  __typename: "Message",
  id: string,
  domainId: string,
  topicId: string,
  title?: string | null,
  message?: string | null,
  pinned?: boolean | null,
  owner?: string | null,
  commentId: Array< string >,
  photos:  Array<Attachment >,
  updatedAt: string,
  createdAt: string,
  deleted?: boolean | null,
  deletedUser?: string | null,
  acl?: Acl | null,
};

export type UpdateMessageInput = {
  id: string,
  domainId?: string | null,
  topicId: string,
  title?: string | null,
  message?: string | null,
  pinned?: boolean | null,
  owner?: string | null,
  commentId?: Array< string > | null,
  photos?: Array< AttachmentInput > | null,
  updatedAt?: string | null,
  createdAt?: string | null,
  deleted?: boolean | null,
  deletedUser?: string | null,
  acl?: AclInput | null,
};

export type DeleteMessageInput = {
  topicId: string,
  id: string,
};

export type CreateCommentInput = {
  id?: string | null,
  domainId: string,
  topicId: string,
  messageId: string,
  message: string,
  photos: Array< AttachmentInput >,
  owner?: string | null,
  updatedAt?: string | null,
  createdAt?: string | null,
  deleted?: boolean | null,
  deletedUser?: string | null,
  acl?: AclInput | null,
};

export type ModelCommentConditionInput = {
  topicId?: ModelStringInput | null,
  message?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  createdAt?: ModelStringInput | null,
  deleted?: ModelBooleanInput | null,
  deletedUser?: ModelStringInput | null,
  and?: Array< ModelCommentConditionInput | null > | null,
  or?: Array< ModelCommentConditionInput | null > | null,
  not?: ModelCommentConditionInput | null,
};

export type Comment = {
  __typename: "Comment",
  id: string,
  domainId: string,
  topicId: string,
  messageId: string,
  message: string,
  photos:  Array<Attachment >,
  owner?: string | null,
  updatedAt: string,
  createdAt?: string | null,
  deleted?: boolean | null,
  deletedUser?: string | null,
  acl?: Acl | null,
};

export type UpdateCommentInput = {
  id: string,
  domainId?: string | null,
  topicId?: string | null,
  messageId: string,
  message?: string | null,
  photos?: Array< AttachmentInput > | null,
  owner?: string | null,
  updatedAt?: string | null,
  createdAt?: string | null,
  deleted?: boolean | null,
  deletedUser?: string | null,
  acl?: AclInput | null,
};

export type DeleteCommentInput = {
  messageId: string,
  id: string,
};

export type CreateCategoryInput = {
  id?: string | null,
  title: string,
  domainId: string,
  color?: string | null,
  owner?: string | null,
  updatedAt?: string | null,
  createdAt?: string | null,
  deleted?: boolean | null,
  deletedUser?: string | null,
};

export type ModelCategoryConditionInput = {
  title?: ModelStringInput | null,
  color?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  createdAt?: ModelStringInput | null,
  deleted?: ModelBooleanInput | null,
  deletedUser?: ModelStringInput | null,
  and?: Array< ModelCategoryConditionInput | null > | null,
  or?: Array< ModelCategoryConditionInput | null > | null,
  not?: ModelCategoryConditionInput | null,
};

export type Category = {
  __typename: "Category",
  id: string,
  title: string,
  domainId: string,
  color?: string | null,
  owner?: string | null,
  updatedAt?: string | null,
  createdAt?: string | null,
  deleted?: boolean | null,
  deletedUser?: string | null,
};

export type UpdateCategoryInput = {
  id: string,
  title?: string | null,
  domainId: string,
  color?: string | null,
  owner?: string | null,
  updatedAt?: string | null,
  createdAt?: string | null,
  deleted?: boolean | null,
  deletedUser?: string | null,
};

export type DeleteCategoryInput = {
  domainId: string,
  id: string,
};

export type CreateUserInput = {
  id?: string | null,
  directId: string,
  name: string,
  profileIcon?: string | null,
  domainIdList: Array< string >,
  owner?: string | null,
  favorites?: Array< string | null > | null,
  likes?: Array< string | null > | null,
  updatedAt?: string | null,
  createdAt?: string | null,
};

export type ModelUserConditionInput = {
  name?: ModelStringInput | null,
  profileIcon?: ModelStringInput | null,
  favorites?: ModelStringInput | null,
  likes?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  createdAt?: ModelStringInput | null,
  and?: Array< ModelUserConditionInput | null > | null,
  or?: Array< ModelUserConditionInput | null > | null,
  not?: ModelUserConditionInput | null,
};

export type User = {
  __typename: "User",
  id: string,
  directId: string,
  name: string,
  profileIcon?: string | null,
  domainIdList: Array< string >,
  owner?: string | null,
  favorites?: Array< string | null > | null,
  likes?: Array< string | null > | null,
  updatedAt?: string | null,
  createdAt?: string | null,
};

export type UpdateUserInput = {
  id?: string | null,
  directId: string,
  name?: string | null,
  profileIcon?: string | null,
  domainIdList?: Array< string > | null,
  owner?: string | null,
  favorites?: Array< string | null > | null,
  likes?: Array< string | null > | null,
  updatedAt?: string | null,
  createdAt?: string | null,
};

export type DeleteUserInput = {
  directId: string,
};

export type CreateDomainUserInput = {
  domainId: string,
  userDirectId: string,
};

export type ModelDomainUserConditionInput = {
  and?: Array< ModelDomainUserConditionInput | null > | null,
  or?: Array< ModelDomainUserConditionInput | null > | null,
  not?: ModelDomainUserConditionInput | null,
};

export type DomainUser = {
  __typename: "DomainUser",
  domainId: string,
  userDirectId: string,
  user?: User | null,
  createdAt: string,
  updatedAt: string,
};

export type UpdateDomainUserInput = {
  domainId: string,
  userDirectId: string,
};

export type DeleteDomainUserInput = {
  domainId: string,
  userDirectId: string,
};

export type CreateDepartmentInput = {
  domainId: string,
  department_id: string,
  name: string,
  user_defined_key: string,
  order: number,
  parent?: string | null,
  node?: number | null,
};

export type ModelDepartmentConditionInput = {
  name?: ModelStringInput | null,
  order?: ModelIntInput | null,
  parent?: ModelStringInput | null,
  node?: ModelIntInput | null,
  and?: Array< ModelDepartmentConditionInput | null > | null,
  or?: Array< ModelDepartmentConditionInput | null > | null,
  not?: ModelDepartmentConditionInput | null,
};

export type Department = {
  __typename: "Department",
  domainId: string,
  department_id: string,
  name: string,
  user_defined_key: string,
  order: number,
  parent?: string | null,
  node?: number | null,
  createdAt: string,
  updatedAt: string,
  owner?: string | null,
};

export type UpdateDepartmentInput = {
  domainId: string,
  department_id: string,
  name?: string | null,
  user_defined_key: string,
  order?: number | null,
  parent?: string | null,
  node?: number | null,
};

export type DeleteDepartmentInput = {
  domainId: string,
  user_defined_key: string,
};

export type CreateNotificationsInput = {
  id?: string | null,
  domainId: string,
  title: string,
  desc?: string | null,
  users: Array< string | null >,
  departments: Array< string | null >,
  exclude_users: Array< string | null >,
  exclude_departments: Array< string | null >,
};

export type ModelNotificationsConditionInput = {
  domainId?: ModelStringInput | null,
  title?: ModelStringInput | null,
  desc?: ModelStringInput | null,
  users?: ModelStringInput | null,
  departments?: ModelStringInput | null,
  exclude_users?: ModelStringInput | null,
  exclude_departments?: ModelStringInput | null,
  and?: Array< ModelNotificationsConditionInput | null > | null,
  or?: Array< ModelNotificationsConditionInput | null > | null,
  not?: ModelNotificationsConditionInput | null,
};

export type Notifications = {
  __typename: "Notifications",
  id: string,
  domainId: string,
  title: string,
  desc?: string | null,
  users: Array< string | null >,
  departments: Array< string | null >,
  exclude_users: Array< string | null >,
  exclude_departments: Array< string | null >,
  createdAt: string,
  updatedAt: string,
};

export type UpdateNotificationsInput = {
  id: string,
  domainId?: string | null,
  title?: string | null,
  desc?: string | null,
  users?: Array< string | null > | null,
  departments?: Array< string | null > | null,
  exclude_users?: Array< string | null > | null,
  exclude_departments?: Array< string | null > | null,
};

export type DeleteNotificationsInput = {
  id: string,
};

export type CreateIconReactionInput = {
  type: IconReactionType,
  targetType: ReactionTargetType,
  domainId: string,
  topicId: string,
  messageId: string,
  commentId: string,
  userIdList: Array< string >,
};

export enum IconReactionType {
  FAVORITE = "FAVORITE",
  LIKE = "LIKE",
}


export enum ReactionTargetType {
  TOPIC = "TOPIC",
  MESSAGE = "MESSAGE",
  COMMENT = "COMMENT",
}


export type ModelIconReactionConditionInput = {
  userIdList?: ModelStringInput | null,
  and?: Array< ModelIconReactionConditionInput | null > | null,
  or?: Array< ModelIconReactionConditionInput | null > | null,
  not?: ModelIconReactionConditionInput | null,
};

export type IconReaction = {
  __typename: "IconReaction",
  type: IconReactionType,
  targetType: ReactionTargetType,
  domainId: string,
  topicId: string,
  messageId: string,
  commentId: string,
  userIdList: Array< string >,
  createdAt: string,
  updatedAt: string,
};

export type UpdateIconReactionInput = {
  type: IconReactionType,
  targetType: ReactionTargetType,
  domainId: string,
  topicId: string,
  messageId: string,
  commentId: string,
  userIdList?: Array< string > | null,
};

export type DeleteIconReactionInput = {
  type: IconReactionType,
  targetType: ReactionTargetType,
  domainId: string,
  topicId: string,
  messageId: string,
  commentId: string,
};

export type CreateDomainSettingsInput = {
  id: string,
  createTopicAuthorityGroupPolicy?: AuthorityGroupPolicyInput | null,
  updateTopicAuthorityGroupPolicy?: AuthorityGroupPolicyInput | null,
  attachmentPolicy: AttachmentPolicy,
  attachmentFileType: AttachmentFileTypeInput,
  downloadPolicy: DownloadPolicy,
};

export type AuthorityGroupPolicyInput = {
  admin?: boolean | null,
  user?: boolean | null,
};

export enum AttachmentPolicy {
  DENY = "DENY",
  ALLOW = "ALLOW",
  FOLLOW_DIRECT = "FOLLOW_DIRECT",
}


export type AttachmentFileTypeInput = {
  image?: boolean | null,
  movie?: boolean | null,
  pdf?: boolean | null,
  office?: boolean | null,
};

export enum DownloadPolicy {
  DENY = "DENY",
  ALLOW = "ALLOW",
  FOLLOW_DIRECT = "FOLLOW_DIRECT",
}


export type ModelDomainSettingsConditionInput = {
  attachmentPolicy?: ModelAttachmentPolicyInput | null,
  downloadPolicy?: ModelDownloadPolicyInput | null,
  and?: Array< ModelDomainSettingsConditionInput | null > | null,
  or?: Array< ModelDomainSettingsConditionInput | null > | null,
  not?: ModelDomainSettingsConditionInput | null,
};

export type ModelAttachmentPolicyInput = {
  eq?: AttachmentPolicy | null,
  ne?: AttachmentPolicy | null,
};

export type ModelDownloadPolicyInput = {
  eq?: DownloadPolicy | null,
  ne?: DownloadPolicy | null,
};

export type DomainSettings = {
  __typename: "DomainSettings",
  id: string,
  createTopicAuthorityGroupPolicy?: AuthorityGroupPolicy | null,
  updateTopicAuthorityGroupPolicy?: AuthorityGroupPolicy | null,
  attachmentPolicy: AttachmentPolicy,
  attachmentFileType: AttachmentFileType,
  downloadPolicy: DownloadPolicy,
  createdAt: string,
  updatedAt: string,
  owner?: string | null,
};

export type AuthorityGroupPolicy = {
  __typename: "AuthorityGroupPolicy",
  admin?: boolean | null,
  user?: boolean | null,
};

export type AttachmentFileType = {
  __typename: "AttachmentFileType",
  image?: boolean | null,
  movie?: boolean | null,
  pdf?: boolean | null,
  office?: boolean | null,
};

export type UpdateDomainSettingsInput = {
  id: string,
  createTopicAuthorityGroupPolicy?: AuthorityGroupPolicyInput | null,
  updateTopicAuthorityGroupPolicy?: AuthorityGroupPolicyInput | null,
  attachmentPolicy?: AttachmentPolicy | null,
  attachmentFileType?: AttachmentFileTypeInput | null,
  downloadPolicy?: DownloadPolicy | null,
};

export type DeleteDomainSettingsInput = {
  id: string,
};

export type CreateAllowFeatureInput = {
  keyword: string,
  releasePhase: string,
  desc?: string | null,
  updatedAt?: string | null,
  createdAt?: string | null,
};

export type ModelAllowFeatureConditionInput = {
  releasePhase?: ModelStringInput | null,
  desc?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  createdAt?: ModelStringInput | null,
  and?: Array< ModelAllowFeatureConditionInput | null > | null,
  or?: Array< ModelAllowFeatureConditionInput | null > | null,
  not?: ModelAllowFeatureConditionInput | null,
};

export type AllowFeature = {
  __typename: "AllowFeature",
  keyword: string,
  releasePhase: string,
  desc?: string | null,
  updatedAt?: string | null,
  createdAt?: string | null,
};

export type UpdateAllowFeatureInput = {
  keyword: string,
  releasePhase?: string | null,
  desc?: string | null,
  updatedAt?: string | null,
  createdAt?: string | null,
};

export type DeleteAllowFeatureInput = {
  keyword: string,
};

export type AuthenticatedImageUrlReturnType = {
  __typename: "AuthenticatedImageUrlReturnType",
  statusCode: number,
  body: string,
};

export type ListDomainsReturnType = {
  __typename: "ListDomainsReturnType",
  statusCode: number,
  body:  Array<DirectDomainType >,
};

export type DirectDomainType = {
  __typename: "DirectDomainType",
  domain_id_str: string,
  domain_name: string,
  frozen?: boolean | null,
  updated_at: number,
  role?: DirectDomainRoleType | null,
  contract: DirectDomainContractType,
  setting: DirectDomainSettingType,
};

export type DirectDomainRoleType = {
  __typename: "DirectDomainRoleType",
  role_id: number,
  type: number,
  name: string,
};

export type DirectDomainContractType = {
  __typename: "DirectDomainContractType",
  solution_ids?: Array< string | null > | null,
  direct_app_keys?: Array< string | null > | null,
};

export type DirectDomainSettingType = {
  __typename: "DirectDomainSettingType",
  allow_attachment_type?: number | null,
  allow_save_attachments?: DirectDomainSaveAttachementsType | null,
  allow_take_screenshot?: boolean | null,
};

export type DirectDomainSaveAttachementsType = {
  __typename: "DirectDomainSaveAttachementsType",
  web?: boolean | null,
  ios?: boolean | null,
  android?: boolean | null,
  desktop?: boolean | null,
};

export type AttachmentPushUrlReturnType = {
  __typename: "AttachmentPushUrlReturnType",
  statusCode: number,
  body: string,
};

export type ModelIDKeyConditionInput = {
  eq?: string | null,
  le?: string | null,
  lt?: string | null,
  ge?: string | null,
  gt?: string | null,
  between?: Array< string | null > | null,
  beginsWith?: string | null,
};

export type ModelTopicFilterInput = {
  id?: ModelIDInput | null,
  domainId?: ModelStringInput | null,
  title?: ModelStringInput | null,
  desc?: ModelStringInput | null,
  pinned?: ModelBooleanInput | null,
  categoryId?: ModelIDInput | null,
  messages?: ModelIntInput | null,
  owner?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  createdAt?: ModelStringInput | null,
  deleted?: ModelBooleanInput | null,
  deletedUser?: ModelStringInput | null,
  notification?: ModelIDInput | null,
  and?: Array< ModelTopicFilterInput | null > | null,
  or?: Array< ModelTopicFilterInput | null > | null,
  not?: ModelTopicFilterInput | null,
};

export enum ModelSortDirection {
  ASC = "ASC",
  DESC = "DESC",
}


export type ModelTopicConnection = {
  __typename: "ModelTopicConnection",
  items:  Array<Topic | null >,
  nextToken?: string | null,
};

export type ModelMessageFilterInput = {
  id?: ModelIDInput | null,
  domainId?: ModelStringInput | null,
  topicId?: ModelStringInput | null,
  title?: ModelStringInput | null,
  message?: ModelStringInput | null,
  pinned?: ModelBooleanInput | null,
  owner?: ModelStringInput | null,
  commentId?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  createdAt?: ModelStringInput | null,
  deleted?: ModelBooleanInput | null,
  deletedUser?: ModelStringInput | null,
  and?: Array< ModelMessageFilterInput | null > | null,
  or?: Array< ModelMessageFilterInput | null > | null,
  not?: ModelMessageFilterInput | null,
};

export type ModelMessageConnection = {
  __typename: "ModelMessageConnection",
  items:  Array<Message | null >,
  nextToken?: string | null,
};

export type ModelCommentFilterInput = {
  id?: ModelIDInput | null,
  domainId?: ModelStringInput | null,
  topicId?: ModelStringInput | null,
  messageId?: ModelStringInput | null,
  message?: ModelStringInput | null,
  owner?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  createdAt?: ModelStringInput | null,
  deleted?: ModelBooleanInput | null,
  deletedUser?: ModelStringInput | null,
  and?: Array< ModelCommentFilterInput | null > | null,
  or?: Array< ModelCommentFilterInput | null > | null,
  not?: ModelCommentFilterInput | null,
};

export type ModelCommentConnection = {
  __typename: "ModelCommentConnection",
  items:  Array<Comment | null >,
  nextToken?: string | null,
};

export type ModelCategoryFilterInput = {
  id?: ModelIDInput | null,
  title?: ModelStringInput | null,
  domainId?: ModelStringInput | null,
  color?: ModelStringInput | null,
  owner?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  createdAt?: ModelStringInput | null,
  deleted?: ModelBooleanInput | null,
  deletedUser?: ModelStringInput | null,
  and?: Array< ModelCategoryFilterInput | null > | null,
  or?: Array< ModelCategoryFilterInput | null > | null,
  not?: ModelCategoryFilterInput | null,
};

export type ModelCategoryConnection = {
  __typename: "ModelCategoryConnection",
  items:  Array<Category | null >,
  nextToken?: string | null,
};

export type ModelUserFilterInput = {
  id?: ModelIDInput | null,
  directId?: ModelStringInput | null,
  name?: ModelStringInput | null,
  profileIcon?: ModelStringInput | null,
  domainIdList?: ModelStringInput | null,
  owner?: ModelStringInput | null,
  favorites?: ModelStringInput | null,
  likes?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  createdAt?: ModelStringInput | null,
  and?: Array< ModelUserFilterInput | null > | null,
  or?: Array< ModelUserFilterInput | null > | null,
  not?: ModelUserFilterInput | null,
};

export type ModelUserConnection = {
  __typename: "ModelUserConnection",
  items:  Array<User | null >,
  nextToken?: string | null,
};

export type ModelStringKeyConditionInput = {
  eq?: string | null,
  le?: string | null,
  lt?: string | null,
  ge?: string | null,
  gt?: string | null,
  between?: Array< string | null > | null,
  beginsWith?: string | null,
};

export type ModelDomainUserFilterInput = {
  domainId?: ModelStringInput | null,
  userDirectId?: ModelStringInput | null,
  and?: Array< ModelDomainUserFilterInput | null > | null,
  or?: Array< ModelDomainUserFilterInput | null > | null,
  not?: ModelDomainUserFilterInput | null,
};

export type ModelDomainUserConnection = {
  __typename: "ModelDomainUserConnection",
  items:  Array<DomainUser | null >,
  nextToken?: string | null,
};

export type ModelDepartmentFilterInput = {
  domainId?: ModelStringInput | null,
  department_id?: ModelStringInput | null,
  name?: ModelStringInput | null,
  user_defined_key?: ModelStringInput | null,
  order?: ModelIntInput | null,
  parent?: ModelStringInput | null,
  node?: ModelIntInput | null,
  and?: Array< ModelDepartmentFilterInput | null > | null,
  or?: Array< ModelDepartmentFilterInput | null > | null,
  not?: ModelDepartmentFilterInput | null,
};

export type ModelDepartmentConnection = {
  __typename: "ModelDepartmentConnection",
  items:  Array<Department | null >,
  nextToken?: string | null,
};

export type ModelNotificationsFilterInput = {
  id?: ModelIDInput | null,
  domainId?: ModelStringInput | null,
  title?: ModelStringInput | null,
  desc?: ModelStringInput | null,
  users?: ModelStringInput | null,
  departments?: ModelStringInput | null,
  exclude_users?: ModelStringInput | null,
  exclude_departments?: ModelStringInput | null,
  and?: Array< ModelNotificationsFilterInput | null > | null,
  or?: Array< ModelNotificationsFilterInput | null > | null,
  not?: ModelNotificationsFilterInput | null,
};

export type ModelNotificationsConnection = {
  __typename: "ModelNotificationsConnection",
  items:  Array<Notifications | null >,
  nextToken?: string | null,
};

export type ModelIconReactionPrimaryCompositeKeyConditionInput = {
  eq?: ModelIconReactionPrimaryCompositeKeyInput | null,
  le?: ModelIconReactionPrimaryCompositeKeyInput | null,
  lt?: ModelIconReactionPrimaryCompositeKeyInput | null,
  ge?: ModelIconReactionPrimaryCompositeKeyInput | null,
  gt?: ModelIconReactionPrimaryCompositeKeyInput | null,
  between?: Array< ModelIconReactionPrimaryCompositeKeyInput | null > | null,
  beginsWith?: ModelIconReactionPrimaryCompositeKeyInput | null,
};

export type ModelIconReactionPrimaryCompositeKeyInput = {
  targetType?: ReactionTargetType | null,
  domainId?: string | null,
  topicId?: string | null,
  messageId?: string | null,
  commentId?: string | null,
};

export type ModelIconReactionFilterInput = {
  type?: ModelIconReactionTypeInput | null,
  targetType?: ModelReactionTargetTypeInput | null,
  domainId?: ModelStringInput | null,
  topicId?: ModelStringInput | null,
  messageId?: ModelStringInput | null,
  commentId?: ModelStringInput | null,
  userIdList?: ModelStringInput | null,
  and?: Array< ModelIconReactionFilterInput | null > | null,
  or?: Array< ModelIconReactionFilterInput | null > | null,
  not?: ModelIconReactionFilterInput | null,
};

export type ModelIconReactionTypeInput = {
  eq?: IconReactionType | null,
  ne?: IconReactionType | null,
};

export type ModelReactionTargetTypeInput = {
  eq?: ReactionTargetType | null,
  ne?: ReactionTargetType | null,
};

export type ModelIconReactionConnection = {
  __typename: "ModelIconReactionConnection",
  items:  Array<IconReaction | null >,
  nextToken?: string | null,
};

export type ModelDomainSettingsFilterInput = {
  id?: ModelStringInput | null,
  attachmentPolicy?: ModelAttachmentPolicyInput | null,
  downloadPolicy?: ModelDownloadPolicyInput | null,
  and?: Array< ModelDomainSettingsFilterInput | null > | null,
  or?: Array< ModelDomainSettingsFilterInput | null > | null,
  not?: ModelDomainSettingsFilterInput | null,
};

export type ModelDomainSettingsConnection = {
  __typename: "ModelDomainSettingsConnection",
  items:  Array<DomainSettings | null >,
  nextToken?: string | null,
};

export type ModelAllowFeatureFilterInput = {
  keyword?: ModelStringInput | null,
  releasePhase?: ModelStringInput | null,
  desc?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  createdAt?: ModelStringInput | null,
  and?: Array< ModelAllowFeatureFilterInput | null > | null,
  or?: Array< ModelAllowFeatureFilterInput | null > | null,
  not?: ModelAllowFeatureFilterInput | null,
};

export type ModelAllowFeatureConnection = {
  __typename: "ModelAllowFeatureConnection",
  items:  Array<AllowFeature | null >,
  nextToken?: string | null,
};

export type ModelCommentListCommentsByMessageIdCompositeKeyConditionInput = {
  eq?: ModelCommentListCommentsByMessageIdCompositeKeyInput | null,
  le?: ModelCommentListCommentsByMessageIdCompositeKeyInput | null,
  lt?: ModelCommentListCommentsByMessageIdCompositeKeyInput | null,
  ge?: ModelCommentListCommentsByMessageIdCompositeKeyInput | null,
  gt?: ModelCommentListCommentsByMessageIdCompositeKeyInput | null,
  between?: Array< ModelCommentListCommentsByMessageIdCompositeKeyInput | null > | null,
  beginsWith?: ModelCommentListCommentsByMessageIdCompositeKeyInput | null,
};

export type ModelCommentListCommentsByMessageIdCompositeKeyInput = {
  messageId?: string | null,
  createdAt?: string | null,
};

export type ModelIntKeyConditionInput = {
  eq?: number | null,
  le?: number | null,
  lt?: number | null,
  ge?: number | null,
  gt?: number | null,
  between?: Array< number | null > | null,
};

export type ModelIconReactionListIconReactionByDomaniIdCompositeKeyConditionInput = {
  eq?: ModelIconReactionListIconReactionByDomaniIdCompositeKeyInput | null,
  le?: ModelIconReactionListIconReactionByDomaniIdCompositeKeyInput | null,
  lt?: ModelIconReactionListIconReactionByDomaniIdCompositeKeyInput | null,
  ge?: ModelIconReactionListIconReactionByDomaniIdCompositeKeyInput | null,
  gt?: ModelIconReactionListIconReactionByDomaniIdCompositeKeyInput | null,
  between?: Array< ModelIconReactionListIconReactionByDomaniIdCompositeKeyInput | null > | null,
  beginsWith?: ModelIconReactionListIconReactionByDomaniIdCompositeKeyInput | null,
};

export type ModelIconReactionListIconReactionByDomaniIdCompositeKeyInput = {
  targetType?: ReactionTargetType | null,
  domainId?: string | null,
};

export type ModelIconReactionListIconReactionByTopicIdCompositeKeyConditionInput = {
  eq?: ModelIconReactionListIconReactionByTopicIdCompositeKeyInput | null,
  le?: ModelIconReactionListIconReactionByTopicIdCompositeKeyInput | null,
  lt?: ModelIconReactionListIconReactionByTopicIdCompositeKeyInput | null,
  ge?: ModelIconReactionListIconReactionByTopicIdCompositeKeyInput | null,
  gt?: ModelIconReactionListIconReactionByTopicIdCompositeKeyInput | null,
  between?: Array< ModelIconReactionListIconReactionByTopicIdCompositeKeyInput | null > | null,
  beginsWith?: ModelIconReactionListIconReactionByTopicIdCompositeKeyInput | null,
};

export type ModelIconReactionListIconReactionByTopicIdCompositeKeyInput = {
  targetType?: ReactionTargetType | null,
  domainId?: string | null,
  topicId?: string | null,
};

export type SearchableTopicFilterInput = {
  id?: SearchableIDFilterInput | null,
  domainId?: SearchableStringFilterInput | null,
  title?: SearchableStringFilterInput | null,
  desc?: SearchableStringFilterInput | null,
  pinned?: SearchableBooleanFilterInput | null,
  categoryId?: SearchableIDFilterInput | null,
  messages?: SearchableIntFilterInput | null,
  owner?: SearchableStringFilterInput | null,
  updatedAt?: SearchableStringFilterInput | null,
  createdAt?: SearchableStringFilterInput | null,
  deleted?: SearchableBooleanFilterInput | null,
  deletedUser?: SearchableStringFilterInput | null,
  notification?: SearchableIDFilterInput | null,
  and?: Array< SearchableTopicFilterInput | null > | null,
  or?: Array< SearchableTopicFilterInput | null > | null,
  not?: SearchableTopicFilterInput | null,
};

export type SearchableIDFilterInput = {
  ne?: string | null,
  gt?: string | null,
  lt?: string | null,
  gte?: string | null,
  lte?: string | null,
  eq?: string | null,
  match?: string | null,
  matchPhrase?: string | null,
  matchPhrasePrefix?: string | null,
  multiMatch?: string | null,
  exists?: boolean | null,
  wildcard?: string | null,
  regexp?: string | null,
  range?: Array< string | null > | null,
};

export type SearchableStringFilterInput = {
  ne?: string | null,
  gt?: string | null,
  lt?: string | null,
  gte?: string | null,
  lte?: string | null,
  eq?: string | null,
  match?: string | null,
  matchPhrase?: string | null,
  matchPhrasePrefix?: string | null,
  multiMatch?: string | null,
  exists?: boolean | null,
  wildcard?: string | null,
  regexp?: string | null,
  range?: Array< string | null > | null,
};

export type SearchableBooleanFilterInput = {
  eq?: boolean | null,
  ne?: boolean | null,
};

export type SearchableIntFilterInput = {
  ne?: number | null,
  gt?: number | null,
  lt?: number | null,
  gte?: number | null,
  lte?: number | null,
  eq?: number | null,
  range?: Array< number | null > | null,
};

export type SearchableTopicSortInput = {
  field?: SearchableTopicSortableFields | null,
  direction?: SearchableSortDirection | null,
};

export enum SearchableTopicSortableFields {
  id = "id",
  domainId = "domainId",
  title = "title",
  desc = "desc",
  pinned = "pinned",
  categoryId = "categoryId",
  messages = "messages",
  owner = "owner",
  updatedAt = "updatedAt",
  createdAt = "createdAt",
  deleted = "deleted",
  deletedUser = "deletedUser",
  notification = "notification",
}


export enum SearchableSortDirection {
  asc = "asc",
  desc = "desc",
}


export type SearchableTopicConnection = {
  __typename: "SearchableTopicConnection",
  items:  Array<Topic | null >,
  nextToken?: string | null,
  total?: number | null,
};

export type SearchableMessageFilterInput = {
  id?: SearchableIDFilterInput | null,
  domainId?: SearchableStringFilterInput | null,
  topicId?: SearchableStringFilterInput | null,
  title?: SearchableStringFilterInput | null,
  message?: SearchableStringFilterInput | null,
  pinned?: SearchableBooleanFilterInput | null,
  owner?: SearchableStringFilterInput | null,
  commentId?: SearchableStringFilterInput | null,
  updatedAt?: SearchableStringFilterInput | null,
  createdAt?: SearchableStringFilterInput | null,
  deleted?: SearchableBooleanFilterInput | null,
  deletedUser?: SearchableStringFilterInput | null,
  and?: Array< SearchableMessageFilterInput | null > | null,
  or?: Array< SearchableMessageFilterInput | null > | null,
  not?: SearchableMessageFilterInput | null,
};

export type SearchableMessageSortInput = {
  field?: SearchableMessageSortableFields | null,
  direction?: SearchableSortDirection | null,
};

export enum SearchableMessageSortableFields {
  id = "id",
  domainId = "domainId",
  topicId = "topicId",
  title = "title",
  message = "message",
  pinned = "pinned",
  owner = "owner",
  commentId = "commentId",
  updatedAt = "updatedAt",
  createdAt = "createdAt",
  deleted = "deleted",
  deletedUser = "deletedUser",
}


export type SearchableMessageConnection = {
  __typename: "SearchableMessageConnection",
  items:  Array<Message | null >,
  nextToken?: string | null,
  total?: number | null,
};

export type SearchableCommentFilterInput = {
  id?: SearchableIDFilterInput | null,
  domainId?: SearchableStringFilterInput | null,
  topicId?: SearchableStringFilterInput | null,
  messageId?: SearchableStringFilterInput | null,
  message?: SearchableStringFilterInput | null,
  owner?: SearchableStringFilterInput | null,
  updatedAt?: SearchableStringFilterInput | null,
  createdAt?: SearchableStringFilterInput | null,
  deleted?: SearchableBooleanFilterInput | null,
  deletedUser?: SearchableStringFilterInput | null,
  and?: Array< SearchableCommentFilterInput | null > | null,
  or?: Array< SearchableCommentFilterInput | null > | null,
  not?: SearchableCommentFilterInput | null,
};

export type SearchableCommentSortInput = {
  field?: SearchableCommentSortableFields | null,
  direction?: SearchableSortDirection | null,
};

export enum SearchableCommentSortableFields {
  id = "id",
  domainId = "domainId",
  topicId = "topicId",
  messageId = "messageId",
  message = "message",
  owner = "owner",
  updatedAt = "updatedAt",
  createdAt = "createdAt",
  deleted = "deleted",
  deletedUser = "deletedUser",
}


export type SearchableCommentConnection = {
  __typename: "SearchableCommentConnection",
  items:  Array<Comment | null >,
  nextToken?: string | null,
  total?: number | null,
};

export type CreateTopicMutationVariables = {
  input: CreateTopicInput,
  condition?: ModelTopicConditionInput | null,
};

export type CreateTopicMutation = {
  createTopic?:  {
    __typename: "Topic",
    id: string,
    domainId: string,
    title: string,
    desc: string,
    icon?:  {
      __typename: "Attachment",
      url: string,
      mime: string,
    } | null,
    pinned?: boolean | null,
    categoryId: string,
    messages?: number | null,
    owner?: string | null,
    updatedAt: string,
    createdAt?: string | null,
    deleted?: boolean | null,
    deletedUser?: string | null,
    acl?:  {
      __typename: "Acl",
      id?: string | null,
      refId: string,
      refIdType: RefidType,
      base:  {
        __typename: "AclBaseItem",
        create?: boolean | null,
        read?: boolean | null,
        update?: boolean | null,
        delete?: boolean | null,
        createTopic?: string | null,
        createMessage?: string | null,
        createComment?: string | null,
        createReactions?: string | null,
      },
      guest:  {
        __typename: "AclItem",
        id?: string | null,
        refId: string,
        refIdType: RefidType,
        create?: boolean | null,
        read?: boolean | null,
        update?: boolean | null,
        delete?: boolean | null,
        createTopic?: string | null,
        createMessage?: string | null,
        createComment?: string | null,
        createReactions?: string | null,
      },
      items?:  Array< {
        __typename: "AclItem",
        id?: string | null,
        refId: string,
        refIdType: RefidType,
        create?: boolean | null,
        read?: boolean | null,
        update?: boolean | null,
        delete?: boolean | null,
        createTopic?: string | null,
        createMessage?: string | null,
        createComment?: string | null,
        createReactions?: string | null,
      } > | null,
    } | null,
    notification?: string | null,
  } | null,
};

export type UpdateTopicMutationVariables = {
  input: UpdateTopicInput,
  condition?: ModelTopicConditionInput | null,
};

export type UpdateTopicMutation = {
  updateTopic?:  {
    __typename: "Topic",
    id: string,
    domainId: string,
    title: string,
    desc: string,
    icon?:  {
      __typename: "Attachment",
      url: string,
      mime: string,
    } | null,
    pinned?: boolean | null,
    categoryId: string,
    messages?: number | null,
    owner?: string | null,
    updatedAt: string,
    createdAt?: string | null,
    deleted?: boolean | null,
    deletedUser?: string | null,
    acl?:  {
      __typename: "Acl",
      id?: string | null,
      refId: string,
      refIdType: RefidType,
      base:  {
        __typename: "AclBaseItem",
        create?: boolean | null,
        read?: boolean | null,
        update?: boolean | null,
        delete?: boolean | null,
        createTopic?: string | null,
        createMessage?: string | null,
        createComment?: string | null,
        createReactions?: string | null,
      },
      guest:  {
        __typename: "AclItem",
        id?: string | null,
        refId: string,
        refIdType: RefidType,
        create?: boolean | null,
        read?: boolean | null,
        update?: boolean | null,
        delete?: boolean | null,
        createTopic?: string | null,
        createMessage?: string | null,
        createComment?: string | null,
        createReactions?: string | null,
      },
      items?:  Array< {
        __typename: "AclItem",
        id?: string | null,
        refId: string,
        refIdType: RefidType,
        create?: boolean | null,
        read?: boolean | null,
        update?: boolean | null,
        delete?: boolean | null,
        createTopic?: string | null,
        createMessage?: string | null,
        createComment?: string | null,
        createReactions?: string | null,
      } > | null,
    } | null,
    notification?: string | null,
  } | null,
};

export type DeleteTopicMutationVariables = {
  input: DeleteTopicInput,
  condition?: ModelTopicConditionInput | null,
};

export type DeleteTopicMutation = {
  deleteTopic?:  {
    __typename: "Topic",
    id: string,
    domainId: string,
    title: string,
    desc: string,
    icon?:  {
      __typename: "Attachment",
      url: string,
      mime: string,
    } | null,
    pinned?: boolean | null,
    categoryId: string,
    messages?: number | null,
    owner?: string | null,
    updatedAt: string,
    createdAt?: string | null,
    deleted?: boolean | null,
    deletedUser?: string | null,
    acl?:  {
      __typename: "Acl",
      id?: string | null,
      refId: string,
      refIdType: RefidType,
      base:  {
        __typename: "AclBaseItem",
        create?: boolean | null,
        read?: boolean | null,
        update?: boolean | null,
        delete?: boolean | null,
        createTopic?: string | null,
        createMessage?: string | null,
        createComment?: string | null,
        createReactions?: string | null,
      },
      guest:  {
        __typename: "AclItem",
        id?: string | null,
        refId: string,
        refIdType: RefidType,
        create?: boolean | null,
        read?: boolean | null,
        update?: boolean | null,
        delete?: boolean | null,
        createTopic?: string | null,
        createMessage?: string | null,
        createComment?: string | null,
        createReactions?: string | null,
      },
      items?:  Array< {
        __typename: "AclItem",
        id?: string | null,
        refId: string,
        refIdType: RefidType,
        create?: boolean | null,
        read?: boolean | null,
        update?: boolean | null,
        delete?: boolean | null,
        createTopic?: string | null,
        createMessage?: string | null,
        createComment?: string | null,
        createReactions?: string | null,
      } > | null,
    } | null,
    notification?: string | null,
  } | null,
};

export type CreateMessageMutationVariables = {
  input: CreateMessageInput,
  condition?: ModelMessageConditionInput | null,
};

export type CreateMessageMutation = {
  createMessage?:  {
    __typename: "Message",
    id: string,
    domainId: string,
    topicId: string,
    title?: string | null,
    message?: string | null,
    pinned?: boolean | null,
    owner?: string | null,
    commentId: Array< string >,
    photos:  Array< {
      __typename: "Attachment",
      url: string,
      mime: string,
    } >,
    updatedAt: string,
    createdAt: string,
    deleted?: boolean | null,
    deletedUser?: string | null,
    acl?:  {
      __typename: "Acl",
      id?: string | null,
      refId: string,
      refIdType: RefidType,
      base:  {
        __typename: "AclBaseItem",
        create?: boolean | null,
        read?: boolean | null,
        update?: boolean | null,
        delete?: boolean | null,
        createTopic?: string | null,
        createMessage?: string | null,
        createComment?: string | null,
        createReactions?: string | null,
      },
      guest:  {
        __typename: "AclItem",
        id?: string | null,
        refId: string,
        refIdType: RefidType,
        create?: boolean | null,
        read?: boolean | null,
        update?: boolean | null,
        delete?: boolean | null,
        createTopic?: string | null,
        createMessage?: string | null,
        createComment?: string | null,
        createReactions?: string | null,
      },
      items?:  Array< {
        __typename: "AclItem",
        id?: string | null,
        refId: string,
        refIdType: RefidType,
        create?: boolean | null,
        read?: boolean | null,
        update?: boolean | null,
        delete?: boolean | null,
        createTopic?: string | null,
        createMessage?: string | null,
        createComment?: string | null,
        createReactions?: string | null,
      } > | null,
    } | null,
  } | null,
};

export type UpdateMessageMutationVariables = {
  input: UpdateMessageInput,
  condition?: ModelMessageConditionInput | null,
};

export type UpdateMessageMutation = {
  updateMessage?:  {
    __typename: "Message",
    id: string,
    domainId: string,
    topicId: string,
    title?: string | null,
    message?: string | null,
    pinned?: boolean | null,
    owner?: string | null,
    commentId: Array< string >,
    photos:  Array< {
      __typename: "Attachment",
      url: string,
      mime: string,
    } >,
    updatedAt: string,
    createdAt: string,
    deleted?: boolean | null,
    deletedUser?: string | null,
    acl?:  {
      __typename: "Acl",
      id?: string | null,
      refId: string,
      refIdType: RefidType,
      base:  {
        __typename: "AclBaseItem",
        create?: boolean | null,
        read?: boolean | null,
        update?: boolean | null,
        delete?: boolean | null,
        createTopic?: string | null,
        createMessage?: string | null,
        createComment?: string | null,
        createReactions?: string | null,
      },
      guest:  {
        __typename: "AclItem",
        id?: string | null,
        refId: string,
        refIdType: RefidType,
        create?: boolean | null,
        read?: boolean | null,
        update?: boolean | null,
        delete?: boolean | null,
        createTopic?: string | null,
        createMessage?: string | null,
        createComment?: string | null,
        createReactions?: string | null,
      },
      items?:  Array< {
        __typename: "AclItem",
        id?: string | null,
        refId: string,
        refIdType: RefidType,
        create?: boolean | null,
        read?: boolean | null,
        update?: boolean | null,
        delete?: boolean | null,
        createTopic?: string | null,
        createMessage?: string | null,
        createComment?: string | null,
        createReactions?: string | null,
      } > | null,
    } | null,
  } | null,
};

export type DeleteMessageMutationVariables = {
  input: DeleteMessageInput,
  condition?: ModelMessageConditionInput | null,
};

export type DeleteMessageMutation = {
  deleteMessage?:  {
    __typename: "Message",
    id: string,
    domainId: string,
    topicId: string,
    title?: string | null,
    message?: string | null,
    pinned?: boolean | null,
    owner?: string | null,
    commentId: Array< string >,
    photos:  Array< {
      __typename: "Attachment",
      url: string,
      mime: string,
    } >,
    updatedAt: string,
    createdAt: string,
    deleted?: boolean | null,
    deletedUser?: string | null,
    acl?:  {
      __typename: "Acl",
      id?: string | null,
      refId: string,
      refIdType: RefidType,
      base:  {
        __typename: "AclBaseItem",
        create?: boolean | null,
        read?: boolean | null,
        update?: boolean | null,
        delete?: boolean | null,
        createTopic?: string | null,
        createMessage?: string | null,
        createComment?: string | null,
        createReactions?: string | null,
      },
      guest:  {
        __typename: "AclItem",
        id?: string | null,
        refId: string,
        refIdType: RefidType,
        create?: boolean | null,
        read?: boolean | null,
        update?: boolean | null,
        delete?: boolean | null,
        createTopic?: string | null,
        createMessage?: string | null,
        createComment?: string | null,
        createReactions?: string | null,
      },
      items?:  Array< {
        __typename: "AclItem",
        id?: string | null,
        refId: string,
        refIdType: RefidType,
        create?: boolean | null,
        read?: boolean | null,
        update?: boolean | null,
        delete?: boolean | null,
        createTopic?: string | null,
        createMessage?: string | null,
        createComment?: string | null,
        createReactions?: string | null,
      } > | null,
    } | null,
  } | null,
};

export type CreateCommentMutationVariables = {
  input: CreateCommentInput,
  condition?: ModelCommentConditionInput | null,
};

export type CreateCommentMutation = {
  createComment?:  {
    __typename: "Comment",
    id: string,
    domainId: string,
    topicId: string,
    messageId: string,
    message: string,
    photos:  Array< {
      __typename: "Attachment",
      url: string,
      mime: string,
    } >,
    owner?: string | null,
    updatedAt: string,
    createdAt?: string | null,
    deleted?: boolean | null,
    deletedUser?: string | null,
    acl?:  {
      __typename: "Acl",
      id?: string | null,
      refId: string,
      refIdType: RefidType,
      base:  {
        __typename: "AclBaseItem",
        create?: boolean | null,
        read?: boolean | null,
        update?: boolean | null,
        delete?: boolean | null,
        createTopic?: string | null,
        createMessage?: string | null,
        createComment?: string | null,
        createReactions?: string | null,
      },
      guest:  {
        __typename: "AclItem",
        id?: string | null,
        refId: string,
        refIdType: RefidType,
        create?: boolean | null,
        read?: boolean | null,
        update?: boolean | null,
        delete?: boolean | null,
        createTopic?: string | null,
        createMessage?: string | null,
        createComment?: string | null,
        createReactions?: string | null,
      },
      items?:  Array< {
        __typename: "AclItem",
        id?: string | null,
        refId: string,
        refIdType: RefidType,
        create?: boolean | null,
        read?: boolean | null,
        update?: boolean | null,
        delete?: boolean | null,
        createTopic?: string | null,
        createMessage?: string | null,
        createComment?: string | null,
        createReactions?: string | null,
      } > | null,
    } | null,
  } | null,
};

export type UpdateCommentMutationVariables = {
  input: UpdateCommentInput,
  condition?: ModelCommentConditionInput | null,
};

export type UpdateCommentMutation = {
  updateComment?:  {
    __typename: "Comment",
    id: string,
    domainId: string,
    topicId: string,
    messageId: string,
    message: string,
    photos:  Array< {
      __typename: "Attachment",
      url: string,
      mime: string,
    } >,
    owner?: string | null,
    updatedAt: string,
    createdAt?: string | null,
    deleted?: boolean | null,
    deletedUser?: string | null,
    acl?:  {
      __typename: "Acl",
      id?: string | null,
      refId: string,
      refIdType: RefidType,
      base:  {
        __typename: "AclBaseItem",
        create?: boolean | null,
        read?: boolean | null,
        update?: boolean | null,
        delete?: boolean | null,
        createTopic?: string | null,
        createMessage?: string | null,
        createComment?: string | null,
        createReactions?: string | null,
      },
      guest:  {
        __typename: "AclItem",
        id?: string | null,
        refId: string,
        refIdType: RefidType,
        create?: boolean | null,
        read?: boolean | null,
        update?: boolean | null,
        delete?: boolean | null,
        createTopic?: string | null,
        createMessage?: string | null,
        createComment?: string | null,
        createReactions?: string | null,
      },
      items?:  Array< {
        __typename: "AclItem",
        id?: string | null,
        refId: string,
        refIdType: RefidType,
        create?: boolean | null,
        read?: boolean | null,
        update?: boolean | null,
        delete?: boolean | null,
        createTopic?: string | null,
        createMessage?: string | null,
        createComment?: string | null,
        createReactions?: string | null,
      } > | null,
    } | null,
  } | null,
};

export type DeleteCommentMutationVariables = {
  input: DeleteCommentInput,
  condition?: ModelCommentConditionInput | null,
};

export type DeleteCommentMutation = {
  deleteComment?:  {
    __typename: "Comment",
    id: string,
    domainId: string,
    topicId: string,
    messageId: string,
    message: string,
    photos:  Array< {
      __typename: "Attachment",
      url: string,
      mime: string,
    } >,
    owner?: string | null,
    updatedAt: string,
    createdAt?: string | null,
    deleted?: boolean | null,
    deletedUser?: string | null,
    acl?:  {
      __typename: "Acl",
      id?: string | null,
      refId: string,
      refIdType: RefidType,
      base:  {
        __typename: "AclBaseItem",
        create?: boolean | null,
        read?: boolean | null,
        update?: boolean | null,
        delete?: boolean | null,
        createTopic?: string | null,
        createMessage?: string | null,
        createComment?: string | null,
        createReactions?: string | null,
      },
      guest:  {
        __typename: "AclItem",
        id?: string | null,
        refId: string,
        refIdType: RefidType,
        create?: boolean | null,
        read?: boolean | null,
        update?: boolean | null,
        delete?: boolean | null,
        createTopic?: string | null,
        createMessage?: string | null,
        createComment?: string | null,
        createReactions?: string | null,
      },
      items?:  Array< {
        __typename: "AclItem",
        id?: string | null,
        refId: string,
        refIdType: RefidType,
        create?: boolean | null,
        read?: boolean | null,
        update?: boolean | null,
        delete?: boolean | null,
        createTopic?: string | null,
        createMessage?: string | null,
        createComment?: string | null,
        createReactions?: string | null,
      } > | null,
    } | null,
  } | null,
};

export type CreateCategoryMutationVariables = {
  input: CreateCategoryInput,
  condition?: ModelCategoryConditionInput | null,
};

export type CreateCategoryMutation = {
  createCategory?:  {
    __typename: "Category",
    id: string,
    title: string,
    domainId: string,
    color?: string | null,
    owner?: string | null,
    updatedAt?: string | null,
    createdAt?: string | null,
    deleted?: boolean | null,
    deletedUser?: string | null,
  } | null,
};

export type UpdateCategoryMutationVariables = {
  input: UpdateCategoryInput,
  condition?: ModelCategoryConditionInput | null,
};

export type UpdateCategoryMutation = {
  updateCategory?:  {
    __typename: "Category",
    id: string,
    title: string,
    domainId: string,
    color?: string | null,
    owner?: string | null,
    updatedAt?: string | null,
    createdAt?: string | null,
    deleted?: boolean | null,
    deletedUser?: string | null,
  } | null,
};

export type DeleteCategoryMutationVariables = {
  input: DeleteCategoryInput,
  condition?: ModelCategoryConditionInput | null,
};

export type DeleteCategoryMutation = {
  deleteCategory?:  {
    __typename: "Category",
    id: string,
    title: string,
    domainId: string,
    color?: string | null,
    owner?: string | null,
    updatedAt?: string | null,
    createdAt?: string | null,
    deleted?: boolean | null,
    deletedUser?: string | null,
  } | null,
};

export type CreateUserMutationVariables = {
  input: CreateUserInput,
  condition?: ModelUserConditionInput | null,
};

export type CreateUserMutation = {
  createUser?:  {
    __typename: "User",
    id: string,
    directId: string,
    name: string,
    profileIcon?: string | null,
    domainIdList: Array< string >,
    owner?: string | null,
    favorites?: Array< string | null > | null,
    likes?: Array< string | null > | null,
    updatedAt?: string | null,
    createdAt?: string | null,
  } | null,
};

export type UpdateUserMutationVariables = {
  input: UpdateUserInput,
  condition?: ModelUserConditionInput | null,
};

export type UpdateUserMutation = {
  updateUser?:  {
    __typename: "User",
    id: string,
    directId: string,
    name: string,
    profileIcon?: string | null,
    domainIdList: Array< string >,
    owner?: string | null,
    favorites?: Array< string | null > | null,
    likes?: Array< string | null > | null,
    updatedAt?: string | null,
    createdAt?: string | null,
  } | null,
};

export type DeleteUserMutationVariables = {
  input: DeleteUserInput,
  condition?: ModelUserConditionInput | null,
};

export type DeleteUserMutation = {
  deleteUser?:  {
    __typename: "User",
    id: string,
    directId: string,
    name: string,
    profileIcon?: string | null,
    domainIdList: Array< string >,
    owner?: string | null,
    favorites?: Array< string | null > | null,
    likes?: Array< string | null > | null,
    updatedAt?: string | null,
    createdAt?: string | null,
  } | null,
};

export type CreateDomainUserMutationVariables = {
  input: CreateDomainUserInput,
  condition?: ModelDomainUserConditionInput | null,
};

export type CreateDomainUserMutation = {
  createDomainUser?:  {
    __typename: "DomainUser",
    domainId: string,
    userDirectId: string,
    user?:  {
      __typename: "User",
      id: string,
      directId: string,
      name: string,
      profileIcon?: string | null,
      domainIdList: Array< string >,
      owner?: string | null,
      favorites?: Array< string | null > | null,
      likes?: Array< string | null > | null,
      updatedAt?: string | null,
      createdAt?: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type UpdateDomainUserMutationVariables = {
  input: UpdateDomainUserInput,
  condition?: ModelDomainUserConditionInput | null,
};

export type UpdateDomainUserMutation = {
  updateDomainUser?:  {
    __typename: "DomainUser",
    domainId: string,
    userDirectId: string,
    user?:  {
      __typename: "User",
      id: string,
      directId: string,
      name: string,
      profileIcon?: string | null,
      domainIdList: Array< string >,
      owner?: string | null,
      favorites?: Array< string | null > | null,
      likes?: Array< string | null > | null,
      updatedAt?: string | null,
      createdAt?: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type DeleteDomainUserMutationVariables = {
  input: DeleteDomainUserInput,
  condition?: ModelDomainUserConditionInput | null,
};

export type DeleteDomainUserMutation = {
  deleteDomainUser?:  {
    __typename: "DomainUser",
    domainId: string,
    userDirectId: string,
    user?:  {
      __typename: "User",
      id: string,
      directId: string,
      name: string,
      profileIcon?: string | null,
      domainIdList: Array< string >,
      owner?: string | null,
      favorites?: Array< string | null > | null,
      likes?: Array< string | null > | null,
      updatedAt?: string | null,
      createdAt?: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type CreateDepartmentMutationVariables = {
  input: CreateDepartmentInput,
  condition?: ModelDepartmentConditionInput | null,
};

export type CreateDepartmentMutation = {
  createDepartment?:  {
    __typename: "Department",
    domainId: string,
    department_id: string,
    name: string,
    user_defined_key: string,
    order: number,
    parent?: string | null,
    node?: number | null,
    createdAt: string,
    updatedAt: string,
    owner?: string | null,
  } | null,
};

export type UpdateDepartmentMutationVariables = {
  input: UpdateDepartmentInput,
  condition?: ModelDepartmentConditionInput | null,
};

export type UpdateDepartmentMutation = {
  updateDepartment?:  {
    __typename: "Department",
    domainId: string,
    department_id: string,
    name: string,
    user_defined_key: string,
    order: number,
    parent?: string | null,
    node?: number | null,
    createdAt: string,
    updatedAt: string,
    owner?: string | null,
  } | null,
};

export type DeleteDepartmentMutationVariables = {
  input: DeleteDepartmentInput,
  condition?: ModelDepartmentConditionInput | null,
};

export type DeleteDepartmentMutation = {
  deleteDepartment?:  {
    __typename: "Department",
    domainId: string,
    department_id: string,
    name: string,
    user_defined_key: string,
    order: number,
    parent?: string | null,
    node?: number | null,
    createdAt: string,
    updatedAt: string,
    owner?: string | null,
  } | null,
};

export type CreateNotificationsMutationVariables = {
  input: CreateNotificationsInput,
  condition?: ModelNotificationsConditionInput | null,
};

export type CreateNotificationsMutation = {
  createNotifications?:  {
    __typename: "Notifications",
    id: string,
    domainId: string,
    title: string,
    desc?: string | null,
    users: Array< string | null >,
    departments: Array< string | null >,
    exclude_users: Array< string | null >,
    exclude_departments: Array< string | null >,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type UpdateNotificationsMutationVariables = {
  input: UpdateNotificationsInput,
  condition?: ModelNotificationsConditionInput | null,
};

export type UpdateNotificationsMutation = {
  updateNotifications?:  {
    __typename: "Notifications",
    id: string,
    domainId: string,
    title: string,
    desc?: string | null,
    users: Array< string | null >,
    departments: Array< string | null >,
    exclude_users: Array< string | null >,
    exclude_departments: Array< string | null >,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type DeleteNotificationsMutationVariables = {
  input: DeleteNotificationsInput,
  condition?: ModelNotificationsConditionInput | null,
};

export type DeleteNotificationsMutation = {
  deleteNotifications?:  {
    __typename: "Notifications",
    id: string,
    domainId: string,
    title: string,
    desc?: string | null,
    users: Array< string | null >,
    departments: Array< string | null >,
    exclude_users: Array< string | null >,
    exclude_departments: Array< string | null >,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type CreateIconReactionMutationVariables = {
  input: CreateIconReactionInput,
  condition?: ModelIconReactionConditionInput | null,
};

export type CreateIconReactionMutation = {
  createIconReaction?:  {
    __typename: "IconReaction",
    type: IconReactionType,
    targetType: ReactionTargetType,
    domainId: string,
    topicId: string,
    messageId: string,
    commentId: string,
    userIdList: Array< string >,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type UpdateIconReactionMutationVariables = {
  input: UpdateIconReactionInput,
  condition?: ModelIconReactionConditionInput | null,
};

export type UpdateIconReactionMutation = {
  updateIconReaction?:  {
    __typename: "IconReaction",
    type: IconReactionType,
    targetType: ReactionTargetType,
    domainId: string,
    topicId: string,
    messageId: string,
    commentId: string,
    userIdList: Array< string >,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type DeleteIconReactionMutationVariables = {
  input: DeleteIconReactionInput,
  condition?: ModelIconReactionConditionInput | null,
};

export type DeleteIconReactionMutation = {
  deleteIconReaction?:  {
    __typename: "IconReaction",
    type: IconReactionType,
    targetType: ReactionTargetType,
    domainId: string,
    topicId: string,
    messageId: string,
    commentId: string,
    userIdList: Array< string >,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type CreateDomainSettingsMutationVariables = {
  input: CreateDomainSettingsInput,
  condition?: ModelDomainSettingsConditionInput | null,
};

export type CreateDomainSettingsMutation = {
  createDomainSettings?:  {
    __typename: "DomainSettings",
    id: string,
    createTopicAuthorityGroupPolicy?:  {
      __typename: "AuthorityGroupPolicy",
      admin?: boolean | null,
      user?: boolean | null,
    } | null,
    updateTopicAuthorityGroupPolicy?:  {
      __typename: "AuthorityGroupPolicy",
      admin?: boolean | null,
      user?: boolean | null,
    } | null,
    attachmentPolicy: AttachmentPolicy,
    attachmentFileType:  {
      __typename: "AttachmentFileType",
      image?: boolean | null,
      movie?: boolean | null,
      pdf?: boolean | null,
      office?: boolean | null,
    },
    downloadPolicy: DownloadPolicy,
    createdAt: string,
    updatedAt: string,
    owner?: string | null,
  } | null,
};

export type UpdateDomainSettingsMutationVariables = {
  input: UpdateDomainSettingsInput,
  condition?: ModelDomainSettingsConditionInput | null,
};

export type UpdateDomainSettingsMutation = {
  updateDomainSettings?:  {
    __typename: "DomainSettings",
    id: string,
    createTopicAuthorityGroupPolicy?:  {
      __typename: "AuthorityGroupPolicy",
      admin?: boolean | null,
      user?: boolean | null,
    } | null,
    updateTopicAuthorityGroupPolicy?:  {
      __typename: "AuthorityGroupPolicy",
      admin?: boolean | null,
      user?: boolean | null,
    } | null,
    attachmentPolicy: AttachmentPolicy,
    attachmentFileType:  {
      __typename: "AttachmentFileType",
      image?: boolean | null,
      movie?: boolean | null,
      pdf?: boolean | null,
      office?: boolean | null,
    },
    downloadPolicy: DownloadPolicy,
    createdAt: string,
    updatedAt: string,
    owner?: string | null,
  } | null,
};

export type DeleteDomainSettingsMutationVariables = {
  input: DeleteDomainSettingsInput,
  condition?: ModelDomainSettingsConditionInput | null,
};

export type DeleteDomainSettingsMutation = {
  deleteDomainSettings?:  {
    __typename: "DomainSettings",
    id: string,
    createTopicAuthorityGroupPolicy?:  {
      __typename: "AuthorityGroupPolicy",
      admin?: boolean | null,
      user?: boolean | null,
    } | null,
    updateTopicAuthorityGroupPolicy?:  {
      __typename: "AuthorityGroupPolicy",
      admin?: boolean | null,
      user?: boolean | null,
    } | null,
    attachmentPolicy: AttachmentPolicy,
    attachmentFileType:  {
      __typename: "AttachmentFileType",
      image?: boolean | null,
      movie?: boolean | null,
      pdf?: boolean | null,
      office?: boolean | null,
    },
    downloadPolicy: DownloadPolicy,
    createdAt: string,
    updatedAt: string,
    owner?: string | null,
  } | null,
};

export type CreateAllowFeatureMutationVariables = {
  input: CreateAllowFeatureInput,
  condition?: ModelAllowFeatureConditionInput | null,
};

export type CreateAllowFeatureMutation = {
  createAllowFeature?:  {
    __typename: "AllowFeature",
    keyword: string,
    releasePhase: string,
    desc?: string | null,
    updatedAt?: string | null,
    createdAt?: string | null,
  } | null,
};

export type UpdateAllowFeatureMutationVariables = {
  input: UpdateAllowFeatureInput,
  condition?: ModelAllowFeatureConditionInput | null,
};

export type UpdateAllowFeatureMutation = {
  updateAllowFeature?:  {
    __typename: "AllowFeature",
    keyword: string,
    releasePhase: string,
    desc?: string | null,
    updatedAt?: string | null,
    createdAt?: string | null,
  } | null,
};

export type DeleteAllowFeatureMutationVariables = {
  input: DeleteAllowFeatureInput,
  condition?: ModelAllowFeatureConditionInput | null,
};

export type DeleteAllowFeatureMutation = {
  deleteAllowFeature?:  {
    __typename: "AllowFeature",
    keyword: string,
    releasePhase: string,
    desc?: string | null,
    updatedAt?: string | null,
    createdAt?: string | null,
  } | null,
};

export type AuthenticatedImageUrlQueryVariables = {
  url?: string | null,
  token?: string | null,
};

export type AuthenticatedImageUrlQuery = {
  authenticatedImageUrl?:  {
    __typename: "AuthenticatedImageUrlReturnType",
    statusCode: number,
    body: string,
  } | null,
};

export type ListDomainsQueryVariables = {
  token?: string | null,
  provider?: string | null,
};

export type ListDomainsQuery = {
  listDomains?:  {
    __typename: "ListDomainsReturnType",
    statusCode: number,
    body:  Array< {
      __typename: "DirectDomainType",
      domain_id_str: string,
      domain_name: string,
      frozen?: boolean | null,
      updated_at: number,
      role?:  {
        __typename: "DirectDomainRoleType",
        role_id: number,
        type: number,
        name: string,
      } | null,
      contract:  {
        __typename: "DirectDomainContractType",
        solution_ids?: Array< string | null > | null,
        direct_app_keys?: Array< string | null > | null,
      },
      setting:  {
        __typename: "DirectDomainSettingType",
        allow_attachment_type?: number | null,
        allow_save_attachments?:  {
          __typename: "DirectDomainSaveAttachementsType",
          web?: boolean | null,
          ios?: boolean | null,
          android?: boolean | null,
          desktop?: boolean | null,
        } | null,
        allow_take_screenshot?: boolean | null,
      },
    } >,
  } | null,
};

export type AttachmentPushUrlQueryVariables = {
  objectKey?: string | null,
};

export type AttachmentPushUrlQuery = {
  attachmentPushUrl?:  {
    __typename: "AttachmentPushUrlReturnType",
    statusCode: number,
    body: string,
  } | null,
};

export type GetTopicQueryVariables = {
  domainId: string,
  id: string,
};

export type GetTopicQuery = {
  getTopic?:  {
    __typename: "Topic",
    id: string,
    domainId: string,
    title: string,
    desc: string,
    icon?:  {
      __typename: "Attachment",
      url: string,
      mime: string,
    } | null,
    pinned?: boolean | null,
    categoryId: string,
    messages?: number | null,
    owner?: string | null,
    updatedAt: string,
    createdAt?: string | null,
    deleted?: boolean | null,
    deletedUser?: string | null,
    acl?:  {
      __typename: "Acl",
      id?: string | null,
      refId: string,
      refIdType: RefidType,
      base:  {
        __typename: "AclBaseItem",
        create?: boolean | null,
        read?: boolean | null,
        update?: boolean | null,
        delete?: boolean | null,
        createTopic?: string | null,
        createMessage?: string | null,
        createComment?: string | null,
        createReactions?: string | null,
      },
      guest:  {
        __typename: "AclItem",
        id?: string | null,
        refId: string,
        refIdType: RefidType,
        create?: boolean | null,
        read?: boolean | null,
        update?: boolean | null,
        delete?: boolean | null,
        createTopic?: string | null,
        createMessage?: string | null,
        createComment?: string | null,
        createReactions?: string | null,
      },
      items?:  Array< {
        __typename: "AclItem",
        id?: string | null,
        refId: string,
        refIdType: RefidType,
        create?: boolean | null,
        read?: boolean | null,
        update?: boolean | null,
        delete?: boolean | null,
        createTopic?: string | null,
        createMessage?: string | null,
        createComment?: string | null,
        createReactions?: string | null,
      } > | null,
    } | null,
    notification?: string | null,
  } | null,
};

export type ListTopicsQueryVariables = {
  domainId?: string | null,
  id?: ModelIDKeyConditionInput | null,
  filter?: ModelTopicFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  sortDirection?: ModelSortDirection | null,
};

export type ListTopicsQuery = {
  listTopics?:  {
    __typename: "ModelTopicConnection",
    items:  Array< {
      __typename: "Topic",
      id: string,
      domainId: string,
      title: string,
      desc: string,
      icon?:  {
        __typename: "Attachment",
        url: string,
        mime: string,
      } | null,
      pinned?: boolean | null,
      categoryId: string,
      messages?: number | null,
      owner?: string | null,
      updatedAt: string,
      createdAt?: string | null,
      deleted?: boolean | null,
      deletedUser?: string | null,
      acl?:  {
        __typename: "Acl",
        id?: string | null,
        refId: string,
        refIdType: RefidType,
        base:  {
          __typename: "AclBaseItem",
          create?: boolean | null,
          read?: boolean | null,
          update?: boolean | null,
          delete?: boolean | null,
          createTopic?: string | null,
          createMessage?: string | null,
          createComment?: string | null,
          createReactions?: string | null,
        },
        guest:  {
          __typename: "AclItem",
          id?: string | null,
          refId: string,
          refIdType: RefidType,
          create?: boolean | null,
          read?: boolean | null,
          update?: boolean | null,
          delete?: boolean | null,
          createTopic?: string | null,
          createMessage?: string | null,
          createComment?: string | null,
          createReactions?: string | null,
        },
        items?:  Array< {
          __typename: "AclItem",
          id?: string | null,
          refId: string,
          refIdType: RefidType,
          create?: boolean | null,
          read?: boolean | null,
          update?: boolean | null,
          delete?: boolean | null,
          createTopic?: string | null,
          createMessage?: string | null,
          createComment?: string | null,
          createReactions?: string | null,
        } > | null,
      } | null,
      notification?: string | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetMessageQueryVariables = {
  topicId: string,
  id: string,
};

export type GetMessageQuery = {
  getMessage?:  {
    __typename: "Message",
    id: string,
    domainId: string,
    topicId: string,
    title?: string | null,
    message?: string | null,
    pinned?: boolean | null,
    owner?: string | null,
    commentId: Array< string >,
    photos:  Array< {
      __typename: "Attachment",
      url: string,
      mime: string,
    } >,
    updatedAt: string,
    createdAt: string,
    deleted?: boolean | null,
    deletedUser?: string | null,
    acl?:  {
      __typename: "Acl",
      id?: string | null,
      refId: string,
      refIdType: RefidType,
      base:  {
        __typename: "AclBaseItem",
        create?: boolean | null,
        read?: boolean | null,
        update?: boolean | null,
        delete?: boolean | null,
        createTopic?: string | null,
        createMessage?: string | null,
        createComment?: string | null,
        createReactions?: string | null,
      },
      guest:  {
        __typename: "AclItem",
        id?: string | null,
        refId: string,
        refIdType: RefidType,
        create?: boolean | null,
        read?: boolean | null,
        update?: boolean | null,
        delete?: boolean | null,
        createTopic?: string | null,
        createMessage?: string | null,
        createComment?: string | null,
        createReactions?: string | null,
      },
      items?:  Array< {
        __typename: "AclItem",
        id?: string | null,
        refId: string,
        refIdType: RefidType,
        create?: boolean | null,
        read?: boolean | null,
        update?: boolean | null,
        delete?: boolean | null,
        createTopic?: string | null,
        createMessage?: string | null,
        createComment?: string | null,
        createReactions?: string | null,
      } > | null,
    } | null,
  } | null,
};

export type ListMessagesQueryVariables = {
  topicId?: string | null,
  id?: ModelIDKeyConditionInput | null,
  filter?: ModelMessageFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  sortDirection?: ModelSortDirection | null,
};

export type ListMessagesQuery = {
  listMessages?:  {
    __typename: "ModelMessageConnection",
    items:  Array< {
      __typename: "Message",
      id: string,
      domainId: string,
      topicId: string,
      title?: string | null,
      message?: string | null,
      pinned?: boolean | null,
      owner?: string | null,
      commentId: Array< string >,
      photos:  Array< {
        __typename: "Attachment",
        url: string,
        mime: string,
      } >,
      updatedAt: string,
      createdAt: string,
      deleted?: boolean | null,
      deletedUser?: string | null,
      acl?:  {
        __typename: "Acl",
        id?: string | null,
        refId: string,
        refIdType: RefidType,
        base:  {
          __typename: "AclBaseItem",
          create?: boolean | null,
          read?: boolean | null,
          update?: boolean | null,
          delete?: boolean | null,
          createTopic?: string | null,
          createMessage?: string | null,
          createComment?: string | null,
          createReactions?: string | null,
        },
        guest:  {
          __typename: "AclItem",
          id?: string | null,
          refId: string,
          refIdType: RefidType,
          create?: boolean | null,
          read?: boolean | null,
          update?: boolean | null,
          delete?: boolean | null,
          createTopic?: string | null,
          createMessage?: string | null,
          createComment?: string | null,
          createReactions?: string | null,
        },
        items?:  Array< {
          __typename: "AclItem",
          id?: string | null,
          refId: string,
          refIdType: RefidType,
          create?: boolean | null,
          read?: boolean | null,
          update?: boolean | null,
          delete?: boolean | null,
          createTopic?: string | null,
          createMessage?: string | null,
          createComment?: string | null,
          createReactions?: string | null,
        } > | null,
      } | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetCommentQueryVariables = {
  messageId: string,
  id: string,
};

export type GetCommentQuery = {
  getComment?:  {
    __typename: "Comment",
    id: string,
    domainId: string,
    topicId: string,
    messageId: string,
    message: string,
    photos:  Array< {
      __typename: "Attachment",
      url: string,
      mime: string,
    } >,
    owner?: string | null,
    updatedAt: string,
    createdAt?: string | null,
    deleted?: boolean | null,
    deletedUser?: string | null,
    acl?:  {
      __typename: "Acl",
      id?: string | null,
      refId: string,
      refIdType: RefidType,
      base:  {
        __typename: "AclBaseItem",
        create?: boolean | null,
        read?: boolean | null,
        update?: boolean | null,
        delete?: boolean | null,
        createTopic?: string | null,
        createMessage?: string | null,
        createComment?: string | null,
        createReactions?: string | null,
      },
      guest:  {
        __typename: "AclItem",
        id?: string | null,
        refId: string,
        refIdType: RefidType,
        create?: boolean | null,
        read?: boolean | null,
        update?: boolean | null,
        delete?: boolean | null,
        createTopic?: string | null,
        createMessage?: string | null,
        createComment?: string | null,
        createReactions?: string | null,
      },
      items?:  Array< {
        __typename: "AclItem",
        id?: string | null,
        refId: string,
        refIdType: RefidType,
        create?: boolean | null,
        read?: boolean | null,
        update?: boolean | null,
        delete?: boolean | null,
        createTopic?: string | null,
        createMessage?: string | null,
        createComment?: string | null,
        createReactions?: string | null,
      } > | null,
    } | null,
  } | null,
};

export type ListCommentsQueryVariables = {
  messageId?: string | null,
  id?: ModelIDKeyConditionInput | null,
  filter?: ModelCommentFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  sortDirection?: ModelSortDirection | null,
};

export type ListCommentsQuery = {
  listComments?:  {
    __typename: "ModelCommentConnection",
    items:  Array< {
      __typename: "Comment",
      id: string,
      domainId: string,
      topicId: string,
      messageId: string,
      message: string,
      photos:  Array< {
        __typename: "Attachment",
        url: string,
        mime: string,
      } >,
      owner?: string | null,
      updatedAt: string,
      createdAt?: string | null,
      deleted?: boolean | null,
      deletedUser?: string | null,
      acl?:  {
        __typename: "Acl",
        id?: string | null,
        refId: string,
        refIdType: RefidType,
        base:  {
          __typename: "AclBaseItem",
          create?: boolean | null,
          read?: boolean | null,
          update?: boolean | null,
          delete?: boolean | null,
          createTopic?: string | null,
          createMessage?: string | null,
          createComment?: string | null,
          createReactions?: string | null,
        },
        guest:  {
          __typename: "AclItem",
          id?: string | null,
          refId: string,
          refIdType: RefidType,
          create?: boolean | null,
          read?: boolean | null,
          update?: boolean | null,
          delete?: boolean | null,
          createTopic?: string | null,
          createMessage?: string | null,
          createComment?: string | null,
          createReactions?: string | null,
        },
        items?:  Array< {
          __typename: "AclItem",
          id?: string | null,
          refId: string,
          refIdType: RefidType,
          create?: boolean | null,
          read?: boolean | null,
          update?: boolean | null,
          delete?: boolean | null,
          createTopic?: string | null,
          createMessage?: string | null,
          createComment?: string | null,
          createReactions?: string | null,
        } > | null,
      } | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetCategoryQueryVariables = {
  domainId: string,
  id: string,
};

export type GetCategoryQuery = {
  getCategory?:  {
    __typename: "Category",
    id: string,
    title: string,
    domainId: string,
    color?: string | null,
    owner?: string | null,
    updatedAt?: string | null,
    createdAt?: string | null,
    deleted?: boolean | null,
    deletedUser?: string | null,
  } | null,
};

export type ListCategoriesQueryVariables = {
  domainId?: string | null,
  id?: ModelIDKeyConditionInput | null,
  filter?: ModelCategoryFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  sortDirection?: ModelSortDirection | null,
};

export type ListCategoriesQuery = {
  listCategories?:  {
    __typename: "ModelCategoryConnection",
    items:  Array< {
      __typename: "Category",
      id: string,
      title: string,
      domainId: string,
      color?: string | null,
      owner?: string | null,
      updatedAt?: string | null,
      createdAt?: string | null,
      deleted?: boolean | null,
      deletedUser?: string | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetUserQueryVariables = {
  directId: string,
};

export type GetUserQuery = {
  getUser?:  {
    __typename: "User",
    id: string,
    directId: string,
    name: string,
    profileIcon?: string | null,
    domainIdList: Array< string >,
    owner?: string | null,
    favorites?: Array< string | null > | null,
    likes?: Array< string | null > | null,
    updatedAt?: string | null,
    createdAt?: string | null,
  } | null,
};

export type ListUsersQueryVariables = {
  directId?: string | null,
  filter?: ModelUserFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  sortDirection?: ModelSortDirection | null,
};

export type ListUsersQuery = {
  listUsers?:  {
    __typename: "ModelUserConnection",
    items:  Array< {
      __typename: "User",
      id: string,
      directId: string,
      name: string,
      profileIcon?: string | null,
      domainIdList: Array< string >,
      owner?: string | null,
      favorites?: Array< string | null > | null,
      likes?: Array< string | null > | null,
      updatedAt?: string | null,
      createdAt?: string | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetDomainUserQueryVariables = {
  domainId: string,
  userDirectId: string,
};

export type GetDomainUserQuery = {
  getDomainUser?:  {
    __typename: "DomainUser",
    domainId: string,
    userDirectId: string,
    user?:  {
      __typename: "User",
      id: string,
      directId: string,
      name: string,
      profileIcon?: string | null,
      domainIdList: Array< string >,
      owner?: string | null,
      favorites?: Array< string | null > | null,
      likes?: Array< string | null > | null,
      updatedAt?: string | null,
      createdAt?: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type ListDomainUsersQueryVariables = {
  domainId?: string | null,
  userDirectId?: ModelStringKeyConditionInput | null,
  filter?: ModelDomainUserFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  sortDirection?: ModelSortDirection | null,
};

export type ListDomainUsersQuery = {
  listDomainUsers?:  {
    __typename: "ModelDomainUserConnection",
    items:  Array< {
      __typename: "DomainUser",
      domainId: string,
      userDirectId: string,
      user?:  {
        __typename: "User",
        id: string,
        directId: string,
        name: string,
        profileIcon?: string | null,
        domainIdList: Array< string >,
        owner?: string | null,
        favorites?: Array< string | null > | null,
        likes?: Array< string | null > | null,
        updatedAt?: string | null,
        createdAt?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetDepartmentQueryVariables = {
  domainId: string,
  user_defined_key: string,
};

export type GetDepartmentQuery = {
  getDepartment?:  {
    __typename: "Department",
    domainId: string,
    department_id: string,
    name: string,
    user_defined_key: string,
    order: number,
    parent?: string | null,
    node?: number | null,
    createdAt: string,
    updatedAt: string,
    owner?: string | null,
  } | null,
};

export type ListDepartmentsQueryVariables = {
  domainId?: string | null,
  user_defined_key?: ModelStringKeyConditionInput | null,
  filter?: ModelDepartmentFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  sortDirection?: ModelSortDirection | null,
};

export type ListDepartmentsQuery = {
  listDepartments?:  {
    __typename: "ModelDepartmentConnection",
    items:  Array< {
      __typename: "Department",
      domainId: string,
      department_id: string,
      name: string,
      user_defined_key: string,
      order: number,
      parent?: string | null,
      node?: number | null,
      createdAt: string,
      updatedAt: string,
      owner?: string | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetNotificationsQueryVariables = {
  id: string,
};

export type GetNotificationsQuery = {
  getNotifications?:  {
    __typename: "Notifications",
    id: string,
    domainId: string,
    title: string,
    desc?: string | null,
    users: Array< string | null >,
    departments: Array< string | null >,
    exclude_users: Array< string | null >,
    exclude_departments: Array< string | null >,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type ListNotificationsQueryVariables = {
  filter?: ModelNotificationsFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListNotificationsQuery = {
  listNotifications?:  {
    __typename: "ModelNotificationsConnection",
    items:  Array< {
      __typename: "Notifications",
      id: string,
      domainId: string,
      title: string,
      desc?: string | null,
      users: Array< string | null >,
      departments: Array< string | null >,
      exclude_users: Array< string | null >,
      exclude_departments: Array< string | null >,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetIconReactionQueryVariables = {
  type: IconReactionType,
  targetType: ReactionTargetType,
  domainId: string,
  topicId: string,
  messageId: string,
  commentId: string,
};

export type GetIconReactionQuery = {
  getIconReaction?:  {
    __typename: "IconReaction",
    type: IconReactionType,
    targetType: ReactionTargetType,
    domainId: string,
    topicId: string,
    messageId: string,
    commentId: string,
    userIdList: Array< string >,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type ListIconReactionsQueryVariables = {
  type?: IconReactionType | null,
  targetTypeDomainIdTopicIdMessageIdCommentId?: ModelIconReactionPrimaryCompositeKeyConditionInput | null,
  filter?: ModelIconReactionFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  sortDirection?: ModelSortDirection | null,
};

export type ListIconReactionsQuery = {
  listIconReactions?:  {
    __typename: "ModelIconReactionConnection",
    items:  Array< {
      __typename: "IconReaction",
      type: IconReactionType,
      targetType: ReactionTargetType,
      domainId: string,
      topicId: string,
      messageId: string,
      commentId: string,
      userIdList: Array< string >,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetDomainSettingsQueryVariables = {
  id: string,
};

export type GetDomainSettingsQuery = {
  getDomainSettings?:  {
    __typename: "DomainSettings",
    id: string,
    createTopicAuthorityGroupPolicy?:  {
      __typename: "AuthorityGroupPolicy",
      admin?: boolean | null,
      user?: boolean | null,
    } | null,
    updateTopicAuthorityGroupPolicy?:  {
      __typename: "AuthorityGroupPolicy",
      admin?: boolean | null,
      user?: boolean | null,
    } | null,
    attachmentPolicy: AttachmentPolicy,
    attachmentFileType:  {
      __typename: "AttachmentFileType",
      image?: boolean | null,
      movie?: boolean | null,
      pdf?: boolean | null,
      office?: boolean | null,
    },
    downloadPolicy: DownloadPolicy,
    createdAt: string,
    updatedAt: string,
    owner?: string | null,
  } | null,
};

export type ListDomainSettingsQueryVariables = {
  filter?: ModelDomainSettingsFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListDomainSettingsQuery = {
  listDomainSettings?:  {
    __typename: "ModelDomainSettingsConnection",
    items:  Array< {
      __typename: "DomainSettings",
      id: string,
      createTopicAuthorityGroupPolicy?:  {
        __typename: "AuthorityGroupPolicy",
        admin?: boolean | null,
        user?: boolean | null,
      } | null,
      updateTopicAuthorityGroupPolicy?:  {
        __typename: "AuthorityGroupPolicy",
        admin?: boolean | null,
        user?: boolean | null,
      } | null,
      attachmentPolicy: AttachmentPolicy,
      attachmentFileType:  {
        __typename: "AttachmentFileType",
        image?: boolean | null,
        movie?: boolean | null,
        pdf?: boolean | null,
        office?: boolean | null,
      },
      downloadPolicy: DownloadPolicy,
      createdAt: string,
      updatedAt: string,
      owner?: string | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetAllowFeatureQueryVariables = {
  keyword: string,
};

export type GetAllowFeatureQuery = {
  getAllowFeature?:  {
    __typename: "AllowFeature",
    keyword: string,
    releasePhase: string,
    desc?: string | null,
    updatedAt?: string | null,
    createdAt?: string | null,
  } | null,
};

export type ListAllowFeaturesQueryVariables = {
  keyword?: string | null,
  filter?: ModelAllowFeatureFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  sortDirection?: ModelSortDirection | null,
};

export type ListAllowFeaturesQuery = {
  listAllowFeatures?:  {
    __typename: "ModelAllowFeatureConnection",
    items:  Array< {
      __typename: "AllowFeature",
      keyword: string,
      releasePhase: string,
      desc?: string | null,
      updatedAt?: string | null,
      createdAt?: string | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type ListTopicsByDomainIdQueryVariables = {
  domainId?: string | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelTopicFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListTopicsByDomainIdQuery = {
  ListTopicsByDomainId?:  {
    __typename: "ModelTopicConnection",
    items:  Array< {
      __typename: "Topic",
      id: string,
      domainId: string,
      title: string,
      desc: string,
      icon?:  {
        __typename: "Attachment",
        url: string,
        mime: string,
      } | null,
      pinned?: boolean | null,
      categoryId: string,
      messages?: number | null,
      owner?: string | null,
      updatedAt: string,
      createdAt?: string | null,
      deleted?: boolean | null,
      deletedUser?: string | null,
      acl?:  {
        __typename: "Acl",
        id?: string | null,
        refId: string,
        refIdType: RefidType,
        base:  {
          __typename: "AclBaseItem",
          create?: boolean | null,
          read?: boolean | null,
          update?: boolean | null,
          delete?: boolean | null,
          createTopic?: string | null,
          createMessage?: string | null,
          createComment?: string | null,
          createReactions?: string | null,
        },
        guest:  {
          __typename: "AclItem",
          id?: string | null,
          refId: string,
          refIdType: RefidType,
          create?: boolean | null,
          read?: boolean | null,
          update?: boolean | null,
          delete?: boolean | null,
          createTopic?: string | null,
          createMessage?: string | null,
          createComment?: string | null,
          createReactions?: string | null,
        },
        items?:  Array< {
          __typename: "AclItem",
          id?: string | null,
          refId: string,
          refIdType: RefidType,
          create?: boolean | null,
          read?: boolean | null,
          update?: boolean | null,
          delete?: boolean | null,
          createTopic?: string | null,
          createMessage?: string | null,
          createComment?: string | null,
          createReactions?: string | null,
        } > | null,
      } | null,
      notification?: string | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type ListMessagesByDomainAndTopicQueryVariables = {
  domainId?: string | null,
  topicId?: ModelStringKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelMessageFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListMessagesByDomainAndTopicQuery = {
  ListMessagesByDomainAndTopic?:  {
    __typename: "ModelMessageConnection",
    items:  Array< {
      __typename: "Message",
      id: string,
      domainId: string,
      topicId: string,
      title?: string | null,
      message?: string | null,
      pinned?: boolean | null,
      owner?: string | null,
      commentId: Array< string >,
      photos:  Array< {
        __typename: "Attachment",
        url: string,
        mime: string,
      } >,
      updatedAt: string,
      createdAt: string,
      deleted?: boolean | null,
      deletedUser?: string | null,
      acl?:  {
        __typename: "Acl",
        id?: string | null,
        refId: string,
        refIdType: RefidType,
        base:  {
          __typename: "AclBaseItem",
          create?: boolean | null,
          read?: boolean | null,
          update?: boolean | null,
          delete?: boolean | null,
          createTopic?: string | null,
          createMessage?: string | null,
          createComment?: string | null,
          createReactions?: string | null,
        },
        guest:  {
          __typename: "AclItem",
          id?: string | null,
          refId: string,
          refIdType: RefidType,
          create?: boolean | null,
          read?: boolean | null,
          update?: boolean | null,
          delete?: boolean | null,
          createTopic?: string | null,
          createMessage?: string | null,
          createComment?: string | null,
          createReactions?: string | null,
        },
        items?:  Array< {
          __typename: "AclItem",
          id?: string | null,
          refId: string,
          refIdType: RefidType,
          create?: boolean | null,
          read?: boolean | null,
          update?: boolean | null,
          delete?: boolean | null,
          createTopic?: string | null,
          createMessage?: string | null,
          createComment?: string | null,
          createReactions?: string | null,
        } > | null,
      } | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type ListMessagesByUpdatedAtQueryVariables = {
  topicId?: string | null,
  updatedAt?: ModelStringKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelMessageFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListMessagesByUpdatedAtQuery = {
  ListMessagesByUpdatedAt?:  {
    __typename: "ModelMessageConnection",
    items:  Array< {
      __typename: "Message",
      id: string,
      domainId: string,
      topicId: string,
      title?: string | null,
      message?: string | null,
      pinned?: boolean | null,
      owner?: string | null,
      commentId: Array< string >,
      photos:  Array< {
        __typename: "Attachment",
        url: string,
        mime: string,
      } >,
      updatedAt: string,
      createdAt: string,
      deleted?: boolean | null,
      deletedUser?: string | null,
      acl?:  {
        __typename: "Acl",
        id?: string | null,
        refId: string,
        refIdType: RefidType,
        base:  {
          __typename: "AclBaseItem",
          create?: boolean | null,
          read?: boolean | null,
          update?: boolean | null,
          delete?: boolean | null,
          createTopic?: string | null,
          createMessage?: string | null,
          createComment?: string | null,
          createReactions?: string | null,
        },
        guest:  {
          __typename: "AclItem",
          id?: string | null,
          refId: string,
          refIdType: RefidType,
          create?: boolean | null,
          read?: boolean | null,
          update?: boolean | null,
          delete?: boolean | null,
          createTopic?: string | null,
          createMessage?: string | null,
          createComment?: string | null,
          createReactions?: string | null,
        },
        items?:  Array< {
          __typename: "AclItem",
          id?: string | null,
          refId: string,
          refIdType: RefidType,
          create?: boolean | null,
          read?: boolean | null,
          update?: boolean | null,
          delete?: boolean | null,
          createTopic?: string | null,
          createMessage?: string | null,
          createComment?: string | null,
          createReactions?: string | null,
        } > | null,
      } | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type ListCommentsByTopicIdQueryVariables = {
  topicId?: string | null,
  createdAt?: ModelStringKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelCommentFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListCommentsByTopicIdQuery = {
  ListCommentsByTopicId?:  {
    __typename: "ModelCommentConnection",
    items:  Array< {
      __typename: "Comment",
      id: string,
      domainId: string,
      topicId: string,
      messageId: string,
      message: string,
      photos:  Array< {
        __typename: "Attachment",
        url: string,
        mime: string,
      } >,
      owner?: string | null,
      updatedAt: string,
      createdAt?: string | null,
      deleted?: boolean | null,
      deletedUser?: string | null,
      acl?:  {
        __typename: "Acl",
        id?: string | null,
        refId: string,
        refIdType: RefidType,
        base:  {
          __typename: "AclBaseItem",
          create?: boolean | null,
          read?: boolean | null,
          update?: boolean | null,
          delete?: boolean | null,
          createTopic?: string | null,
          createMessage?: string | null,
          createComment?: string | null,
          createReactions?: string | null,
        },
        guest:  {
          __typename: "AclItem",
          id?: string | null,
          refId: string,
          refIdType: RefidType,
          create?: boolean | null,
          read?: boolean | null,
          update?: boolean | null,
          delete?: boolean | null,
          createTopic?: string | null,
          createMessage?: string | null,
          createComment?: string | null,
          createReactions?: string | null,
        },
        items?:  Array< {
          __typename: "AclItem",
          id?: string | null,
          refId: string,
          refIdType: RefidType,
          create?: boolean | null,
          read?: boolean | null,
          update?: boolean | null,
          delete?: boolean | null,
          createTopic?: string | null,
          createMessage?: string | null,
          createComment?: string | null,
          createReactions?: string | null,
        } > | null,
      } | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type ListCommentsByMessageIdQueryVariables = {
  topicId?: string | null,
  messageIdCreatedAt?: ModelCommentListCommentsByMessageIdCompositeKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelCommentFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListCommentsByMessageIdQuery = {
  ListCommentsByMessageId?:  {
    __typename: "ModelCommentConnection",
    items:  Array< {
      __typename: "Comment",
      id: string,
      domainId: string,
      topicId: string,
      messageId: string,
      message: string,
      photos:  Array< {
        __typename: "Attachment",
        url: string,
        mime: string,
      } >,
      owner?: string | null,
      updatedAt: string,
      createdAt?: string | null,
      deleted?: boolean | null,
      deletedUser?: string | null,
      acl?:  {
        __typename: "Acl",
        id?: string | null,
        refId: string,
        refIdType: RefidType,
        base:  {
          __typename: "AclBaseItem",
          create?: boolean | null,
          read?: boolean | null,
          update?: boolean | null,
          delete?: boolean | null,
          createTopic?: string | null,
          createMessage?: string | null,
          createComment?: string | null,
          createReactions?: string | null,
        },
        guest:  {
          __typename: "AclItem",
          id?: string | null,
          refId: string,
          refIdType: RefidType,
          create?: boolean | null,
          read?: boolean | null,
          update?: boolean | null,
          delete?: boolean | null,
          createTopic?: string | null,
          createMessage?: string | null,
          createComment?: string | null,
          createReactions?: string | null,
        },
        items?:  Array< {
          __typename: "AclItem",
          id?: string | null,
          refId: string,
          refIdType: RefidType,
          create?: boolean | null,
          read?: boolean | null,
          update?: boolean | null,
          delete?: boolean | null,
          createTopic?: string | null,
          createMessage?: string | null,
          createComment?: string | null,
          createReactions?: string | null,
        } > | null,
      } | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type CategoryByDomainIdQueryVariables = {
  domainId?: string | null,
  title?: ModelStringKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelCategoryFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type CategoryByDomainIdQuery = {
  CategoryByDomainId?:  {
    __typename: "ModelCategoryConnection",
    items:  Array< {
      __typename: "Category",
      id: string,
      title: string,
      domainId: string,
      color?: string | null,
      owner?: string | null,
      updatedAt?: string | null,
      createdAt?: string | null,
      deleted?: boolean | null,
      deletedUser?: string | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type UserByCognitoUserIdQueryVariables = {
  id?: string | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelUserFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type UserByCognitoUserIdQuery = {
  UserByCognitoUserId?:  {
    __typename: "ModelUserConnection",
    items:  Array< {
      __typename: "User",
      id: string,
      directId: string,
      name: string,
      profileIcon?: string | null,
      domainIdList: Array< string >,
      owner?: string | null,
      favorites?: Array< string | null > | null,
      likes?: Array< string | null > | null,
      updatedAt?: string | null,
      createdAt?: string | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type DomainUsersByDomainIdQueryVariables = {
  domainId?: string | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelDomainUserFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type DomainUsersByDomainIdQuery = {
  DomainUsersByDomainId?:  {
    __typename: "ModelDomainUserConnection",
    items:  Array< {
      __typename: "DomainUser",
      domainId: string,
      userDirectId: string,
      user?:  {
        __typename: "User",
        id: string,
        directId: string,
        name: string,
        profileIcon?: string | null,
        domainIdList: Array< string >,
        owner?: string | null,
        favorites?: Array< string | null > | null,
        likes?: Array< string | null > | null,
        updatedAt?: string | null,
        createdAt?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type DepartmentByOrderQueryVariables = {
  domainId?: string | null,
  order?: ModelIntKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelDepartmentFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type DepartmentByOrderQuery = {
  DepartmentByOrder?:  {
    __typename: "ModelDepartmentConnection",
    items:  Array< {
      __typename: "Department",
      domainId: string,
      department_id: string,
      name: string,
      user_defined_key: string,
      order: number,
      parent?: string | null,
      node?: number | null,
      createdAt: string,
      updatedAt: string,
      owner?: string | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type ListIconReactionByDomainIdQueryVariables = {
  type?: IconReactionType | null,
  targetTypeDomainId?: ModelIconReactionListIconReactionByDomaniIdCompositeKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelIconReactionFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListIconReactionByDomainIdQuery = {
  ListIconReactionByDomainId?:  {
    __typename: "ModelIconReactionConnection",
    items:  Array< {
      __typename: "IconReaction",
      type: IconReactionType,
      targetType: ReactionTargetType,
      domainId: string,
      topicId: string,
      messageId: string,
      commentId: string,
      userIdList: Array< string >,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type ListIconReactionByTopicIdQueryVariables = {
  type?: IconReactionType | null,
  targetTypeDomainIdTopicId?: ModelIconReactionListIconReactionByTopicIdCompositeKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelIconReactionFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListIconReactionByTopicIdQuery = {
  ListIconReactionByTopicId?:  {
    __typename: "ModelIconReactionConnection",
    items:  Array< {
      __typename: "IconReaction",
      type: IconReactionType,
      targetType: ReactionTargetType,
      domainId: string,
      topicId: string,
      messageId: string,
      commentId: string,
      userIdList: Array< string >,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type SearchTopicsQueryVariables = {
  filter?: SearchableTopicFilterInput | null,
  sort?: SearchableTopicSortInput | null,
  limit?: number | null,
  nextToken?: string | null,
  from?: number | null,
};

export type SearchTopicsQuery = {
  searchTopics?:  {
    __typename: "SearchableTopicConnection",
    items:  Array< {
      __typename: "Topic",
      id: string,
      domainId: string,
      title: string,
      desc: string,
      icon?:  {
        __typename: "Attachment",
        url: string,
        mime: string,
      } | null,
      pinned?: boolean | null,
      categoryId: string,
      messages?: number | null,
      owner?: string | null,
      updatedAt: string,
      createdAt?: string | null,
      deleted?: boolean | null,
      deletedUser?: string | null,
      acl?:  {
        __typename: "Acl",
        id?: string | null,
        refId: string,
        refIdType: RefidType,
        base:  {
          __typename: "AclBaseItem",
          create?: boolean | null,
          read?: boolean | null,
          update?: boolean | null,
          delete?: boolean | null,
          createTopic?: string | null,
          createMessage?: string | null,
          createComment?: string | null,
          createReactions?: string | null,
        },
        guest:  {
          __typename: "AclItem",
          id?: string | null,
          refId: string,
          refIdType: RefidType,
          create?: boolean | null,
          read?: boolean | null,
          update?: boolean | null,
          delete?: boolean | null,
          createTopic?: string | null,
          createMessage?: string | null,
          createComment?: string | null,
          createReactions?: string | null,
        },
        items?:  Array< {
          __typename: "AclItem",
          id?: string | null,
          refId: string,
          refIdType: RefidType,
          create?: boolean | null,
          read?: boolean | null,
          update?: boolean | null,
          delete?: boolean | null,
          createTopic?: string | null,
          createMessage?: string | null,
          createComment?: string | null,
          createReactions?: string | null,
        } > | null,
      } | null,
      notification?: string | null,
    } | null >,
    nextToken?: string | null,
    total?: number | null,
  } | null,
};

export type SearchMessagesQueryVariables = {
  filter?: SearchableMessageFilterInput | null,
  sort?: SearchableMessageSortInput | null,
  limit?: number | null,
  nextToken?: string | null,
  from?: number | null,
};

export type SearchMessagesQuery = {
  searchMessages?:  {
    __typename: "SearchableMessageConnection",
    items:  Array< {
      __typename: "Message",
      id: string,
      domainId: string,
      topicId: string,
      title?: string | null,
      message?: string | null,
      pinned?: boolean | null,
      owner?: string | null,
      commentId: Array< string >,
      photos:  Array< {
        __typename: "Attachment",
        url: string,
        mime: string,
      } >,
      updatedAt: string,
      createdAt: string,
      deleted?: boolean | null,
      deletedUser?: string | null,
      acl?:  {
        __typename: "Acl",
        id?: string | null,
        refId: string,
        refIdType: RefidType,
        base:  {
          __typename: "AclBaseItem",
          create?: boolean | null,
          read?: boolean | null,
          update?: boolean | null,
          delete?: boolean | null,
          createTopic?: string | null,
          createMessage?: string | null,
          createComment?: string | null,
          createReactions?: string | null,
        },
        guest:  {
          __typename: "AclItem",
          id?: string | null,
          refId: string,
          refIdType: RefidType,
          create?: boolean | null,
          read?: boolean | null,
          update?: boolean | null,
          delete?: boolean | null,
          createTopic?: string | null,
          createMessage?: string | null,
          createComment?: string | null,
          createReactions?: string | null,
        },
        items?:  Array< {
          __typename: "AclItem",
          id?: string | null,
          refId: string,
          refIdType: RefidType,
          create?: boolean | null,
          read?: boolean | null,
          update?: boolean | null,
          delete?: boolean | null,
          createTopic?: string | null,
          createMessage?: string | null,
          createComment?: string | null,
          createReactions?: string | null,
        } > | null,
      } | null,
    } | null >,
    nextToken?: string | null,
    total?: number | null,
  } | null,
};

export type SearchCommentsQueryVariables = {
  filter?: SearchableCommentFilterInput | null,
  sort?: SearchableCommentSortInput | null,
  limit?: number | null,
  nextToken?: string | null,
  from?: number | null,
};

export type SearchCommentsQuery = {
  searchComments?:  {
    __typename: "SearchableCommentConnection",
    items:  Array< {
      __typename: "Comment",
      id: string,
      domainId: string,
      topicId: string,
      messageId: string,
      message: string,
      photos:  Array< {
        __typename: "Attachment",
        url: string,
        mime: string,
      } >,
      owner?: string | null,
      updatedAt: string,
      createdAt?: string | null,
      deleted?: boolean | null,
      deletedUser?: string | null,
      acl?:  {
        __typename: "Acl",
        id?: string | null,
        refId: string,
        refIdType: RefidType,
        base:  {
          __typename: "AclBaseItem",
          create?: boolean | null,
          read?: boolean | null,
          update?: boolean | null,
          delete?: boolean | null,
          createTopic?: string | null,
          createMessage?: string | null,
          createComment?: string | null,
          createReactions?: string | null,
        },
        guest:  {
          __typename: "AclItem",
          id?: string | null,
          refId: string,
          refIdType: RefidType,
          create?: boolean | null,
          read?: boolean | null,
          update?: boolean | null,
          delete?: boolean | null,
          createTopic?: string | null,
          createMessage?: string | null,
          createComment?: string | null,
          createReactions?: string | null,
        },
        items?:  Array< {
          __typename: "AclItem",
          id?: string | null,
          refId: string,
          refIdType: RefidType,
          create?: boolean | null,
          read?: boolean | null,
          update?: boolean | null,
          delete?: boolean | null,
          createTopic?: string | null,
          createMessage?: string | null,
          createComment?: string | null,
          createReactions?: string | null,
        } > | null,
      } | null,
    } | null >,
    nextToken?: string | null,
    total?: number | null,
  } | null,
};

export type OnCreateTopicSubscriptionVariables = {
  domainId: string,
};

export type OnCreateTopicSubscription = {
  onCreateTopic?:  {
    __typename: "Topic",
    id: string,
    domainId: string,
    title: string,
    desc: string,
    icon?:  {
      __typename: "Attachment",
      url: string,
      mime: string,
    } | null,
    pinned?: boolean | null,
    categoryId: string,
    messages?: number | null,
    owner?: string | null,
    updatedAt: string,
    createdAt?: string | null,
    deleted?: boolean | null,
    deletedUser?: string | null,
    acl?:  {
      __typename: "Acl",
      id?: string | null,
      refId: string,
      refIdType: RefidType,
      base:  {
        __typename: "AclBaseItem",
        create?: boolean | null,
        read?: boolean | null,
        update?: boolean | null,
        delete?: boolean | null,
        createTopic?: string | null,
        createMessage?: string | null,
        createComment?: string | null,
        createReactions?: string | null,
      },
      guest:  {
        __typename: "AclItem",
        id?: string | null,
        refId: string,
        refIdType: RefidType,
        create?: boolean | null,
        read?: boolean | null,
        update?: boolean | null,
        delete?: boolean | null,
        createTopic?: string | null,
        createMessage?: string | null,
        createComment?: string | null,
        createReactions?: string | null,
      },
      items?:  Array< {
        __typename: "AclItem",
        id?: string | null,
        refId: string,
        refIdType: RefidType,
        create?: boolean | null,
        read?: boolean | null,
        update?: boolean | null,
        delete?: boolean | null,
        createTopic?: string | null,
        createMessage?: string | null,
        createComment?: string | null,
        createReactions?: string | null,
      } > | null,
    } | null,
    notification?: string | null,
  } | null,
};

export type OnUpdateTopicSubscriptionVariables = {
  domainId: string,
};

export type OnUpdateTopicSubscription = {
  onUpdateTopic?:  {
    __typename: "Topic",
    id: string,
    domainId: string,
    title: string,
    desc: string,
    icon?:  {
      __typename: "Attachment",
      url: string,
      mime: string,
    } | null,
    pinned?: boolean | null,
    categoryId: string,
    messages?: number | null,
    owner?: string | null,
    updatedAt: string,
    createdAt?: string | null,
    deleted?: boolean | null,
    deletedUser?: string | null,
    acl?:  {
      __typename: "Acl",
      id?: string | null,
      refId: string,
      refIdType: RefidType,
      base:  {
        __typename: "AclBaseItem",
        create?: boolean | null,
        read?: boolean | null,
        update?: boolean | null,
        delete?: boolean | null,
        createTopic?: string | null,
        createMessage?: string | null,
        createComment?: string | null,
        createReactions?: string | null,
      },
      guest:  {
        __typename: "AclItem",
        id?: string | null,
        refId: string,
        refIdType: RefidType,
        create?: boolean | null,
        read?: boolean | null,
        update?: boolean | null,
        delete?: boolean | null,
        createTopic?: string | null,
        createMessage?: string | null,
        createComment?: string | null,
        createReactions?: string | null,
      },
      items?:  Array< {
        __typename: "AclItem",
        id?: string | null,
        refId: string,
        refIdType: RefidType,
        create?: boolean | null,
        read?: boolean | null,
        update?: boolean | null,
        delete?: boolean | null,
        createTopic?: string | null,
        createMessage?: string | null,
        createComment?: string | null,
        createReactions?: string | null,
      } > | null,
    } | null,
    notification?: string | null,
  } | null,
};

export type OnDeleteTopicSubscriptionVariables = {
  domainId: string,
};

export type OnDeleteTopicSubscription = {
  onDeleteTopic?:  {
    __typename: "Topic",
    id: string,
    domainId: string,
    title: string,
    desc: string,
    icon?:  {
      __typename: "Attachment",
      url: string,
      mime: string,
    } | null,
    pinned?: boolean | null,
    categoryId: string,
    messages?: number | null,
    owner?: string | null,
    updatedAt: string,
    createdAt?: string | null,
    deleted?: boolean | null,
    deletedUser?: string | null,
    acl?:  {
      __typename: "Acl",
      id?: string | null,
      refId: string,
      refIdType: RefidType,
      base:  {
        __typename: "AclBaseItem",
        create?: boolean | null,
        read?: boolean | null,
        update?: boolean | null,
        delete?: boolean | null,
        createTopic?: string | null,
        createMessage?: string | null,
        createComment?: string | null,
        createReactions?: string | null,
      },
      guest:  {
        __typename: "AclItem",
        id?: string | null,
        refId: string,
        refIdType: RefidType,
        create?: boolean | null,
        read?: boolean | null,
        update?: boolean | null,
        delete?: boolean | null,
        createTopic?: string | null,
        createMessage?: string | null,
        createComment?: string | null,
        createReactions?: string | null,
      },
      items?:  Array< {
        __typename: "AclItem",
        id?: string | null,
        refId: string,
        refIdType: RefidType,
        create?: boolean | null,
        read?: boolean | null,
        update?: boolean | null,
        delete?: boolean | null,
        createTopic?: string | null,
        createMessage?: string | null,
        createComment?: string | null,
        createReactions?: string | null,
      } > | null,
    } | null,
    notification?: string | null,
  } | null,
};

export type OnCreateMessageSubscriptionVariables = {
  domainId: string,
  topicId: string,
};

export type OnCreateMessageSubscription = {
  onCreateMessage?:  {
    __typename: "Message",
    id: string,
    domainId: string,
    topicId: string,
    title?: string | null,
    message?: string | null,
    pinned?: boolean | null,
    owner?: string | null,
    commentId: Array< string >,
    photos:  Array< {
      __typename: "Attachment",
      url: string,
      mime: string,
    } >,
    updatedAt: string,
    createdAt: string,
    deleted?: boolean | null,
    deletedUser?: string | null,
    acl?:  {
      __typename: "Acl",
      id?: string | null,
      refId: string,
      refIdType: RefidType,
      base:  {
        __typename: "AclBaseItem",
        create?: boolean | null,
        read?: boolean | null,
        update?: boolean | null,
        delete?: boolean | null,
        createTopic?: string | null,
        createMessage?: string | null,
        createComment?: string | null,
        createReactions?: string | null,
      },
      guest:  {
        __typename: "AclItem",
        id?: string | null,
        refId: string,
        refIdType: RefidType,
        create?: boolean | null,
        read?: boolean | null,
        update?: boolean | null,
        delete?: boolean | null,
        createTopic?: string | null,
        createMessage?: string | null,
        createComment?: string | null,
        createReactions?: string | null,
      },
      items?:  Array< {
        __typename: "AclItem",
        id?: string | null,
        refId: string,
        refIdType: RefidType,
        create?: boolean | null,
        read?: boolean | null,
        update?: boolean | null,
        delete?: boolean | null,
        createTopic?: string | null,
        createMessage?: string | null,
        createComment?: string | null,
        createReactions?: string | null,
      } > | null,
    } | null,
  } | null,
};

export type OnUpdateMessageSubscriptionVariables = {
  domainId: string,
  topicId: string,
};

export type OnUpdateMessageSubscription = {
  onUpdateMessage?:  {
    __typename: "Message",
    id: string,
    domainId: string,
    topicId: string,
    title?: string | null,
    message?: string | null,
    pinned?: boolean | null,
    owner?: string | null,
    commentId: Array< string >,
    photos:  Array< {
      __typename: "Attachment",
      url: string,
      mime: string,
    } >,
    updatedAt: string,
    createdAt: string,
    deleted?: boolean | null,
    deletedUser?: string | null,
    acl?:  {
      __typename: "Acl",
      id?: string | null,
      refId: string,
      refIdType: RefidType,
      base:  {
        __typename: "AclBaseItem",
        create?: boolean | null,
        read?: boolean | null,
        update?: boolean | null,
        delete?: boolean | null,
        createTopic?: string | null,
        createMessage?: string | null,
        createComment?: string | null,
        createReactions?: string | null,
      },
      guest:  {
        __typename: "AclItem",
        id?: string | null,
        refId: string,
        refIdType: RefidType,
        create?: boolean | null,
        read?: boolean | null,
        update?: boolean | null,
        delete?: boolean | null,
        createTopic?: string | null,
        createMessage?: string | null,
        createComment?: string | null,
        createReactions?: string | null,
      },
      items?:  Array< {
        __typename: "AclItem",
        id?: string | null,
        refId: string,
        refIdType: RefidType,
        create?: boolean | null,
        read?: boolean | null,
        update?: boolean | null,
        delete?: boolean | null,
        createTopic?: string | null,
        createMessage?: string | null,
        createComment?: string | null,
        createReactions?: string | null,
      } > | null,
    } | null,
  } | null,
};

export type OnDeleteMessageSubscriptionVariables = {
  domainId: string,
  topicId: string,
};

export type OnDeleteMessageSubscription = {
  onDeleteMessage?:  {
    __typename: "Message",
    id: string,
    domainId: string,
    topicId: string,
    title?: string | null,
    message?: string | null,
    pinned?: boolean | null,
    owner?: string | null,
    commentId: Array< string >,
    photos:  Array< {
      __typename: "Attachment",
      url: string,
      mime: string,
    } >,
    updatedAt: string,
    createdAt: string,
    deleted?: boolean | null,
    deletedUser?: string | null,
    acl?:  {
      __typename: "Acl",
      id?: string | null,
      refId: string,
      refIdType: RefidType,
      base:  {
        __typename: "AclBaseItem",
        create?: boolean | null,
        read?: boolean | null,
        update?: boolean | null,
        delete?: boolean | null,
        createTopic?: string | null,
        createMessage?: string | null,
        createComment?: string | null,
        createReactions?: string | null,
      },
      guest:  {
        __typename: "AclItem",
        id?: string | null,
        refId: string,
        refIdType: RefidType,
        create?: boolean | null,
        read?: boolean | null,
        update?: boolean | null,
        delete?: boolean | null,
        createTopic?: string | null,
        createMessage?: string | null,
        createComment?: string | null,
        createReactions?: string | null,
      },
      items?:  Array< {
        __typename: "AclItem",
        id?: string | null,
        refId: string,
        refIdType: RefidType,
        create?: boolean | null,
        read?: boolean | null,
        update?: boolean | null,
        delete?: boolean | null,
        createTopic?: string | null,
        createMessage?: string | null,
        createComment?: string | null,
        createReactions?: string | null,
      } > | null,
    } | null,
  } | null,
};

export type OnCreateCommentSubscriptionVariables = {
  domainId: string,
  topicId: string,
  messageId: string,
};

export type OnCreateCommentSubscription = {
  onCreateComment?:  {
    __typename: "Comment",
    id: string,
    domainId: string,
    topicId: string,
    messageId: string,
    message: string,
    photos:  Array< {
      __typename: "Attachment",
      url: string,
      mime: string,
    } >,
    owner?: string | null,
    updatedAt: string,
    createdAt?: string | null,
    deleted?: boolean | null,
    deletedUser?: string | null,
    acl?:  {
      __typename: "Acl",
      id?: string | null,
      refId: string,
      refIdType: RefidType,
      base:  {
        __typename: "AclBaseItem",
        create?: boolean | null,
        read?: boolean | null,
        update?: boolean | null,
        delete?: boolean | null,
        createTopic?: string | null,
        createMessage?: string | null,
        createComment?: string | null,
        createReactions?: string | null,
      },
      guest:  {
        __typename: "AclItem",
        id?: string | null,
        refId: string,
        refIdType: RefidType,
        create?: boolean | null,
        read?: boolean | null,
        update?: boolean | null,
        delete?: boolean | null,
        createTopic?: string | null,
        createMessage?: string | null,
        createComment?: string | null,
        createReactions?: string | null,
      },
      items?:  Array< {
        __typename: "AclItem",
        id?: string | null,
        refId: string,
        refIdType: RefidType,
        create?: boolean | null,
        read?: boolean | null,
        update?: boolean | null,
        delete?: boolean | null,
        createTopic?: string | null,
        createMessage?: string | null,
        createComment?: string | null,
        createReactions?: string | null,
      } > | null,
    } | null,
  } | null,
};

export type OnUpdateCommentSubscriptionVariables = {
  domainId: string,
  topicId: string,
  messageId: string,
};

export type OnUpdateCommentSubscription = {
  onUpdateComment?:  {
    __typename: "Comment",
    id: string,
    domainId: string,
    topicId: string,
    messageId: string,
    message: string,
    photos:  Array< {
      __typename: "Attachment",
      url: string,
      mime: string,
    } >,
    owner?: string | null,
    updatedAt: string,
    createdAt?: string | null,
    deleted?: boolean | null,
    deletedUser?: string | null,
    acl?:  {
      __typename: "Acl",
      id?: string | null,
      refId: string,
      refIdType: RefidType,
      base:  {
        __typename: "AclBaseItem",
        create?: boolean | null,
        read?: boolean | null,
        update?: boolean | null,
        delete?: boolean | null,
        createTopic?: string | null,
        createMessage?: string | null,
        createComment?: string | null,
        createReactions?: string | null,
      },
      guest:  {
        __typename: "AclItem",
        id?: string | null,
        refId: string,
        refIdType: RefidType,
        create?: boolean | null,
        read?: boolean | null,
        update?: boolean | null,
        delete?: boolean | null,
        createTopic?: string | null,
        createMessage?: string | null,
        createComment?: string | null,
        createReactions?: string | null,
      },
      items?:  Array< {
        __typename: "AclItem",
        id?: string | null,
        refId: string,
        refIdType: RefidType,
        create?: boolean | null,
        read?: boolean | null,
        update?: boolean | null,
        delete?: boolean | null,
        createTopic?: string | null,
        createMessage?: string | null,
        createComment?: string | null,
        createReactions?: string | null,
      } > | null,
    } | null,
  } | null,
};

export type OnDeleteCommentSubscriptionVariables = {
  domainId: string,
  topicId: string,
  messageId: string,
};

export type OnDeleteCommentSubscription = {
  onDeleteComment?:  {
    __typename: "Comment",
    id: string,
    domainId: string,
    topicId: string,
    messageId: string,
    message: string,
    photos:  Array< {
      __typename: "Attachment",
      url: string,
      mime: string,
    } >,
    owner?: string | null,
    updatedAt: string,
    createdAt?: string | null,
    deleted?: boolean | null,
    deletedUser?: string | null,
    acl?:  {
      __typename: "Acl",
      id?: string | null,
      refId: string,
      refIdType: RefidType,
      base:  {
        __typename: "AclBaseItem",
        create?: boolean | null,
        read?: boolean | null,
        update?: boolean | null,
        delete?: boolean | null,
        createTopic?: string | null,
        createMessage?: string | null,
        createComment?: string | null,
        createReactions?: string | null,
      },
      guest:  {
        __typename: "AclItem",
        id?: string | null,
        refId: string,
        refIdType: RefidType,
        create?: boolean | null,
        read?: boolean | null,
        update?: boolean | null,
        delete?: boolean | null,
        createTopic?: string | null,
        createMessage?: string | null,
        createComment?: string | null,
        createReactions?: string | null,
      },
      items?:  Array< {
        __typename: "AclItem",
        id?: string | null,
        refId: string,
        refIdType: RefidType,
        create?: boolean | null,
        read?: boolean | null,
        update?: boolean | null,
        delete?: boolean | null,
        createTopic?: string | null,
        createMessage?: string | null,
        createComment?: string | null,
        createReactions?: string | null,
      } > | null,
    } | null,
  } | null,
};

export type OnCreateIconReactionSubscriptionVariables = {
  domainId: string,
};

export type OnCreateIconReactionSubscription = {
  onCreateIconReaction?:  {
    __typename: "IconReaction",
    type: IconReactionType,
    targetType: ReactionTargetType,
    domainId: string,
    topicId: string,
    messageId: string,
    commentId: string,
    userIdList: Array< string >,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnUpdateIconReactionSubscriptionVariables = {
  domainId: string,
};

export type OnUpdateIconReactionSubscription = {
  onUpdateIconReaction?:  {
    __typename: "IconReaction",
    type: IconReactionType,
    targetType: ReactionTargetType,
    domainId: string,
    topicId: string,
    messageId: string,
    commentId: string,
    userIdList: Array< string >,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnDeleteIconReactionSubscriptionVariables = {
  domainId: string,
};

export type OnDeleteIconReactionSubscription = {
  onDeleteIconReaction?:  {
    __typename: "IconReaction",
    type: IconReactionType,
    targetType: ReactionTargetType,
    domainId: string,
    topicId: string,
    messageId: string,
    commentId: string,
    userIdList: Array< string >,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnCreateCategorySubscriptionVariables = {
  domainId: string,
};

export type OnCreateCategorySubscription = {
  onCreateCategory?:  {
    __typename: "Category",
    id: string,
    title: string,
    domainId: string,
    color?: string | null,
    owner?: string | null,
    updatedAt?: string | null,
    createdAt?: string | null,
    deleted?: boolean | null,
    deletedUser?: string | null,
  } | null,
};

export type OnUpdateCategorySubscriptionVariables = {
  domainId: string,
};

export type OnUpdateCategorySubscription = {
  onUpdateCategory?:  {
    __typename: "Category",
    id: string,
    title: string,
    domainId: string,
    color?: string | null,
    owner?: string | null,
    updatedAt?: string | null,
    createdAt?: string | null,
    deleted?: boolean | null,
    deletedUser?: string | null,
  } | null,
};

export type OnDeleteCategorySubscriptionVariables = {
  domainId: string,
};

export type OnDeleteCategorySubscription = {
  onDeleteCategory?:  {
    __typename: "Category",
    id: string,
    title: string,
    domainId: string,
    color?: string | null,
    owner?: string | null,
    updatedAt?: string | null,
    createdAt?: string | null,
    deleted?: boolean | null,
    deletedUser?: string | null,
  } | null,
};

export type OnCreateUserSubscriptionVariables = {
  owner: string,
};

export type OnCreateUserSubscription = {
  onCreateUser?:  {
    __typename: "User",
    id: string,
    directId: string,
    name: string,
    profileIcon?: string | null,
    domainIdList: Array< string >,
    owner?: string | null,
    favorites?: Array< string | null > | null,
    likes?: Array< string | null > | null,
    updatedAt?: string | null,
    createdAt?: string | null,
  } | null,
};

export type OnUpdateUserSubscriptionVariables = {
  owner: string,
};

export type OnUpdateUserSubscription = {
  onUpdateUser?:  {
    __typename: "User",
    id: string,
    directId: string,
    name: string,
    profileIcon?: string | null,
    domainIdList: Array< string >,
    owner?: string | null,
    favorites?: Array< string | null > | null,
    likes?: Array< string | null > | null,
    updatedAt?: string | null,
    createdAt?: string | null,
  } | null,
};

export type OnDeleteUserSubscriptionVariables = {
  owner: string,
};

export type OnDeleteUserSubscription = {
  onDeleteUser?:  {
    __typename: "User",
    id: string,
    directId: string,
    name: string,
    profileIcon?: string | null,
    domainIdList: Array< string >,
    owner?: string | null,
    favorites?: Array< string | null > | null,
    likes?: Array< string | null > | null,
    updatedAt?: string | null,
    createdAt?: string | null,
  } | null,
};

export type OnCreateDomainUserSubscriptionVariables = {
};

export type OnCreateDomainUserSubscription = {
  onCreateDomainUser?:  {
    __typename: "DomainUser",
    domainId: string,
    userDirectId: string,
    user?:  {
      __typename: "User",
      id: string,
      directId: string,
      name: string,
      profileIcon?: string | null,
      domainIdList: Array< string >,
      owner?: string | null,
      favorites?: Array< string | null > | null,
      likes?: Array< string | null > | null,
      updatedAt?: string | null,
      createdAt?: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnUpdateDomainUserSubscriptionVariables = {
};

export type OnUpdateDomainUserSubscription = {
  onUpdateDomainUser?:  {
    __typename: "DomainUser",
    domainId: string,
    userDirectId: string,
    user?:  {
      __typename: "User",
      id: string,
      directId: string,
      name: string,
      profileIcon?: string | null,
      domainIdList: Array< string >,
      owner?: string | null,
      favorites?: Array< string | null > | null,
      likes?: Array< string | null > | null,
      updatedAt?: string | null,
      createdAt?: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnDeleteDomainUserSubscriptionVariables = {
};

export type OnDeleteDomainUserSubscription = {
  onDeleteDomainUser?:  {
    __typename: "DomainUser",
    domainId: string,
    userDirectId: string,
    user?:  {
      __typename: "User",
      id: string,
      directId: string,
      name: string,
      profileIcon?: string | null,
      domainIdList: Array< string >,
      owner?: string | null,
      favorites?: Array< string | null > | null,
      likes?: Array< string | null > | null,
      updatedAt?: string | null,
      createdAt?: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnCreateNotificationsSubscriptionVariables = {
};

export type OnCreateNotificationsSubscription = {
  onCreateNotifications?:  {
    __typename: "Notifications",
    id: string,
    domainId: string,
    title: string,
    desc?: string | null,
    users: Array< string | null >,
    departments: Array< string | null >,
    exclude_users: Array< string | null >,
    exclude_departments: Array< string | null >,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnUpdateNotificationsSubscriptionVariables = {
};

export type OnUpdateNotificationsSubscription = {
  onUpdateNotifications?:  {
    __typename: "Notifications",
    id: string,
    domainId: string,
    title: string,
    desc?: string | null,
    users: Array< string | null >,
    departments: Array< string | null >,
    exclude_users: Array< string | null >,
    exclude_departments: Array< string | null >,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnDeleteNotificationsSubscriptionVariables = {
};

export type OnDeleteNotificationsSubscription = {
  onDeleteNotifications?:  {
    __typename: "Notifications",
    id: string,
    domainId: string,
    title: string,
    desc?: string | null,
    users: Array< string | null >,
    departments: Array< string | null >,
    exclude_users: Array< string | null >,
    exclude_departments: Array< string | null >,
    createdAt: string,
    updatedAt: string,
  } | null,
};
